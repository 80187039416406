import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from 'pages/landing/assets/header_logo.svg'
import { Button } from 'pages/landing/components/button/Button'
import styles from 'pages/landing/components/header/HeaderLanding.module.scss'
import { MenuItem } from 'pages/landing/components/header/MenuItem'
import { landingRoutes } from 'pages/landing/const'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'

export const Header = () => {
  const tenantAndUserData = useTenantAndUserData()
  const firstname = useMemo(() => tenantAndUserData?.userDetails?.firstname || '-', [tenantAndUserData])
  return (
    <div className={styles.root} data-testid="landing-header-menu-container">
      <div className={styles.wrapper}>
        <Link to="">
          <Logo data-testid="landing-header-logo" />
        </Link>
        <div className={styles.menu} data-testid="landing-header-menu">
          <MenuItem to={landingRoutes.aboutOpen}>About Open</MenuItem>
          <MenuItem to={landingRoutes.whatWeOffer}>What we offer</MenuItem>
          <MenuItem to={landingRoutes.ourProducts}>Our apps</MenuItem>
          <MenuItem to={landingRoutes.successStories}>Success stories</MenuItem>
        </div>
      </div>
      <div className={styles.menu}>
        <span className={styles.text}>{`Welcome ${firstname}`}</span>
        {/* TODO Provide more appropriate solution */}
        <Button
          className={styles.button}
          onClick={() => localStorage.clear()}
          component={Link}
          to={landingRoutes.selectOs}
        >
          Access Open
        </Button>
      </div>
    </div>
  )
}
