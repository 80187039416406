import { Theme, ThemeContent, ThemeStyles, resolveTheme } from '@platform-ui-kit/components-library'
import { MayBeNull, Taxonomy } from '@wpp-open/core'
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, useNavigate } from 'react-router-dom'

import { useCurrentTenantThemeApi } from 'api/tenant/queries/useCurrentTenantThemeApi'
import { useDefaultTenantApi } from 'api/tenant/queries/useDefaultTenantApi'
import { useHostInfoApi } from 'api/tenant/queries/useHostInfoApi'
import { useTaxonomyApi } from 'api/tenant/queries/useTaxonomyApi'
import { is404Error } from 'api/utils'
import { HeadData } from 'components/headData/HeadData'
import { OsIsNotAvailableError } from 'components/renderError'
import { environment } from 'environment'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { useStableCallback } from 'hooks/useStableCallback'
import i18n from 'i18n/i18n'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { SUPPORTED_THEME, useApplyTheme } from 'providers/publicData/utils'
import { GENERIC_TENANT_DEV_ROUTE } from 'providers/tenantAndUserData/utils'
import { HostType, TenantDefault, TenantPublic } from 'types/tenants/tenant'
import { capitalize, excludeFalsy } from 'utils/common'

export interface PublicDataContextValue {
  navigate: NavigateFunction
  tenantType: Exclude<HostType, HostType.UNKNOWN>
  currentTenantPublic: MayBeNull<TenantPublic>
  defaultTenant: TenantDefault
  theme: Theme
  resolvedTheme: ThemeStyles
  currentTaxonomy: Taxonomy
}

export const PublicDataContext = createContext<PublicDataContextValue>(null!)

export const usePublicData = () => useContext(PublicDataContext)

export const PublicDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const [mode] = useState<keyof ThemeContent>(SUPPORTED_THEME)

  // Top-level navigate will resolve relative paths
  // that are provided by legacy apps as absolute.
  const navigate = useNavigate()
  const navigateStable = useStableCallback(navigate)

  const { data: hostInfo, isLoading: isHostInfoLoading, isError: isHostInfoError } = useHostInfoApi()
  const {
    data: defaultTenant,
    isLoading: isDefaultTenantLoading,
    isError: isDefaultTenantError,
  } = useDefaultTenantApi()
  const {
    data: taxonomy,
    isLoading: isTaxonomyLoading,
    isError: isTaxonomyError,
  } = useTaxonomyApi({
    enabled: Boolean(hostInfo?.currentTenant),
  })

  const tenantType = hostInfo?.type
  const isUnknownTenant = tenantType === HostType.UNKNOWN
  const isCurrentThemeEnabled = tenantType === HostType.TENANT

  const {
    data: currentTheme,
    isLoading: isCurrentThemeLoading,
    error: currentThemeError,
  } = useCurrentTenantThemeApi({
    enabled: isCurrentThemeEnabled,
  })

  useProviderNoncriticalError({
    isError: isTaxonomyError,
    message: t('os.provider_errors.taxonomy'),
  })
  useProviderNoncriticalError({
    isError: !!currentThemeError && !is404Error(currentThemeError),
    message: t('os.provider_errors.theme'),
  })

  useEffect(() => {
    if (taxonomy && i18n.options.interpolation) {
      i18n.options.interpolation.defaultVariables = Object.entries(taxonomy).reduce(
        (acc, [key, value]) => ({
          ...acc,
          [key]: value,
          [capitalize(key)]: capitalize(value),
        }),
        {},
      )
    }
  }, [taxonomy])

  useEffect(() => {
    if (isUnknownTenant) {
      if (process.env.PROD) {
        window.location.assign(environment.GENERIC_TENANT_URL)
      }

      if (process.env.DEV) {
        console.error('Unknown tenant, use a different one for development')
        window.location.assign(GENERIC_TENANT_DEV_ROUTE)
      }
    }
  }, [isUnknownTenant])

  const theme = currentTheme || defaultTenant?.theme
  const resolvedThemeStyles = useMemo(
    () => (theme ? resolveTheme(theme, mode).content[mode]! : ({} as ThemeStyles)),
    [theme, mode],
  )
  const themeFonts = [theme?.settings?.fontUrl].filter(excludeFalsy).flat()

  useApplyTheme({ theme, mode })

  const isLoading = isHostInfoLoading || isDefaultTenantLoading || isCurrentThemeLoading || isTaxonomyLoading
  const isCriticalError = isHostInfoError || isDefaultTenantError

  // Nothing is shown until we have a theme loaded
  if (isLoading) {
    return null
  }

  if (isCriticalError) {
    return <OsIsNotAvailableError />
  }

  // User will be redirected to generic tenant instead.
  // We handle it here, so that even public routes won't be available for the user.
  if (isUnknownTenant) {
    return <LoadingPage />
  }

  return (
    <PublicDataContext.Provider
      value={{
        navigate: navigateStable,
        tenantType: tenantType!,
        currentTenantPublic: hostInfo!.currentTenant,
        defaultTenant: defaultTenant!,
        theme: theme!,
        resolvedTheme: resolvedThemeStyles!,
        currentTaxonomy: taxonomy || defaultTenant?.taxonomy!,
      }}
    >
      <HeadData themeFonts={themeFonts} />

      {children}
    </PublicDataContext.Provider>
  )
}
