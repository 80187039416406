import { Outlet } from 'react-router-dom'

import { NotFoundError } from 'components/renderError'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'

export const IsDemoTenant = () => {
  const { currentTenant } = useTenantAndUserData()
  const demoTenantId = '1e73c4ad-dfa9-403e-841f-d8aafb565b76'
  return currentTenant.id === demoTenantId ? <Outlet /> : <NotFoundError />
}
