import { Provider as NiceModalProvider } from '@ebay/nice-modal-react'
import { BrowserRouter } from 'react-router-dom'

import { PublicRoutes } from 'app/publicRoutes/PublicRoutes'
import { PitchRoutes } from 'app/secureRoutes/PitchRoutes'
import { SecureRoutes } from 'app/secureRoutes/SecureRoutes'
import { usePopulateConfigurableCssVariables } from 'app/utils'
import { IntercomWidget } from 'components/intercom/IntercomWidget'
import { RenderErrorBoundary, RenderErrorType } from 'components/renderError'
import { RenderErrorHeight } from 'components/renderError/boundary/RenderErrorBoundary'
import { ZendeskWidget } from 'components/zendesk/ZendeskWidget'
import { ApiProvider } from 'providers/api/ApiProvider'
import { AppStateProvider } from 'providers/appState/AppStateProvider'
import { AuthProvider } from 'providers/auth/AuthProvider'
import { DataProvider } from 'providers/data/DataProvider'
import { MicroAppsProvider } from 'providers/microApps/MicroAppsProvider'
import { OsQueryClientProvider } from 'providers/osQueryClient'
import { PublicDataProvider } from 'providers/publicData/PublicDataProvider'
import { TenantAndUserDataProvider } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { ToastProvider } from 'providers/toast/ToastProvider'

import 'app/App.scss'

export const App = () => {
  usePopulateConfigurableCssVariables()

  return (
    <BrowserRouter>
      <OsQueryClientProvider>
        {/* Public data issues boundary. */}
        <RenderErrorBoundary root height={RenderErrorHeight.Fullscreen}>
          <ToastProvider>
            <PublicDataProvider>
              <PublicRoutes>
                {/* Login modals */}
                <NiceModalProvider>
                  <AuthProvider>
                    <ApiProvider>
                      {/* TODO: Only single modal provider is allowed,
                        see https://github.com/eBay/nice-modal-react/issues/100 */}
                      {/* Tenant selection & error boundary modals */}
                      <NiceModalProvider>
                        {/* Protected data issues boundary. */}
                        <RenderErrorBoundary
                          catchErrors={[
                            RenderErrorType.OsIsNotAvailable,
                            RenderErrorType.DataIsNotAvailable,
                            RenderErrorType.ForbiddenPage,
                            RenderErrorType.ForbiddenOSAccess,
                            RenderErrorType.NotFound,
                            RenderErrorType.CriticalError,
                          ]}
                          height={RenderErrorHeight.Fullscreen}
                        >
                          <TenantAndUserDataProvider>
                            <DataProvider>
                              <AppStateProvider>
                                <MicroAppsProvider>
                                  {/* Protected data modals */}
                                  <NiceModalProvider>
                                    <IntercomWidget />
                                    <PitchRoutes>
                                      <SecureRoutes />
                                    </PitchRoutes>
                                  </NiceModalProvider>
                                </MicroAppsProvider>
                              </AppStateProvider>
                            </DataProvider>
                          </TenantAndUserDataProvider>
                        </RenderErrorBoundary>
                      </NiceModalProvider>
                    </ApiProvider>
                  </AuthProvider>
                </NiceModalProvider>
                <ZendeskWidget />
                <IntercomWidget />
              </PublicRoutes>
            </PublicDataProvider>
          </ToastProvider>
        </RenderErrorBoundary>
      </OsQueryClientProvider>
    </BrowserRouter>
  )
}
