import { HierarchyLevelType, MayBeNull } from '@wpp-open/core'

import { AppData, apps, ExternalAppCode } from 'constants/apps'
import { useHasPermission } from 'hooks/useHasPermission'
import { useData } from 'providers/data/DataProvider'

export type HardcodedAppsConfig = Record<HierarchyLevelType, HardcodedAppSection[]>
export interface HardcodedAppSection {
  name: MayBeNull<string>
  apps: AppData[]
}

// TODO: Remove this when the dynamic list of apps is added
export const hardcodedApps: HardcodedAppsConfig = {
  [HierarchyLevelType.Tenant]: [
    {
      name: null,
      apps: [
        apps[ExternalAppCode.SegmentOpportunitySimulatorUk],
        apps[ExternalAppCode.SegmentOpportunitySimulatorUs],
        apps[ExternalAppCode.Datameter],
      ],
    },
  ],
  [HierarchyLevelType.Client]: [],
  [HierarchyLevelType.Market]: [],
  [HierarchyLevelType.Brand]: [],
}

export const useHardcodedApps = (selectedWorkspaceId?: string): HardcodedAppSection[] => {
  const { navigationTree } = useData()
  const { hasPermission } = useHasPermission()

  const selectedWorkspace = selectedWorkspaceId ? navigationTree.mapping[selectedWorkspaceId] : null
  const workspaceType = selectedWorkspace ? (selectedWorkspace.type as HierarchyLevelType) : HierarchyLevelType.Tenant

  return hardcodedApps[workspaceType]
    .map(section => {
      const filteredApps = section.apps.filter(app => hasPermission(app.permission))

      return {
        ...section,
        apps: filteredApps,
      }
    })
    .filter(({ apps }) => apps.length > 0)
}
