import { WppInput } from '@platform-ui-kit/components-library-react'
import { Fragment, ReactNode, useDeferredValue, useState } from 'react'

import { FadeIn } from 'components/common/fadeIn/FadeIn'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/searchableTreeList/SearchableTreeList.module.scss'
import { sortBy } from 'utils/common'
import { PointerNode, Tree } from 'utils/mapping/common'

interface Props<T extends Tree> {
  inputPlaceholder: string
  pointerNodes: PointerNode<T>[]
  renderNode: (pointerNode: PointerNode<T>, index: number, array: PointerNode<T>[]) => ReactNode
  emptyState?: ReactNode
  action?: ReactNode
}

export function SearchableTreeList<T extends Tree>({
  pointerNodes,
  renderNode,
  inputPlaceholder,
  emptyState,
  action,
}: Props<T>) {
  const [search, setSearch] = useState('')

  const searchedPointerNodes = search
    ? pointerNodes.filter(({ node }) => node.name.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    : pointerNodes

  const filteredPointerNodes = useDeferredValue(sortBy(searchedPointerNodes, ({ node }) => node.name))

  return (
    <Flex as={FadeIn} direction="column" gap={4} data-testid="searchable-tree-list">
      {!!pointerNodes.length && (
        <>
          {pointerNodes.length >= 15 && (
            <WppInput
              size="s"
              type="search"
              className={styles.searchInput}
              placeholder={inputPlaceholder}
              onWppChange={({ detail }) => {
                setSearch(detail.value!.trim())
              }}
              data-testid="search-item"
            />
          )}
          {filteredPointerNodes.map((pointerNode, index, array) => (
            <Fragment key={pointerNode.nodePath}>{renderNode(pointerNode, index, array)}</Fragment>
          ))}
        </>
      )}

      {!filteredPointerNodes.length && (
        <>
          {!!emptyState && (
            <div className={styles.emptyState} data-testid="hierarchy-search-without-result">
              {emptyState}
            </div>
          )}
        </>
      )}

      {action}
    </Flex>
  )
}
