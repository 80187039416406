import { FramedMicroAppMethods, FramedMicroAppParentMethods } from '@wpp-open/core'
import { connectToChild, Methods } from 'penpal'
import { ComponentPropsWithoutRef, useEffect, useRef } from 'react'

import { useMicroAppsContext } from 'providers/microApps/MicroAppsProvider'

type Props = ComponentPropsWithoutRef<'iframe'> & {
  appId: string
}

export const PenpalParentFrame = ({ appId, ...iframeProps }: Props) => {
  const { getMicroAppCustomProps, unsubscribeApp } = useMicroAppsContext()
  const ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    const { osApi, osContext } = getMicroAppCustomProps({ code: appId })

    const parentMethods: Methods = {
      osApi,
    } satisfies FramedMicroAppParentMethods

    const connection = connectToChild<FramedMicroAppMethods>({
      iframe: ref.current!,
      methods: parentMethods,
      // TODO: Debug is temporarily enabled
      debug: true,
    })

    osContext.subscribe(osContext => {
      connection.promise.then(child => {
        child.receiveOsContext(osContext)
      })
    })

    connection.promise.catch(error => {
      console.error('Penpal parent context error:', error)
    })

    return () => {
      unsubscribeApp({ code: appId })
      connection.destroy()
    }
  }, [appId, getMicroAppCustomProps, unsubscribeApp])

  return <iframe ref={ref} {...iframeProps} />
}
