import { useState } from 'react'
import ReactPlayer from 'react-player'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/landing/aboutPage/video/Video.module.scss'
import { ReactComponent as PlayIcon } from 'pages/landing/assets/play.svg'
import { aboutPageVideoUrl } from 'pages/landing/const'

export const Video = () => {
  const [startPlay, setStartPlay] = useState(false)

  return (
    <Flex direction="column" justify="center" align="center" className={styles.block}>
      {startPlay ? (
        <ReactPlayer
          className={styles.video}
          width={1120}
          height={630}
          url={aboutPageVideoUrl}
          playIcon={<PlayIcon />}
          playing
          controls
        />
      ) : (
        <button className={styles.playButton} onClick={() => setStartPlay(true)} />
      )}
    </Flex>
  )
}
