import {
  WppTypography,
  WppIconCart,
  WppIconStatisticDocument,
  WppIconService,
} from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import benefitsListData from 'pages/landing/assets/offers/jsons/benefitsList.json'
import { Benefit, BenefitSection } from 'pages/landing/components/benefitSection/BenefitSection'
import { GetStartedSteps } from 'pages/landing/components/getStartedSteps/GetStartedSteps'
import { Hero } from 'pages/landing/components/hero/Hero'
import styles from 'pages/landing/offerPage/Offer.module.scss'

export const Offer = () => {
  const TABS = {
    MARKETPLACE: { id: 'marketplace', label: 'Marketplace', icon: () => <WppIconCart color="#47039B" /> },
    SERVICES: { id: 'services', label: 'Support services', icon: () => <WppIconService color="#47039B" /> },
    RESOURCES: { id: 'resources', label: 'Resources', icon: () => <WppIconStatisticDocument color="#47039B" /> },
  }

  const [selectedTab, setTab] = useState(TABS.MARKETPLACE.id)

  const getTabContent = (tabId: string) => {
    switch (tabId) {
      case TABS.MARKETPLACE.id:
        return benefitsListData.marketplace.map((benefit, id: number) => (
          <BenefitSection benefit={benefit} key={benefit.title} reverse={!(id % 2)} />
        ))
      case TABS.SERVICES.id:
        return benefitsListData.services.map((benefit: Benefit, id: number) => (
          <BenefitSection benefit={benefit} key={benefit.title} reverse={!(id % 2)} />
        ))
      case TABS.RESOURCES.id:
        return benefitsListData.resources.map((benefit, id: number) => (
          <BenefitSection benefit={benefit} key={benefit.title} reverse={!(id % 2)} />
        ))
    }
  }

  return (
    <Flex direction="column" align="center" className={styles.container}>
      <Hero
        title="What we offer"
        subtitle="Discover the range of tools and services designed to unlock  transformative collaboration."
      />
      <div className={styles.offerTabs}>
        <div className={styles.tabsHeader}>
          <WppTypography type="4xl-display">Explore the capabilities of Open</WppTypography>
        </div>

        <div className={styles.tabsSelector} data-testid="tab-selector">
          {Object.values(TABS).map(tab => {
            const active = tab.id === selectedTab
            return (
              <div
                key={tab.id}
                className={clsx(styles.tabSelector, { [styles.tabSelectorActive]: active })}
                onClick={() => setTab(tab.id)}
              >
                <Flex align="center" gap={16}>
                  <div className={clsx(styles.iconContainer, { [styles.iconContainerActive]: active })}>
                    {tab.icon()}
                  </div>
                  <WppTypography type="m-body" className={clsx({ [styles.tabSelectorLabelActive]: active })}>
                    {tab.label}
                  </WppTypography>
                </Flex>
              </div>
            )
          })}
        </div>

        <Flex direction="column" gap={80} data-testid="tab-content">
          {getTabContent(selectedTab)}
        </Flex>

        <div className={styles.getStartedSection}>
          <GetStartedSteps />
        </div>
      </div>
    </Flex>
  )
}
