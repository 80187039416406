import { useMemo } from 'react'

import defaultFavicon from 'assets/wpp-favicon.ico'
import { htmlDefaultValues } from 'constants/html'
import { usePublicData } from 'providers/publicData/PublicDataProvider'

export const useTenantLogoAndMetaData = () => {
  const { currentTenantPublic, defaultTenant } = usePublicData()

  return useMemo(
    () => ({
      logoOriginal: currentTenantPublic?.logoOriginal?.url || defaultTenant.logoOriginal?.url || undefined,
      logoThumbnail: currentTenantPublic?.logoThumbnail?.url || defaultTenant.logoThumbnail?.url || undefined,
      favicon: currentTenantPublic?.favicon?.url || defaultTenant.favicon?.url || defaultFavicon,
      loginBackground: currentTenantPublic?.loginBackground?.url || defaultTenant.loginBackground?.url || undefined,
      name: currentTenantPublic?.name || htmlDefaultValues.name,
      title: currentTenantPublic?.title || defaultTenant.title || htmlDefaultValues.title,
      description: currentTenantPublic?.description || defaultTenant.description || htmlDefaultValues.description,
      id: currentTenantPublic?.id || null,
    }),
    [currentTenantPublic, defaultTenant],
  )
}
