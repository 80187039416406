import { AppLibraryType, HierarchyLevelType } from '@wpp-open/core'

import { Permission } from 'constants/permission'
import { environment, isProductionLike } from 'environment'
import { OsDeploymentEnvironment } from 'environment/common'

export enum SystemAppCode {
  DevHub = 'DEVHUB',
  Marketplace = 'MARKETPLACE',
  Orchestration = 'ORCHESTRATION',
  News = 'NEWS',
  ThemeBuilder = 'THEME_BUILDER',
  Process = 'PROCESS',
}

export enum ExternalAppCode {
  SegmentOpportunitySimulatorUk = 'CH-SEGMENT-OPPORTUNITY-SIMULATOR-UK',
  SegmentOpportunitySimulatorUsUk = 'CH-SEGMENT-OPPORTUNITY-SIMULATOR-UK',
  SegmentOpportunitySimulatorUs = 'CH-SEGMENT-OPPORTUNITY-SIMULATOR-US',
  Datameter = 'CH-DATAMETER',
  NarrativeApp = 'NARRATIVE_APP',
  SitecoreAssetsReview = 'SITECORE_ASSETS_REVIEW',
  AugmentedOccasions = 'AUGMENTED_OCCASIONS',
  AudiencesProducerStub = 'AUDIENCES_PRODUCER_STUB',
  AudiencesConsumerStub = 'AUDIENCES_CONSUMER_STUB',
  BenefitsRoadmap = 'BENEFITS_ROADMAP',
  Questionnaire = 'QUESTIONNAIRE',
}

export interface AppData {
  name: string
  code: string
  baseUrl: string
  libraryType: AppLibraryType
  bundleUrl: string
  workspaceLevels: HierarchyLevelType[]
  windowLibraryName?: string
  permission?: string
}

export const LOCAL_APP_BASE_URL = 'local'
export const LOCAL_LEGACY_APP_BASE_URL = 'local-legacy'
export const LOCAL_APP_CODE = 'LOCAL'
export const LOCAL_APP_NAME = 'Local App'
export const LOCAL_APP_DEFAULT_URL_CONFIG = '8500/sjs/main'
export const LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG = '8500/main/localApp'

// TODO: Remove hardcode once we have a service to get this from
export const apps: Record<string, AppData> = {
  [SystemAppCode.News]: {
    name: 'News',
    code: SystemAppCode.News,
    baseUrl: 'news',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: environment.NEWS_BUNDLE_URL,
    // bundleUrl: 'http://localhost:8600/main.js',
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [SystemAppCode.Marketplace]: {
    name: 'Marketplace',
    code: SystemAppCode.Marketplace,
    baseUrl: 'marketplace',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: environment.MARKETPLACE_BUNDLE_URL,
    // bundleUrl: 'http://localhost:8600/main.js',
    permission: Permission.WPP_OPEN_MARKETPLACE_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [SystemAppCode.Orchestration]: {
    name: 'Orchestration',
    code: SystemAppCode.Orchestration,
    baseUrl: 'orchestration',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: environment.ORCHESTRATION_BUNDLE_URL,
    permission: Permission.WPP_ORCHESTRATION_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [SystemAppCode.DevHub]: {
    name: 'Dev Hub',
    code: SystemAppCode.DevHub,
    baseUrl: 'devhub',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: environment.DEVHUB_BUNDLE_URL,
    permission: Permission.WPP_DEVHUB_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [SystemAppCode.ThemeBuilder]: {
    name: 'Theme Builder',
    code: SystemAppCode.ThemeBuilder,
    baseUrl: 'theme-builder',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: environment.THEME_BUILDER_BUNDLE_URL,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },

  [ExternalAppCode.SegmentOpportunitySimulatorUk]: {
    name: 'Segment Opportunity Simulator UK',
    code: ExternalAppCode.SegmentOpportunitySimulatorUk,
    baseUrl: 'segment-opportunity-simulator',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: isProductionLike()
      ? 'https://os-prod-populationprojectionuk-osveracymq-nw.a.run.app/main.js'
      : 'https://os-staging-populationprojectionuk-osveracymq-nw.a.run.app/main.js',
    permission: Permission.SEGMENT_OPPORTUNITY_SIMULATOR_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [ExternalAppCode.SegmentOpportunitySimulatorUs]: {
    name: 'Segment Opportunity Simulator US',
    code: ExternalAppCode.SegmentOpportunitySimulatorUs,
    baseUrl: 'segment-opportunity-simulator-us',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: isProductionLike()
      ? 'https://os-prod-populationprojection-us-osveracymq-nw.a.run.app/main.js'
      : 'https://os-staging-populationprojection-us-osveracymq-nw.a.run.app/main.js',
    permission: Permission.SEGMENT_OPPORTUNITY_SIMULATOR_US_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [ExternalAppCode.Datameter]: {
    name: 'Datameter',
    code: ExternalAppCode.Datameter,
    baseUrl: 'datameter',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: isProductionLike()
      ? 'https://datameter.choreograph.com/dmtrui/main.js'
      : environment.ENV === OsDeploymentEnvironment.Stage
      ? 'https://datameter-npd-int.choreograph.com/dmtrui/main.js'
      : 'https://datameter-npd-dev.choreograph.com/dmtrui/main.js',
    permission: Permission.OS_DMTR_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [ExternalAppCode.AudiencesConsumerStub]: {
    name: 'Audiences Consumer Stub',
    code: ExternalAppCode.AudiencesConsumerStub,
    baseUrl: 'audience-consumer-stub',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: 'https://consumer-demo-fe-ch-hulk.os-dev.io/',
    permission: Permission.WPP_DATA_STREAM_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Brand],
  },
  [ExternalAppCode.AudiencesProducerStub]: {
    name: 'Audiences Producer Stub',
    code: ExternalAppCode.AudiencesProducerStub,
    baseUrl: 'audience-producer-stub',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: 'https://producer-demo-fe-ch-hulk.os-dev.io/',
    permission: Permission.WPP_DATA_STREAM_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Brand],
  },
  [ExternalAppCode.BenefitsRoadmap]: {
    name: 'Benefits Roadmap',
    code: ExternalAppCode.BenefitsRoadmap,
    baseUrl: 'benefits-roadmap',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: 'https://benefits-roadmap-fe-ch-hulk.os-dev.io/',
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [ExternalAppCode.SitecoreAssetsReview]: {
    name: 'Sitecore Asset Review',
    code: ExternalAppCode.SitecoreAssetsReview,
    baseUrl: 'sitecore-assets-review',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: environment.SITECORE_ASSETS_REVIEW_BUNDLE_URL,
    permission: Permission.ASSETS_REVIEW_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [ExternalAppCode.AugmentedOccasions]: {
    name: 'Augmented Occasions',
    code: ExternalAppCode.AugmentedOccasions,
    baseUrl: 'augmented-occasions',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: environment.AUGMENTED_OCCASIONS_BUNDLE_URL,
    permission: Permission.AUGMENTED_OCCASIONS_APP_ACCESS,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [ExternalAppCode.NarrativeApp]: {
    name: 'Narrative App',
    code: ExternalAppCode.NarrativeApp,
    baseUrl: 'narrative',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: 'https://narratives-composer-fe-ch-pitch.wppopen.com/main.js',
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [ExternalAppCode.Questionnaire]: {
    name: 'Questionnaire',
    code: ExternalAppCode.Questionnaire,
    baseUrl: 'questionnaire-app',
    libraryType: AppLibraryType.SystemJS,
    permission: Permission.PREREQUISITES_APP_FORM_VIEW,
    bundleUrl: 'https://questionnaire-fe-ch-pitch.wppopen.com/main.js',
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
  [SystemAppCode.Process]: {
    name: 'Process',
    code: SystemAppCode.Process,
    baseUrl: 'process',
    libraryType: AppLibraryType.SystemJS,
    bundleUrl: environment.PROCESS_BUILDER_BUNDLE_URL,
    workspaceLevels: [HierarchyLevelType.Tenant],
  },
}

export const appsList = Object.values(apps)
