import { WppActionButton, WppIconMore, WppListItem, WppMenuContext } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, ElementType, useMemo, useState } from 'react'

import styles from 'components/treeList/treeListItem/TreeListItem.module.scss'

export type TreeListItemProps = ComponentPropsWithoutRef<typeof WppListItem> & {
  menuItems?: {
    label: string
    icon: ElementType
    disabled?: boolean
    onClick: () => void
  }[]
}

export const TreeListItem = ({ menuItems = [], children, className, ...rest }: TreeListItemProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const dropdownConfig: ComponentPropsWithoutRef<typeof WppMenuContext>['dropdownConfig'] = useMemo(
    () => ({
      onShow: () => setIsOpen(true),
      onHide: () => setIsOpen(false),
    }),
    [],
  )

  const withMenu = !!menuItems.length

  return (
    <WppListItem
      data-testid="tree-list-item"
      {...rest}
      className={clsx(
        styles.root,
        {
          [styles.withMenu]: withMenu,
          [styles.menuOpened]: isOpen,
        },
        className,
      )}
    >
      {children}
      {withMenu && (
        <div
          slot="right"
          className={styles.contextMenu}
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <WppMenuContext dropdownConfig={dropdownConfig}>
            <WppActionButton slot="trigger-element" variant="secondary" data-testid="tree-list-item-menu-trigger">
              <WppIconMore direction="horizontal" slot="icon-start" />
            </WppActionButton>

            {menuItems.map(({ label, icon: Icon, disabled, onClick }) => (
              <WppListItem key={label} disabled={disabled} onWppChangeListItem={onClick}>
                <Icon slot="left" />
                <span slot="label">{label}</span>
              </WppListItem>
            ))}
          </WppMenuContext>
        </div>
      )}
    </WppListItem>
  )
}
