import { Outlet } from 'react-router-dom'

import { ForbiddenPageError } from 'components/renderError'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'

export const PermittedAccess = ({ permission }: { permission: Permission }) => {
  const { hasPermission } = useHasPermission()
  return hasPermission(permission) ? <Outlet /> : <ForbiddenPageError />
}
