import { Outlet } from 'react-router-dom'

import { NotFoundError } from 'components/renderError'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'

export const IsCentralTenant = () => {
  const { currentTenant } = useTenantAndUserData()
  const centralTenantId = '5818c50f-bc1d-4c2b-bb41-431dcf4a3bcb'
  return currentTenant.id === centralTenantId ? <Outlet /> : <NotFoundError />
}
