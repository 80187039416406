import { detectIncognito } from 'detect-incognito'

export const getShouldMonitorSession = async () => {
  try {
    const { browserName, isPrivate } = await detectIncognito()

    return browserName === 'Chrome' && !isPrivate
  } catch {
    return false
  }
}
