import { PropsWithChildren } from 'react'

import { InfoBanner } from 'layout/infoBanner/InfoBanner'
import styles from 'layout/pageContainer/PageContainer.module.scss'

export const PageContainer = ({ children }: PropsWithChildren<{}>) => (
  <main className={styles.root}>
    <InfoBanner />
    {children}
  </main>
)
