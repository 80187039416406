import { WppIconApp, WppListItem } from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, LeanAppType } from '@wpp-open/core'

import { useHasPermission } from 'hooks/useHasPermission'
import { BreadcrumbMenuLeanAppItem } from 'layout/header/breadcrumbs/contextMenu/leanAppItem/BreadcrumbMenuLeanAppItem'
import { matchLeanApp } from 'layout/header/breadcrumbs/contextMenu/projectAppItem/utils'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useData } from 'providers/data/DataProvider'
import { MicroAppFromUrlType } from 'types/appState/appState'
import { ProjectCanvasApplication, ProjectCanvasApplicationType } from 'types/projects/projectPhase'

interface Props {
  projectId: string
  app: ProjectCanvasApplication
}

export const ProjectBreadcrumbsAppItem = ({ app, projectId }: Props) => {
  const { leanApps, navigationTree } = useData()
  const { microAppData } = useAppState()
  const { hasPermission } = useHasPermission()

  const { type: activeAppType, workspaceLevels, projectItem: activeProjectItem } = microAppData

  const leanApp = matchLeanApp(leanApps, app)
  const instanceId = app.type === ProjectCanvasApplicationType.MiroBoardInstance ? app.externalAppId : undefined
  const selectedWorkspaceType =
    workspaceLevels.length > 0
      ? (navigationTree.mapping[workspaceLevels.at(-1)!].type as HierarchyLevelType)
      : HierarchyLevelType.Tenant

  const canLaunch =
    (leanApp?.type !== LeanAppType.CAAS && leanApp?.type !== LeanAppType.NATIVE) ||
    (hasPermission(leanApp.config.viewPermissionName) &&
      (!leanApp.config.requiredHierarchy.length || leanApp.config.requiredHierarchy.includes(selectedWorkspaceType)))
  const isChecked =
    (activeAppType === MicroAppFromUrlType.LEAN || activeAppType === MicroAppFromUrlType.LEGACY) &&
    activeProjectItem?.id === app.id

  if (leanApp && canLaunch) {
    return (
      <div>
        <BreadcrumbMenuLeanAppItem
          isChecked={isChecked}
          leanApp={leanApp}
          customAppName={app.name}
          projectId={projectId}
          projectItemId={String(app.id)}
          instanceId={instanceId}
        />
      </div>
    )
  }

  return (
    <div>
      <WppListItem disabled data-testid="breadcrumbs-menu-app-item">
        <WppIconApp slot="left" />
        <span slot="label">{app.name}</span>
      </WppListItem>
    </div>
  )
}
