import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { SystemAppCode } from 'constants/apps'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useData } from 'providers/data/DataProvider'
import { MicroAppFromUrlType } from 'types/appState/appState'
import {
  BreadcrumbItemHierarchy,
  BreadcrumbItemHierarchyType,
  BreadcrumbItemProjectType,
  BreadcrumbsData,
  BreadcrumbsType,
} from 'types/appState/breadcrumbs'
import { ProjectProcessType } from 'types/projects/project'
import { routesManager } from 'utils/routesManager'

export const useBreadcrumbs = (): BreadcrumbsData => {
  const { t } = useTranslation()
  const { navigationTree } = useData()
  const { microAppData } = useAppState()

  const { mapping } = navigationTree
  const { workspaceLevels, type, app, project, projectPhase, projectItem } = microAppData

  return useMemo(() => {
    const isLoading = type === MicroAppFromUrlType.LOADING
    const isSystemApp = type === MicroAppFromUrlType.LATEST && Object.values<string>(SystemAppCode).includes(app.code)
    const isWorkspaceSet = workspaceLevels.length > 0
    const isInvalidWithoutWorkspace = type === MicroAppFromUrlType.INVALID && !isWorkspaceSet
    const isProjectSet = !!project
    const isEmpty = (isSystemApp || isInvalidWithoutWorkspace) && !isProjectSet
    const appName = projectItem?.name || app?.name || ''

    if (isLoading || isEmpty) {
      return {
        type: BreadcrumbsType.Hierarchy,
        items: [],
      }
    }

    if (isProjectSet) {
      const isLinearProject = project.processType === ProjectProcessType.Linear
      const isProjectPhaseSet = !!projectPhase

      return {
        type: isLinearProject ? BreadcrumbsType.ProjectLinear : BreadcrumbsType.ProjectFluid,
        items: [
          {
            type: BreadcrumbItemProjectType.Home,
            label: t('os.header.home'),
            link: routesManager.internalPages.home.root(),
          },
          {
            type: BreadcrumbItemProjectType.Projects,
            label: t('os.header.projects'),
            link: routesManager.systemApps.orchestration.projects(),
          },
          {
            type: BreadcrumbItemProjectType.Project,
            label: project.name,
            id: project.id,
            link: routesManager.systemApps.orchestration.project(project.id),
          },
          {
            type: BreadcrumbItemProjectType.Application,
            label: isProjectPhaseSet ? `${projectPhase.name} – ${appName}` : appName,
          },
        ],
      }
    }

    return {
      type: BreadcrumbsType.Hierarchy,
      items: [
        {
          type: BreadcrumbItemHierarchyType.Home,
          label: t('os.header.home'),
          link: routesManager.internalPages.home.root(),
        },
        ...workspaceLevels.map<BreadcrumbItemHierarchy>(nodeAzId => ({
          type: BreadcrumbItemHierarchyType.Hierarchy,
          label: mapping[nodeAzId].name || '',
          id: nodeAzId,
        })),
        {
          type: BreadcrumbItemHierarchyType.Application,
          label: appName,
        },
      ],
    }
  }, [app, mapping, project, projectPhase, projectItem, t, type, workspaceLevels])
}
