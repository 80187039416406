import { MicroAppContext, MicroAppContextListener, MicroAppCustomProps, MiroAuthorizeFn } from '@wpp-open/core'
import PubSub from 'pubsub-js'
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { NOT_MOUNTED, SingleSpaCustomEventDetail } from 'single-spa'

import { useStableCallback } from 'hooks/useStableCallback'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useAuth } from 'providers/auth/AuthProvider'
import { useData } from 'providers/data/DataProvider'
import {
  mapResolvedWorkspaceToContext,
  useOpenApp,
  useOpenLeanApp,
  useOpenProjectLeanApp,
} from 'providers/microApps/utils'
import { usePublicData } from 'providers/publicData/PublicDataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { trackAnalytics } from 'utils/analytics'
import { useInitiateMiroOAuth2Flow } from 'utils/miro'
import { unregisterAllApps } from 'utils/singleSpa'

export interface MicroAppsContextValue {
  isMicroAppActive: boolean
  setIsMicroAppActive: Dispatch<SetStateAction<boolean>>
  getMicroAppCustomProps: (app: { code: string }) => MicroAppCustomProps
  unsubscribeApp: (app: { code: string }) => void
}

export const MicroAppsContext = createContext<MicroAppsContextValue>(null!)

export const useMicroAppsContext = () => useContext(MicroAppsContext)

export const MicroAppsProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isMicroAppActive, setIsMicroAppActive] = useState(false)
  const { navigationTree } = useData()
  const { microAppData, openNavigation } = useAppState()
  const openApp = useOpenApp()
  const openLeanApp = useOpenLeanApp()
  const openProjectLeanApp = useOpenProjectLeanApp()
  const { jwt } = useAuth()
  const { currentTenant, userDetails } = useTenantAndUserData()
  const { permissions } = useData()
  const { resolvedTheme, currentTaxonomy } = usePublicData()
  const subscribersRef = useRef(new Set<string>())
  const { handleInitiateMiroOAuth2Flow } = useInitiateMiroOAuth2Flow()

  const { workspaceLevels, currentBaseUrl, project, projectItem } = microAppData

  const tokenRef = useRef(jwt)
  tokenRef.current = jwt

  const microAppsContextValue = useMemo<MicroAppContext>(() => {
    const projectData: MicroAppContext['project'] =
      project && projectItem
        ? {
            id: project.id,
            name: project.name,
            type: project.type,
            itemId: String(projectItem.id),
            itemCompleteState: projectItem.task?.status ?? null,
          }
        : null

    return {
      baseUrl: currentBaseUrl,
      workspace: mapResolvedWorkspaceToContext({ workspaceLevels, navigationTree }),
      project: projectData,
      permissions,
      tenant: currentTenant,
      navigationTree,
      userDetails,
      theme: resolvedTheme,
      taxonomy: currentTaxonomy,
    }
  }, [
    currentBaseUrl,
    currentTenant,
    navigationTree,
    permissions,
    project,
    projectItem,
    resolvedTheme,
    userDetails,
    workspaceLevels,
    currentTaxonomy,
  ])

  const microAppsContextValueRef = useRef(microAppsContextValue)
  microAppsContextValueRef.current = microAppsContextValue

  useEffect(() => {
    subscribersRef.current.forEach(appCode => {
      PubSub.publish(appCode, microAppsContextValue)
    })
  }, [microAppsContextValue])

  useEffect(() => {
    const handler = ({ detail }: CustomEvent<SingleSpaCustomEventDetail>) => {
      detail.appsByNewStatus[NOT_MOUNTED].forEach(appCode => {
        PubSub.unsubscribe(appCode)
        subscribersRef.current.delete(appCode)
      })
    }

    window.addEventListener('single-spa:before-app-change', handler)

    const subscriberNames = subscribersRef.current

    // Cleanup is called only when we enter public routes
    return () => {
      subscriberNames.forEach(appCode => {
        PubSub.unsubscribe(appCode)
      })

      window.removeEventListener('single-spa:before-app-change', handler)

      // As we cancel app subscriptions, we also unregister the apps
      unregisterAllApps()
    }
  }, [])

  const handleSubscribeToAppState = useCallback((appCode: string, listener: MicroAppContextListener) => {
    const subscriberToken = PubSub.subscribe(appCode, (msg, value) => {
      listener(value)
    })

    PubSub.publish(appCode, microAppsContextValueRef.current)
    subscribersRef.current.add(appCode)

    return () => {
      PubSub.unsubscribe(subscriberToken)

      const wasLast = PubSub.countSubscriptions(appCode) === 0

      if (wasLast) {
        subscribersRef.current.delete(appCode)
      }
    }
  }, [])

  const handleUnsubscribeFromAppState = useCallback(({ code }: { code: string }) => {
    PubSub.unsubscribe(code)
    subscribersRef.current.delete(code)
  }, [])

  const getAccessToken = useCallback(() => tokenRef.current, [])

  const handleMiroAuthorize = useStableCallback<MiroAuthorizeFn>(({ context }) =>
    handleInitiateMiroOAuth2Flow({ context }),
  )

  const getMicroAppCustomProps = useCallback(
    ({ code }: { code: string }): MicroAppCustomProps => {
      return {
        domElementGetter: () => document.getElementById('micro-app')!,
        osApi: {
          getAccessToken,
          analytics: {
            track: trackAnalytics,
          },
          navigation: {
            openMenu: openNavigation,
            openApp,
            openLeanApp,

            /** Temporary for Q1 Orchestration */
            openProjectLeanApp,
          },
          miro: {
            authorize: handleMiroAuthorize,
          },
        },
        osContext: {
          subscribe: listener => handleSubscribeToAppState(code, listener),
        },
      }
    },
    [
      getAccessToken,
      handleSubscribeToAppState,
      openApp,
      openLeanApp,
      openNavigation,
      openProjectLeanApp,
      handleMiroAuthorize,
    ],
  )

  return (
    <MicroAppsContext.Provider
      value={{
        isMicroAppActive,
        setIsMicroAppActive,
        getMicroAppCustomProps,
        unsubscribeApp: handleUnsubscribeFromAppState,
      }}
    >
      {children}
    </MicroAppsContext.Provider>
  )
}
