import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/landing/aboutPage/About.module.scss'
import { Video } from 'pages/landing/aboutPage/video/Video'
import benefitsListData from 'pages/landing/assets/about/jsons/benefitsList.json'
import pitchDevelopmentSrc from 'pages/landing/assets/about/Pitch.gif'
import solutionDevelopmentSrc from 'pages/landing/assets/about/Solution development.gif'
import waysOfWorkingSrc from 'pages/landing/assets/about/Ways of working.gif'
import workSmarterWithAISrc from 'pages/landing/assets/about/Work smarter with AI.gif'
import { Benefit, BenefitSection } from 'pages/landing/components/benefitSection/BenefitSection'
import { GetStartedSteps } from 'pages/landing/components/getStartedSteps/GetStartedSteps'
import { Hero } from 'pages/landing/components/hero/Hero'

const images = [waysOfWorkingSrc, workSmarterWithAISrc, pitchDevelopmentSrc, solutionDevelopmentSrc]

export const About = () => {
  const benefitsList: Benefit[] = benefitsListData?.data.map((benefit, index) => ({ ...benefit, image: images[index] }))
  return (
    <Flex direction="column" align="center" className={styles.container}>
      <Hero
        title="About Open"
        subtitle="WPP Open connects everything your team needs, empowering you to ignite radical innovation"
      />

      <Flex direction="column" align="center" className={styles.content}>
        <Video />

        <Flex direction="column" gap={80}>
          {benefitsList.map((benefit, id) => (
            <BenefitSection
              benefit={benefit}
              key={benefit.title}
              reverse={!(id % 2)}
              imageClassName={styles.benefitsImage}
              imageTestId="about-page-gif-image"
            />
          ))}
        </Flex>
      </Flex>

      {/* Temporary hide this section */}
      {/* <Flex className={styles.demoWrapper} justify="center" align="center" gap={180}>
        <Flex className={styles.content} align="center" justify="between">
          <Flex direction="column" className={styles.demoDescription} align="start">
            <WppTypography type="3xl-heading">Access the interactive demo</WppTypography>

            <WppTypography type="l-body">
              You will be able to try out the platform as if it was for one of your clients or a version with multiple
              clients
            </WppTypography>
          </Flex>

          <Button size="m" component={Link} to={demoTenantLink}>
            <WppTypography type="l-midi" className={styles.demoButtonText}>
              Try it out!
            </WppTypography>
          </Button>
        </Flex>
      </Flex> */}

      <GetStartedSteps />
    </Flex>
  )
}
