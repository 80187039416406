import { WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType, TenantConfigNavigationType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'
import { useMatch, useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/navigationMenu/navigationMenuContent/composedNavigationMenuColumn/ComposedNavigationMenuColumn.module.scss'
import { LeanAppNavigationMenuItem } from 'components/navigationMenu/navigationMenuContent/leanAppNavigationMenuItem/LeanAppNavigationMenuItem'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import { useNavigationMenuController } from 'components/navigationMenu/navigationMenuContent/NavigationMenuControllerContext'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useData } from 'providers/data/DataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS, getEventPayload } from 'utils/events'
import {
  useHierarchyLevelLabel,
  getHierarchyContainerNodePath,
  getPointerNodeLeanAppsChildren,
} from 'utils/mapping/common'
import {
  getIsHierarchyColumnVisible,
  getIsProjectsColumnVisible,
  getProjectsContainerNodePath,
} from 'utils/mapping/navigationTree'
import { isNavigationTypeEnabled } from 'utils/navigation'
import { routesManager } from 'utils/routesManager'

export const ComposedNavigationMenuColumn = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { navigationTree } = useData()
  const { currentTenant, navigationHierarchy } = useTenantAndUserData()
  const { closeNavigation } = useAppState()
  const { nodesPathsMapping } = useHeaderNavigation()
  const { localNodePath, setLocalNodePath } = useNavigationMenuController()

  const homePageUrl = routesManager.internalPages.home.root()
  const homeMatch = useMatch(`${homePageUrl}/*`)

  const firstHierarchyLevelLabel = useHierarchyLevelLabel({
    levelType: navigationHierarchy[0]?.type || null,
    isPlural: true,
  })

  const isHierarchyNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Hierarchy,
    currentTenant,
  })

  const isProjectsNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Projects,
    currentTenant,
  })

  const isCustomNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Custom,
    currentTenant,
  })

  return (
    <NavigationMenuTreeListColumn data-testid="composed-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <WppTypography type="l-strong" data-testid="composed-navigation-menu-column-title">
          {currentTenant.name}
        </WppTypography>
      </NavigationMenuColumnHeader>

      <Flex gap={4} direction="column">
        <TreeListItem
          checked={!!homeMatch}
          linkConfig={{
            href: homePageUrl,
            onClick: e => e.preventDefault(),
          }}
          onClick={() => {
            navigate(homePageUrl)
            closeNavigation()
            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: getEventPayload(EVENTS.HAMBURGER_MENU.ACTIONS.ACTION_HUMBURGER_MENU_ITEM, 'home'),
            })
          }}
        >
          <span slot="label">{t('os.header.home')}</span>
        </TreeListItem>

        {isProjectsNavigationEnabled && (
          <TreeListItem
            className={styles.containerItem}
            checked={getIsProjectsColumnVisible(localNodePath)}
            onClick={e => {
              if (!e.currentTarget.checked) {
                setLocalNodePath(getProjectsContainerNodePath(navigationTree))
              }
              trackAnalytics({
                type: AnalyticsActionType.action,
                payload: getEventPayload(EVENTS.HAMBURGER_MENU.ACTIONS.ACTION_HUMBURGER_MENU_ITEM, 'project'),
              })
            }}
          >
            <span slot="label">{t('os.entities.project', { count: DEFAULT_PLURAL_COUNT })}</span>
          </TreeListItem>
        )}

        {isHierarchyNavigationEnabled && (
          <TreeListItem
            className={styles.containerItem}
            checked={getIsHierarchyColumnVisible(localNodePath)}
            onClick={() => {
              setLocalNodePath(getHierarchyContainerNodePath(navigationTree))
              trackAnalytics({
                type: AnalyticsActionType.action,
                payload: getEventPayload(
                  EVENTS.HAMBURGER_MENU.ACTIONS.ACTION_HUMBURGER_MENU_ITEM,
                  firstHierarchyLevelLabel,
                ),
              })
            }}
          >
            <span slot="label">{firstHierarchyLevelLabel}</span>
          </TreeListItem>
        )}

        {isCustomNavigationEnabled && (
          <>
            {(() => {
              const customNavigationPointerNodes = getPointerNodeLeanAppsChildren(
                nodesPathsMapping[navigationTree.rootId],
              )

              return (
                <>
                  {customNavigationPointerNodes.length ? (
                    <Flex direction="column" gap={4} data-testid="custom-navigation-menu-list">
                      {customNavigationPointerNodes.map(pointerNode => (
                        <LeanAppNavigationMenuItem key={pointerNode.nodePath} pointerNode={pointerNode} />
                      ))}
                    </Flex>
                  ) : (
                    <>
                      {!isHierarchyNavigationEnabled && !isProjectsNavigationEnabled && (
                        <WppTypography data-testid="no-items-label" className={styles.emptyState} type="s-midi">
                          {t('os.navigation_menu.no_custom_menu_items')}
                        </WppTypography>
                      )}
                    </>
                  )}
                </>
              )
            })()}
          </>
        )}
      </Flex>
    </NavigationMenuTreeListColumn>
  )
}
