export const slideVariants = {
  hiddenRight: {
    x: '100%',
    opacity: 0,
  },
  hiddenLeft: {
    x: '-100%',
    opacity: 0,
  },
  visible: {
    x: '0',
    opacity: 1,
    transition: {
      duration: 1,
    },
  },
  exit: {
    opacity: 0,
  },
}

export const dotsVariants = {
  initial: {
    y: 0,
  },
  animate: {
    transition: { type: 'spring', stiffness: 1000, damping: '10' },
  },
  hover: {
    scale: 1.1,
    transition: { duration: 0.2 },
  },
}
