import { WppIconApp, WppIconExternalLink, WppListItem } from '@platform-ui-kit/components-library-react'
import { LeanAppsMappingNode, LeanAppType, MayBeNull } from '@wpp-open/core'
import { ComponentProps } from 'react'
import { useNavigate } from 'react-router-dom'

import { LeanAppShort } from 'types/apps/leanApps'
import { getLeanAppUrl } from 'utils/navigation'

interface Props {
  isChecked: boolean
  leanApp: LeanAppsMappingNode | LeanAppShort
  customAppName?: MayBeNull<string>
  projectId?: MayBeNull<string>
  projectItemId?: MayBeNull<string>
  instanceId?: string
  workspaceAzId?: string
  onClick?: ComponentProps<typeof WppListItem>['onWppChangeListItem']
}

export const BreadcrumbMenuLeanAppItem = ({
  isChecked,
  leanApp,
  customAppName,
  onClick,
  ...leanAppUrlParams
}: Props) => {
  const navigate = useNavigate()

  const appName = customAppName || leanApp.name
  const href = getLeanAppUrl({
    leanApp,
    ...leanAppUrlParams,
  })

  if (leanApp.type === LeanAppType.LINK_DETACHED) {
    return (
      <WppListItem
        onWppChangeListItem={e => {
          if (!isChecked) {
            onClick?.(e)
          }
        }}
        linkConfig={{
          target: '_blank',
          rel: 'noreferrer',
          href: leanApp.config.url,
        }}
        data-testid="breadcrumbs-menu-app-item"
      >
        <WppIconApp slot="left" />
        <span slot="label">{appName}</span>
        <WppIconExternalLink slot="right" />
      </WppListItem>
    )
  }

  return (
    <WppListItem
      checked={isChecked}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onWppChangeListItem={e => {
        if (!isChecked) {
          onClick?.(e)
          navigate(href)
        }
      }}
      data-testid="breadcrumbs-menu-app-item"
    >
      <WppIconApp slot="left" />
      <span slot="label">{appName}</span>
    </WppListItem>
  )
}
