import {
  LeanAppType,
  MayBeNull,
  NavigationTree,
  ProjectsContainerNodeId,
  Tenant,
  TenantConfigNavigationType,
} from '@wpp-open/core'
import { matchPath } from 'react-router-dom'

import { apps, SystemAppCode } from 'constants/apps'
import { MicroAppDataFromUrlFull, MicroAppFromUrlType } from 'types/appState/appState'
import { createNodePath, getHierarchyContainerNodePath, NodesPathsMapping } from 'utils/mapping/common'
import { getProjectsContainerNodePath } from 'utils/mapping/navigationTree'
import { getNavigationTypeFlags } from 'utils/navigation'

export const getActiveNodePathFromAppState = ({
  pathname,
  currentTenant,
  navigationTree,
  microAppData,
  nodesPathsMapping,
  instanceId,
}: {
  pathname: string
  currentTenant: Tenant
  navigationTree: NavigationTree
  microAppData: MicroAppDataFromUrlFull
  nodesPathsMapping: NodesPathsMapping<NavigationTree>
  instanceId: MayBeNull<string>
}) => {
  const { type, app, workspaceLevels, project } = microAppData
  const { rootId, mapping, projectsMapping } = navigationTree

  const hierarchyFlags = getNavigationTypeFlags({
    type: TenantConfigNavigationType.Hierarchy,
    currentTenant,
  })

  const projectsFlags = getNavigationTypeFlags({
    type: TenantConfigNavigationType.Projects,
    currentTenant,
  })

  const customFlags = getNavigationTypeFlags({
    type: TenantConfigNavigationType.Custom,
    currentTenant,
  })

  const nodePaths = Object.keys(nodesPathsMapping)

  if (type === MicroAppFromUrlType.LEAN || type === MicroAppFromUrlType.LEGACY || type === MicroAppFromUrlType.LATEST) {
    let appId: MayBeNull<string>

    if (type === MicroAppFromUrlType.LATEST) {
      appId = app.code
    } else if (app.type === LeanAppType.MIRO_BOARD) {
      appId = instanceId
    } else {
      appId = app.id
    }

    const nodePath = workspaceLevels.length
      ? nodePaths.find(nodePath => nodePath.endsWith(createNodePath([...workspaceLevels, appId])))
      : [
          // App on custom navigation
          createNodePath([rootId, appId]),
          // Or App assigned to hierarchy container node
          createNodePath([getHierarchyContainerNodePath(navigationTree), appId]),
        ].find(nodePath => nodesPathsMapping[nodePath])

    if (nodePath) {
      return nodePath
    }
  }

  if (type === MicroAppFromUrlType.LATEST && app.code === SystemAppCode.Orchestration && projectsFlags.enabled) {
    const matchProjectsList = matchPath(`/${apps[SystemAppCode.Orchestration].baseUrl}/projects/*`, pathname)
    const matchProject = matchPath(`/${apps[SystemAppCode.Orchestration].baseUrl}/project/:projectId/*`, pathname)
    const projectId = matchProject?.params.projectId

    if (projectId && projectsMapping[projectId]) {
      return createNodePath([getProjectsContainerNodePath(navigationTree), projectId])
    } else if ((projectId || matchProjectsList) && mapping[ProjectsContainerNodeId]) {
      return createNodePath([getProjectsContainerNodePath(navigationTree)])
    }
  }

  if (workspaceLevels.length && !project) {
    return nodePaths.find(nodePath => nodePath.endsWith(createNodePath(workspaceLevels)))!
  }

  // If only Hierarchy navigation enabled
  if (hierarchyFlags.enabled && !projectsFlags.enabled && !customFlags.enabled) {
    return getHierarchyContainerNodePath(navigationTree)
  }

  // If only projects navigation enabled
  if (projectsFlags.enabled && !hierarchyFlags.enabled && !customFlags.enabled) {
    return getProjectsContainerNodePath(navigationTree)
  }

  // If several navigation types enabled - choose one that is set to be opened by default
  if (hierarchyFlags.enabled && hierarchyFlags.expanded) {
    return getHierarchyContainerNodePath(navigationTree)
  }

  if (projectsFlags.enabled && projectsFlags.expanded) {
    return getProjectsContainerNodePath(navigationTree)
  }

  return rootId
}
