import { apps, LOCAL_APP_BASE_URL, LOCAL_APP_DEFAULT_URL_CONFIG, SystemAppCode } from 'constants/apps'
import { WORKSPACE_URL_PREFIX } from 'providers/appState/utils'

export const routesManager = {
  systemApps: {
    news: {
      root: () => `/${apps[SystemAppCode.News].baseUrl}`,
      article: (id: string) => `/${apps[SystemAppCode.News].baseUrl}/${id}`,
      settings: () => `/${apps[SystemAppCode.News].baseUrl}/settings`,
    },
    devhub: () => `/${apps[SystemAppCode.DevHub].baseUrl}`,
    marketplace: () => `/${apps[SystemAppCode.Marketplace].baseUrl}`,
    themeBuilder: () => `/${apps[SystemAppCode.ThemeBuilder].baseUrl}`,
    orchestration: {
      root: () => `/${apps[SystemAppCode.Orchestration].baseUrl}`,
      projects: () => `/${apps[SystemAppCode.Orchestration].baseUrl}/projects`,
      project: (projectId: string) => `/${apps[SystemAppCode.Orchestration].baseUrl}/project/${projectId}`,
      templates: () => `/${apps[SystemAppCode.Orchestration].baseUrl}/templates`,
      template: (templateId: string) =>
        `/${apps[SystemAppCode.Orchestration].baseUrl}/templates?view=template&id=${templateId}`,
    },
    local: ({ workspaceId }: { workspaceId?: string }) => {
      const workspacePart = workspaceId ? `/${WORKSPACE_URL_PREFIX}/${workspaceId}` : ''

      return `${workspacePart}/${LOCAL_APP_BASE_URL}/${LOCAL_APP_DEFAULT_URL_CONFIG}`
    },
  },
  internalPages: {
    public: {
      miroAuthCallback: () => '/miro-auth-callback',
    },
    home: {
      root: () => '/home',
      edit: () => '/home/edit',
    },
    network: {
      root: () => '/network',
      contacts: () => '/network/contacts',
      primaryContacts: () => '/network/contacts/primary',
    },
    links: {
      root: () => '/links',
    },
    admin: {
      root: () => '/admin',
      navigation: () => '/admin/navigation',
      platformConfiguration: {
        root: () => '/admin/platform-config',
        masterData: () => '/admin/platform-config/master-data',
      },
      members: {
        root: () => '/admin/members',
        groups: () => '/admin/members/groups',
      },
      roles: () => '/admin/roles',
      hierarchy: {
        root: () => '/admin/hierarchy',
        settings: () => '/admin/hierarchy/settings',
      },
      apps: {
        root: () => '/admin/apps',
        leanApps: () => '/admin/apps/lean',
      },
      requests: {
        root: () => '/admin/requests',
        tenantAccessRequests: {
          root: () => '/admin/requests/tenant-access',
          completed: () => '/admin/requests/tenant-access/completed',
        },
        signUpRequests: {
          root: () => '/admin/requests/sign-up',
          completed: () => '/admin/requests/sign-up/completed',
        },
      },
      settings: () => '/admin/settings',
    },
    user: {
      root: () => '/user',
      profile: () => '/user/profile',
    },
    pitchPlaybook: {
      root: () => '/pitch-playbook',
    },
  },
}
