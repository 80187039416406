import { LeanAppType } from '@wpp-open/core'
import { useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { ForbiddenPageError, NotFoundError, CriticalError } from 'components/renderError'
import { useHasPermission } from 'hooks/useHasPermission'
import { LegacyMicroAppContainer } from 'legacy/LegacyMicroAppContainer'
import { LeanApp } from 'pages/leanApp/LeanApp'
import { MicroApp } from 'pages/microApp/MicroApp'
import { mapMicroAppDataToApps, useMicroAppErrorHandler } from 'pages/microAppsNavigation/utils'
import { useAppState } from 'providers/appState/AppStateProvider'
import {
  DataFromUrlFull,
  InvalidAppDataFromUrl,
  LoadingAppDataFromUrlFull,
  MicroAppDataFromUrlFull,
  MicroAppFromUrlType,
} from 'types/appState/appState'
import { getLeanAppUrl } from 'utils/navigation'

export const MicroAppsNavigation = () => {
  const { microAppData } = useAppState()
  const { isMicroAppError } = useMicroAppErrorHandler()

  if (microAppData.type === MicroAppFromUrlType.LOADING) {
    console.warn("This component shouldn't be rendered when data is still loading")

    return null
  }

  if (microAppData.type === MicroAppFromUrlType.INVALID) {
    console.warn('Invalid navigation url', location)

    return <NotFoundError />
  }

  return (
    <>
      {isMicroAppError && <CriticalError />}

      <RenderApps microAppData={microAppData} />
    </>
  )
}

interface RenderAppsProps {
  microAppData: Exclude<MicroAppDataFromUrlFull, InvalidAppDataFromUrl<DataFromUrlFull> | LoadingAppDataFromUrlFull>
}

const RenderApps = ({ microAppData }: RenderAppsProps) => {
  const { hasPermission } = useHasPermission()

  const { legacyApp, latestApp, leanApp } = useMemo(() => mapMicroAppDataToApps(microAppData), [microAppData])
  const { project, workspaceLevels } = microAppData

  if (leanApp) {
    if (leanApp?.type === LeanAppType.CAAS) {
      // Special case when an old CaaS app was launched within a project. Redirect to the proper url.
      if (project) {
        return (
          <Navigate
            replace
            to={getLeanAppUrl({
              leanApp,
              workspaceAzId: project.contextWorkspace,
            })}
          />
        )
      }

      console.error('CAAS are legacy apps and should be assigned as LEGACY type app')

      return <CriticalError />
    }

    return <LeanApp appShort={leanApp} />
  }

  const permissionField = legacyApp ? legacyApp.config.viewPermissionName : latestApp.permission

  if (!hasPermission(permissionField)) {
    return <ForbiddenPageError />
  }

  return (
    <>
      {legacyApp ? (
        <LegacyMicroAppContainer app={legacyApp} workspaceLevels={workspaceLevels} />
      ) : (
        <MicroApp app={latestApp} />
      )}
    </>
  )
}
