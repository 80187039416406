import { workfrontApi } from 'api'

export const fetchWorkfrontAuthenticate = ({
  code,
  state,
  redirectUri,
}: {
  code: string
  state: string
  redirectUri: string
}) =>
  workfrontApi.get<{ accessToken: string }>(`/auth/authenticate?code=${code}&state=${state}&redirectUri=${redirectUri}`)
