import { HierarchyLevelType } from '@wpp-open/core'
import { Activity } from 'single-spa'

import { AppData } from 'constants/apps'
import { PROJECT_URL_PREFIX, WORKSPACE_URL_PREFIX } from 'providers/appState/utils'
import { CaasAppShort } from 'types/apps/leanApps'
import { excludeFalsy } from 'utils/common'

export const getActiveWhen = ({ baseUrl, workspaceLevels }: AppData): Activity => {
  const isAnyLevel = !workspaceLevels.length
  const hasWorkspaceUrl = isAnyLevel || !!workspaceLevels.find(level => level !== HierarchyLevelType.Tenant)
  const hasRootUrl = isAnyLevel || workspaceLevels.includes(HierarchyLevelType.Tenant)

  return [
    `/${PROJECT_URL_PREFIX}/:projectId/:phaseId/${baseUrl}`,
    hasWorkspaceUrl && `/${WORKSPACE_URL_PREFIX}/:workspaceId:/${baseUrl}`,
    hasRootUrl && `/${baseUrl}`,
  ].filter(excludeFalsy)
}

export const getLegacyActiveWhen = ({ config }: CaasAppShort): Activity => {
  const { osRoute, requiredHierarchy } = config
  const formattedOsRoute = osRoute.startsWith('/') ? osRoute.slice(1) : osRoute
  const isAnyLevel = !requiredHierarchy.length
  const hasWorkspaceUrl = isAnyLevel || !!requiredHierarchy.find(level => level !== HierarchyLevelType.Tenant)
  const hasRootUrl = isAnyLevel || requiredHierarchy.includes(HierarchyLevelType.Tenant)
  // Urls with parameters like /brand/:brandId/app-route
  const isHardcodedUrl = formattedOsRoute.includes('/:')

  return [
    `/${PROJECT_URL_PREFIX}/:projectId/:phaseId/${formattedOsRoute}`,
    hasWorkspaceUrl && `/${WORKSPACE_URL_PREFIX}/:workspaceId:/${formattedOsRoute}`,
    (hasRootUrl || isHardcodedUrl) && `/${formattedOsRoute}`,
  ].filter(excludeFalsy)
}
