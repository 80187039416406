import { useSearchParams } from 'react-router-dom'

import { usePrelaunchedLeanAppApi } from 'api/apps/queries/usePrelaunchedLeanAppApi'
import { useMiroConfigStatusApi } from 'api/miro/queries/useMiroConfigStatusApi'
import { is403Error, is404Error, is5xxError } from 'api/utils'
import { CriticalError, NotFoundError } from 'components/renderError'
import { LeanAppLoading } from 'pages/leanApp/leanAppLoading/LeanAppLoading'
import { MiroAdminIssuesState } from 'pages/leanApp/miroBoardAppRenderer/MiroAdminIssuesState'
import styles from 'pages/leanApp/miroBoardAppRenderer/MiroBoardAppRenderer.module.scss'
import { MiroForbiddenState } from 'pages/leanApp/miroBoardAppRenderer/MiroForbiddenState'
import { MiroIssuesState } from 'pages/leanApp/miroBoardAppRenderer/MiroIssuesState'
import { MiroRestrictedState } from 'pages/leanApp/miroBoardAppRenderer/MiroRestrictedState'
import { isMiroAdminAuthError, isMiroAuthError, isRestrictedError } from 'pages/leanApp/miroBoardAppRenderer/utils'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { MiroBoardInstanceApp } from 'types/apps/leanApps'

export const MiroBoardAppRenderer = () => {
  const [searchParams] = useSearchParams()
  const { currentTenant } = useTenantAndUserData()

  const instanceId = searchParams.get('instanceId')

  const {
    data: leanAppFull,
    error,
    isLoading: isLeanAppFullLoading,
  } = usePrelaunchedLeanAppApi({
    params: {
      tenantId: currentTenant.id,
      leanAppId: instanceId!,
    },
    enabled: !!instanceId,
  })

  const { data: miroConfigStatus, isLoading: isMiroConfigStatusLoading } = useMiroConfigStatusApi({
    enabled: !!instanceId,
  })

  const isLoading = isLeanAppFullLoading || isMiroConfigStatusLoading
  const isForbiddenError = is403Error(error)

  if (isLoading) {
    return <LeanAppLoading />
  }

  if (!instanceId || is404Error(error)) {
    return <NotFoundError />
  }

  if (is5xxError(error)) {
    return <CriticalError />
  }

  if (isMiroAdminAuthError(error)) {
    return <MiroAdminIssuesState error={error!} />
  }

  if (isMiroAuthError(error)) {
    return <MiroIssuesState miroConfigStatus={miroConfigStatus!} instanceId={instanceId} />
  }

  if (isRestrictedError(error)) {
    return <MiroRestrictedState instanceId={instanceId} />
  }

  if (isForbiddenError) {
    return <MiroForbiddenState />
  }

  // Fallback for any other unexpected errors
  if (!leanAppFull) {
    return null
  }

  const { id, config } = leanAppFull as MiroBoardInstanceApp

  return (
    <iframe
      key={id}
      sandbox="allow-downloads allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
      className={styles.root}
      src={`https://miro.com/app/live-embed/${config.boardId}/?autoplay=true`}
      allow="fullscreen; clipboard-read; clipboard-write"
      allowFullScreen
    />
  )
}
