import { AppLibraryType, LeanAppType, MayBeNull } from '@wpp-open/core'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useStableCallback } from 'hooks/useStableCallback'
import styles from 'legacy/LegacyMicroApp.module.scss'
import { LegacyAppWindowLibraryName } from 'legacy/types/apps'
import {
  Breadcrumbs,
  LegacyWorkspace,
  MicrofrontendSetHeaderBreadcrumbs,
  WorkspaceType,
} from 'legacy/types/osWebRootApi'
import { getLegacyAppCode } from 'legacy/utils/apps'
import { LegacyState, legacyState } from 'legacy/utils/state'
import { getLegacyActiveWhen } from 'pages/microApp/utils'
import { useAuth } from 'providers/auth/AuthProvider'
import { useData } from 'providers/data/DataProvider'
import { usePublicData } from 'providers/publicData/PublicDataProvider'
import { CaasAppShort } from 'types/apps/leanApps'
import { getLeanAppUrl } from 'utils/navigation'
import { registerApp, unregisterApp } from 'utils/singleSpa'

// Some apps require a workspace to be set by header
/**
 * @deprecated This was used before required hierarchy config was available
 */
const WORKSPACE_DEPENDANT_APPS: LegacyAppWindowLibraryName[] = [
  LegacyAppWindowLibraryName.Audiences,
  LegacyAppWindowLibraryName.Touchpoints,
  LegacyAppWindowLibraryName.CampaignsCreate,
  LegacyAppWindowLibraryName.MediaPlansComparison,
  LegacyAppWindowLibraryName.Cart,
  LegacyAppWindowLibraryName.Scorecards,
]

// Some apps require a wrapper with custom background styles
const APPS_WITH_CUSTOM_BACKGROUND: LegacyAppWindowLibraryName[] = [
  LegacyAppWindowLibraryName.AudienceDB,
  LegacyAppWindowLibraryName.Contacts,
  LegacyAppWindowLibraryName.IDNOnboarding,
  LegacyAppWindowLibraryName.MediaPlanner,
  LegacyAppWindowLibraryName.SpeedDate,
]

const APP_BREADCRUMBS: Partial<Record<LegacyAppWindowLibraryName, Breadcrumbs>> = {
  [LegacyAppWindowLibraryName.Audiences]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
    app: 'OS_HEADER.NAVIGATION_MAIN.AUDIENCES',
  },
  [LegacyAppWindowLibraryName.Touchpoints]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
    app: 'OS_HEADER.NAVIGATION_MAIN.TOUCHPOINTS',
  },
  [LegacyAppWindowLibraryName.CampaignsCreate]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
    app: 'OS_HEADER.NAVIGATION_MAIN.CAMPAIGNS',
  },
  [LegacyAppWindowLibraryName.MediaPlansComparison]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.OPTIMISE',
    app: 'OS_HEADER.NAVIGATION_MAIN.MP_COMPARISON',
  },
  [LegacyAppWindowLibraryName.Cart]: {
    text: 'OS_HEADER.NAVIGATION_MAIN.CART',
  },
  [LegacyAppWindowLibraryName.Scorecards]: {
    category: 'OS_HEADER.NAVIGATION_MAIN.UNLOCK',
    app: 'OS_HEADER.NAVIGATION_MAIN.SCORECARDS',
  },
}

interface Props {
  app: CaasAppShort
  selectedWorkspace: MayBeNull<LegacyWorkspace>
}

export const LegacyMicroApp = ({ app, selectedWorkspace }: Props) => {
  const [isUnregistering, setIsUnregistering] = useState(false)
  const isUnregisteringRef = useRef(isUnregistering)
  const updateIsUnregistering = useCallback((isUnregistering: boolean) => {
    setIsUnregistering(isUnregistering)
    isUnregisteringRef.current = isUnregistering
  }, [])
  const { logout } = useAuth()
  const { navigate } = usePublicData()

  const windowLibraryName = app.config.windowLibraryName as LegacyAppWindowLibraryName
  const isBrandWorkspaceSet = selectedWorkspace?.type === WorkspaceType.Brand
  const isWorkspaceManagement = windowLibraryName === LegacyAppWindowLibraryName.WorkspaceManagement
  const isWorkspaceDependant = WORKSPACE_DEPENDANT_APPS.includes(windowLibraryName)
  const shouldRegister = !isWorkspaceDependant || isBrandWorkspaceSet
  const workspaceKey = isWorkspaceDependant || isWorkspaceManagement ? selectedWorkspace?.azId : null
  const appCode = getLegacyAppCode(app)

  // Navigation to legacy apps
  const { leanApps } = useData()
  const routerNavigate = useNavigate()

  const openApp = useStableCallback((windowLibraryName: string, subPath: string = '') => {
    const navigatedApp = leanApps
      .filter((leanApp): leanApp is CaasAppShort => leanApp.type === LeanAppType.CAAS)
      .find(leanApp => leanApp.config.windowLibraryName === windowLibraryName)
    routerNavigate(getLeanAppUrl({ leanApp: navigatedApp!, workspaceAzId: workspaceKey }) + subPath)
  })

  useEffect(() => {
    // Register only after previous unregister finishes.
    // This covers cases when the same legacy app should be re-registered
    if (!isUnregisteringRef.current && shouldRegister) {
      const { eventEmitter$, subscription } = LegacyState.getEventEmitterData(navigate, logout, app)
      const customProps = legacyState.getCustomProps(app, eventEmitter$, openApp)

      registerApp({
        libraryType: AppLibraryType.Window,
        code: appCode,
        appUrl: app.config.bundleUrl,
        activeWhen: getLegacyActiveWhen(app),
        windowLibraryName,
        customProps,
      })

      if (APP_BREADCRUMBS[windowLibraryName]) {
        legacyState.emitEvent(new MicrofrontendSetHeaderBreadcrumbs(APP_BREADCRUMBS[windowLibraryName]!))
      }

      return () => {
        subscription.unsubscribe()
        updateIsUnregistering(true)
        unregisterApp({ code: appCode }).finally(() => {
          // This is a quick workaround to handle the async execution of `unregisterApp()`.
          // The next register should wait for it to finish, but it finishes within one render cycle
          // and `isUnregistering` stays the same on next update without `setTimeout()`
          setTimeout(() => updateIsUnregistering(false), 100)
        })
      }
    }
  }, [
    app,
    logout,
    navigate,
    updateIsUnregistering,
    isUnregistering,
    shouldRegister,
    workspaceKey,
    appCode,
    windowLibraryName,
    openApp,
  ])

  let customBackgroundStyles: Record<string, string> = {}

  if (APPS_WITH_CUSTOM_BACKGROUND.includes(windowLibraryName)) {
    customBackgroundStyles = {
      backgroundColor: '#f3f4f7',
    }
  }

  return <div style={customBackgroundStyles} className={styles.container} id={app.config.containerId} />
}
