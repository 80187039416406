import { WppTypography } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { LineConnection } from 'components/connection/lineConnection/LineConnection'
import { ProjectBreadcrumbsAppItem } from 'layout/header/breadcrumbs/contextMenu/projectAppItem/ProjectBreadcrumbsAppItem'
import { ConnectionItemWrapper } from 'layout/header/breadcrumbs/projectFluid/connectionItemWrapper/ConnectionItemWrapper'
import styles from 'layout/header/breadcrumbs/projectFluid/menu/ProjectFluidBreadcrumbsMenu.module.scss'
import { FluidMenuItemTag, useFluidConnectionTracking } from 'layout/header/breadcrumbs/projectFluid/menu/utils'
import { FLUID_MENU_COLUMNS_GAP } from 'layout/header/breadcrumbs/projectFluid/ProjectFluidBreadcrumbs'
import { ProjectCanvasApplication } from 'types/projects/projectPhase'

interface MenuProps {
  projectId: string
  previousApps: ProjectCanvasApplication[]
  currentApp: ProjectCanvasApplication
  nextApps: ProjectCanvasApplication[]
}

export const ProjectFluidBreadcrumbsMenu = ({ projectId, previousApps, currentApp, nextApps }: MenuProps) => {
  const { t } = useTranslation()
  const [rootRef, setRootRef] = useState<MayBeNull<HTMLDivElement>>(null)
  const { prevConnections, nextConnections, connect, disconnect } = useFluidConnectionTracking()

  const hasLeftColumn = previousApps.length > 0
  const hasRightColumn = nextApps.length > 0

  useEffect(() => {
    if (rootRef) {
      connect(rootRef)

      return () => disconnect(rootRef)
    }
  }, [connect, disconnect, rootRef])

  return (
    <Flex
      ref={node => setRootRef(node)}
      className={clsx(FluidMenuItemTag.Root, styles.root)}
      gap={8}
      direction="column"
    >
      <Flex className={styles.headers} gap={8} direction="column">
        <Flex gap={FLUID_MENU_COLUMNS_GAP}>
          {hasLeftColumn && (
            <Flex className={styles.column} align="center" justify="center">
              <WppTypography className={styles.columnTitle} type="2xs-strong">
                {t('os.header.breadcrumbs.project_columns.previous')}
              </WppTypography>
            </Flex>
          )}

          <Flex className={styles.column} align="center" justify="center">
            <WppTypography className={styles.columnTitle} type="2xs-strong">
              {t('os.header.breadcrumbs.project_columns.current')}
            </WppTypography>
          </Flex>

          {hasRightColumn && (
            <Flex className={styles.column} align="center" justify="center">
              <WppTypography className={styles.columnTitle} type="2xs-strong">
                {t('os.header.breadcrumbs.project_columns.next')}
              </WppTypography>
            </Flex>
          )}
        </Flex>

        <div className={styles.headerSeparator} />
      </Flex>

      <Flex gap={FLUID_MENU_COLUMNS_GAP}>
        {hasLeftColumn && (
          <Flex
            className={styles.column}
            direction="column"
            gap={4}
            data-testid="breadcrumbs-menu-project-fluid-prev-column"
          >
            {previousApps.map(app => (
              <ConnectionItemWrapper key={app.id} tag={FluidMenuItemTag.Prev} connect={connect} disconnect={disconnect}>
                <ProjectBreadcrumbsAppItem key={app.id} app={app} projectId={projectId} />
              </ConnectionItemWrapper>
            ))}
          </Flex>
        )}

        <Flex
          className={styles.column}
          direction="column"
          gap={4}
          data-testid="breadcrumbs-menu-project-fluid-current-column"
        >
          <ConnectionItemWrapper tag={FluidMenuItemTag.Current} connect={connect} disconnect={disconnect}>
            <ProjectBreadcrumbsAppItem app={currentApp} projectId={projectId} />
          </ConnectionItemWrapper>
        </Flex>

        {hasRightColumn && (
          <Flex
            className={styles.column}
            direction="column"
            gap={4}
            data-testid="breadcrumbs-menu-project-fluid-next-column"
          >
            {nextApps.map(app => (
              <ConnectionItemWrapper key={app.id} tag={FluidMenuItemTag.Next} connect={connect} disconnect={disconnect}>
                <ProjectBreadcrumbsAppItem app={app} projectId={projectId} />
              </ConnectionItemWrapper>
            ))}
          </Flex>
        )}
      </Flex>

      <div className={styles.connectionsContainer}>
        {prevConnections.map((connection, index) => (
          <LineConnection key={index} withArrow={index === 0} coordinates={connection} />
        ))}
        {nextConnections.map((connection, index) => (
          <LineConnection key={index} withArrow coordinates={connection} />
        ))}
      </div>
    </Flex>
  )
}
