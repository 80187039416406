import { LeanAppsMappingNode } from '@wpp-open/core'
import { useMemo } from 'react'

import { sortBy } from 'utils/common'
import { PointerNode, Tree, getCurrentAppGroupFromTree } from 'utils/mapping/common'

export const useGroupAppsPointerNodes = <T extends Tree>({
  pointerNodes,
  tree,
}: {
  pointerNodes: PointerNode<T>[]
  tree: T
}) =>
  useMemo(() => {
    const ungrouped: PointerNode<T>[] = []

    const groupedMap: Record<
      string,
      {
        group: LeanAppsMappingNode['groups'][number][string]
        children: PointerNode<T>[]
      }
    > = {}

    for (const pointerNode of pointerNodes) {
      const navigationGroup = getCurrentAppGroupFromTree({ tree, appPointerNode: pointerNode })

      if (navigationGroup) {
        const groupId = navigationGroup.id

        if (!groupedMap[groupId]) {
          groupedMap[groupId] = { group: navigationGroup, children: [pointerNode] }
        } else {
          groupedMap[groupId].children.push(pointerNode)
        }
      } else {
        ungrouped.push(pointerNode)
      }
    }

    return {
      ungrouped,
      grouped: sortBy(Object.values(groupedMap), ({ group }) => group.order),
    }
  }, [pointerNodes, tree])
