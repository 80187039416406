import { WppAccordion, WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import questionsData from 'pages/landing/assets/landing/jsons/questions.json'
import styles from 'pages/landing/homePage/frequentlyAskedQuestions/FrequentlyAskedQuestions.module.scss'

interface Question {
  title: string
  description: string
}

export const FrequentlyAskedQuestions = () => {
  const questions: Question[] = questionsData?.data || []

  return (
    <Flex direction="column" gap={30} className={styles.wrapper}>
      <Flex direction="column" gap={24}>
        <WppTypography type="2xl-heading">Frequently asked questions</WppTypography>
      </Flex>
      <Flex direction="row" wrap="wrap">
        {questions.map(item => (
          <WppAccordion key={item.title} size="m" expanded={false} withDivider>
            <WppTypography type="m-midi" slot="header">
              {item.title}
            </WppTypography>
            <WppTypography type="s-body">{item.description}</WppTypography>
          </WppAccordion>
        ))}
      </Flex>
    </Flex>
  )
}
