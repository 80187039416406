import { UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts'
import { createContext, PropsWithChildren, useCallback, useContext, useMemo } from 'react'
import { AuthProvider as OidcProvider, AuthProviderProps } from 'react-oidc-context'
import { Route, Routes, useMatch } from 'react-router-dom'
import { useAsync } from 'react-use'

import { environment } from 'environment'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { LoginRoutes } from 'providers/auth/components/LoginRoutes'
import { authRoutes } from 'providers/auth/components/utils'
import { getShouldMonitorSession } from 'providers/auth/utils'
import { usePublicData } from 'providers/publicData/PublicDataProvider'
import { SignInUserState } from 'types/auth/state'
import { OidcUser } from 'types/auth/user'
import { routesManager } from 'utils/routesManager'

export interface AuthContextValue {
  jwt: string
  user: OidcUser
  logout: () => void
}

export const AuthContext = createContext<AuthContextValue>(null!)

export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const { navigate } = usePublicData()
  const { loading: isCheckingShouldMonitorSession, value: shouldMonitorSession } = useAsync(getShouldMonitorSession, [])
  const isMiroAuthRedirectCallback = !!useMatch(routesManager.internalPages.public.miroAuthCallback())

  const oidcConfiguration: UserManagerSettings = useMemo(
    () => ({
      accessTokenExpiringNotificationTimeInSeconds: 120,
      authority: environment.KEYCLOAK_AUTHORITY,
      automaticSilentRenew: true, // Using refresh token
      client_id: environment.KEYCLOAK_CLIENT_ID,
      loadUserInfo: false, // The default user email is enough for now
      // TODO: monitorSession is causing login issues in FF & Safari
      //  due to strict cookie policies
      monitorSession: shouldMonitorSession, // Detect logouts from a different tab, session expiration, etc.
      post_logout_redirect_uri: `${window.location.origin}/${authRoutes.login}`,
      redirectMethod: 'assign',
      redirect_uri: `${window.location.origin}/${authRoutes.authCallback}`,
      scope: 'openid',
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    }),
    [shouldMonitorSession],
  )

  const handleSignInCallback: NonNullable<AuthProviderProps['onSigninCallback']> = useCallback(
    user => {
      if (user) {
        const userState = user.state as SignInUserState | undefined
        const targetUrl = userState?.targetUrl || routesManager.internalPages.home.root()

        navigate(targetUrl, {
          replace: true,
        })
      }
    },
    [navigate],
  )

  if (isCheckingShouldMonitorSession) {
    return null
  }

  return (
    <OidcProvider
      {...oidcConfiguration}
      onSigninCallback={handleSignInCallback}
      // Miro OAuth2 flow is a subset of OIDC and uses same query parameters
      // so in this case we skip oidc-client tries to authenticate with miro "state" and "code" parameter
      skipSigninCallback={isMiroAuthRedirectCallback}
    >
      <Routes>
        <Route path={authRoutes.authCallback} element={<LoadingPage />} />
        <Route path="*" element={<LoginRoutes>{children}</LoginRoutes>} />
      </Routes>
    </OidcProvider>
  )
}
