import { MayBeNull } from '@wpp-open/core'
import { SigninRedirectArgs } from 'oidc-client-ts'
import { PropsWithChildren, useEffect, useState } from 'react'
import { useAuth } from 'react-oidc-context'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { LoginPage } from 'pages/login/LoginPage'
import { OidcSecure } from 'providers/auth/components/OidcSecure'
import { authRoutes, getTargetUrl } from 'providers/auth/components/utils'
import { IdentityProvider } from 'types/auth/identityProvider'
import { SignInUserState } from 'types/auth/state'
import { routesManager } from 'utils/routesManager'

export const LoginRoutes = ({ children }: PropsWithChildren<{}>) => {
  const { isAuthenticated, isLoading, events, error, signinSilent, signinRedirect, removeUser } = useAuth()
  const [didTrySilentSignIn, setDidTrySilentSignIn] = useState(false)
  const location = useLocation()
  const historyState = location.state as SignInUserState | undefined

  // Try silent sign in on page load if we don't have user data yet
  useEffect(() => {
    if (!didTrySilentSignIn && !isLoading) {
      if (isAuthenticated) {
        setDidTrySilentSignIn(true)
      } else {
        // The library may use an expired refresh token to sign in.
        // So if the user is not authenticated by this point,
        // just remove user info before trying silent sign in.
        // See: https://github.com/authts/oidc-client-ts/issues/644
        removeUser().finally(() => {
          signinSilent()
            .catch(() => {
              // Ignore the error. User is not signed in, we'll just redirect them to the login page.
            })
            .finally(() => {
              setDidTrySilentSignIn(true)
            })
        })
      }
    }
  }, [isAuthenticated, isLoading, setDidTrySilentSignIn, didTrySilentSignIn, signinSilent, removeUser])

  // Watch for sign out to update the status
  useEffect(() => {
    const signedOutCallback = () => {
      removeUser()
    }

    events.addUserSignedOut(signedOutCallback)

    return () => {
      events.removeUserSignedOut(signedOutCallback)
    }
  }, [events, removeUser])

  // Handle 'react-oidc-context' errors
  useEffect(() => {
    if (error) {
      console.error('Authentication error:', error)
      removeUser()
    }
  }, [error, removeUser])

  const handleLogin = (identityProvider?: IdentityProvider) => {
    const providerData: MayBeNull<SigninRedirectArgs> = identityProvider
      ? {
          extraQueryParams: {
            kc_idp_hint: identityProvider,
          },
        }
      : null

    signinRedirect({
      ...providerData,
      state: {
        targetUrl: historyState?.targetUrl,
      } as SignInUserState,
    })
  }

  if (!didTrySilentSignIn || isLoading) {
    return <LoadingPage />
  }

  return (
    <Routes>
      <Route
        path={authRoutes.login}
        element={
          isAuthenticated ? (
            <Navigate to={historyState?.targetUrl || routesManager.internalPages.home.root()} />
          ) : (
            <LoginPage login={handleLogin} />
          )
        }
      />

      <Route
        path="*"
        element={
          isAuthenticated && !error ? (
            <OidcSecure>{children}</OidcSecure>
          ) : (
            <Navigate
              to={authRoutes.login}
              state={
                {
                  targetUrl: getTargetUrl(location),
                } as SignInUserState
              }
            />
          )
        }
      />
    </Routes>
  )
}
