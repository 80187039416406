import qs from 'qs'

import { facadeApi } from 'api'
import { PaginatedResponse, PaginationParams } from 'api/common/types'
import { LeanAppShort } from 'types/apps/leanApps'

type Params = PaginationParams<{
  tenantId: string
  search?: string
  type?: string[]
}>

export type LeanAppsListResponse = PaginatedResponse<LeanAppShort>

export const fetchLeanAppsList = ({ tenantId, search, page, itemsPerPage, type }: Params) =>
  facadeApi.get<LeanAppsListResponse>(`/tenants/${tenantId}/lean-apps`, {
    params: {
      page,
      itemsPerPage,
      filter: {
        ...(!!search && { search }),
        ...(!!type?.length && { type }),
      },
    },
    paramsSerializer: {
      serialize: params => qs.stringify(params, { arrayFormat: 'repeat', encode: true }),
    },
  })
