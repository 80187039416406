import { Fragment, PropsWithChildren } from 'react'
import { Route, Routes } from 'react-router-dom'

import { DevResetQueries } from 'providers/tenantAndUserData/devResetQueries/DevResetQueries'
import { GENERIC_TENANT_DEV_ROUTE } from 'providers/tenantAndUserData/utils'

// Enable generic tenant workflow simulation.
// The generic tenant route will only be rendered when the loaded data is related to
// specific tenant instead of generic. This happens during auth callback or when the user
// clicks a browser's back button.
export const SpecificTenantRoutes = process.env.DEV
  ? ({ children }: PropsWithChildren<{}>) => (
      <Routes>
        <Route path={`${GENERIC_TENANT_DEV_ROUTE}/*`} element={<DevResetQueries />} />
        <Route index path="*" element={children} />
      </Routes>
    )
  : Fragment
