import { LeanAppType } from '@wpp-open/core'

import { useLeanAppApi } from 'api/apps/queries/useLeanAppApi'
import { is404Error, is5xxError } from 'api/utils'
import { PageBuilderView } from 'components/contentBuilderView/PageBuilderView'
import { CriticalError, NotFoundError } from 'components/renderError'
import styles from 'pages/leanApp/defaultLeanAppRenderer/DefaultLeanAppRenderer.module.scss'
import { LeanAppLoading } from 'pages/leanApp/leanAppLoading/LeanAppLoading'
import { PenpalParentFrame } from 'pages/leanApp/penpalParentFrame/PenpalParentFrame'
import { TableauLeanApp } from 'pages/leanApp/tableau/TableauLeanApp'
import { isTableauApp } from 'pages/leanApp/tableau/utils'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { LinkEmbeddedAppShort, MiroBoardAppShort } from 'types/apps/leanApps'
import { RenderableLeanAppShort } from 'types/navigation/navigation'
import { isEmptyBuilderContent } from 'utils/builder'

interface Props {
  appShort: Exclude<RenderableLeanAppShort, MiroBoardAppShort>
}

export const DefaultLeanAppRenderer = ({ appShort }: Props) => {
  const { currentTenant } = useTenantAndUserData()

  const { data, error, isLoading } = useLeanAppApi({
    params: {
      tenantId: currentTenant.id,
      leanAppId: appShort.id,
    },
  })

  if (isLoading) {
    return <LeanAppLoading />
  }

  if (is404Error(error)) {
    return <NotFoundError />
  }

  if (is5xxError(error)) {
    return <CriticalError />
  }

  // Fallback for any other unexpected errors
  if (!data) {
    return null
  }

  const { id, type, config } = data

  return (
    <>
      {isTableauApp(appShort) && <TableauLeanApp app={data as LinkEmbeddedAppShort} />}
      {type === LeanAppType.LINK_EMBEDDED && (
        <PenpalParentFrame
          key={id}
          appId={id}
          allow="clipboard-write"
          sandbox="allow-downloads allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          className={styles.iframe}
          src={config.url}
        />
      )}

      {type === LeanAppType.CODE_EMBEDDED && (
        <PenpalParentFrame
          key={id}
          appId={id}
          allow="clipboard-write"
          sandbox="allow-downloads allow-forms allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          className={styles.iframe}
          srcDoc={config.embeddedCode}
        />
      )}

      {type === LeanAppType.CUSTOM_PAGE && !isEmptyBuilderContent(config.content) && (
        <PageBuilderView content={config.content} />
      )}
    </>
  )
}
