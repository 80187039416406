export enum LeanAppType {
  CODE_EMBEDDED = 'code_embedded',
  LINK_EMBEDDED = 'link_embedded',
  LINK_DETACHED = 'link_detached',
  CUSTOM_PAGE = 'custom_page',
  CUSTOM_WIDGET = 'custom_widget',
  MIRO_BOARD = 'miro_board',
  MIRO_BOARD_INSTANCE = 'miro_board_instance',
  CAAS = 'caas',
  NATIVE = 'native',
}
