import { WppIconExternalLink, WppIconWarning, WppTooltip } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType, LeanAppType, NavigationTree } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useData } from 'providers/data/DataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { trackAnalytics } from 'utils/analytics'
import { isMiroWarningVisible } from 'utils/apps'
import { PointerNode, getWorkspacePathPointerNodes } from 'utils/mapping/common'
import { getLeanAppUrl } from 'utils/navigation'

interface Props {
  pointerNode: PointerNode<NavigationTree>
}

export const LeanAppNavigationMenuItem = ({ pointerNode }: Props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { currentTenant, navigationHierarchy, userDetails } = useTenantAndUserData()
  const { navigationTree } = useData()
  const { closeNavigation } = useAppState()
  const { activeNodePath, miroConfigStatus } = useHeaderNavigation()

  const { nodeId, nodePath, parent } = pointerNode
  const { leanAppsMapping } = navigationTree
  const leanAppMappingNode = leanAppsMapping[nodeId]

  const { type, name, config } = leanAppMappingNode

  const [client, market, brand] = getWorkspacePathPointerNodes({ pointerNode, navigationHierarchy })

  const data = {
    tenantId: currentTenant.id,
    tenantName: currentTenant.name,
    source: 'CORE',
    launchedWithContext: [market, brand, client].some(Boolean),
    market: market?.node.name || '',
    brand: brand?.node.name || '',
    client: client?.node.name || '',
    productName: leanAppMappingNode.name,
    productType: leanAppMappingNode.type,
    appId: leanAppMappingNode.id,
    // browserLocation: '', // disabled for now
    userId: userDetails.id,
    userEmail: userDetails.email,
    userAgency: userDetails.agency,
    coreNavigation: true,
    coreBreadcrumbs: false,
  }

  const params = Object.entries(data).map(([key, value]) => ({
    key,
    value,
  }))

  if (type === LeanAppType.LINK_DETACHED) {
    return (
      <TreeListItem
        linkConfig={{
          target: '_blank',
          rel: 'noreferrer',
          href: config.url,
        }}
        onClick={() => {
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: {
              action: 'navigation_app_run',
              params: params,
            },
          })
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: {
              action: 'navigation_app_run',
              params: params,
            },
          })
        }}
      >
        <span slot="label">{name}</span>
        <WppIconExternalLink slot="right" />
      </TreeListItem>
    )
  }

  const href = getLeanAppUrl({
    leanApp: leanAppMappingNode,
    workspaceAzId: navigationHierarchy.some(({ type }) => type === parent!.node.type) ? parent!.nodeId : null,
  })

  return (
    <TreeListItem
      checked={activeNodePath === nodePath}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onClick={e => {
        if (!e.currentTarget.checked) {
          navigate(href)
          closeNavigation()
          trackAnalytics({
            type: AnalyticsActionType.action,
            payload: {
              action: 'navigation_app_run',
              params: params,
            },
          })
        }
      }}
    >
      <span slot="label">{name}</span>

      {isMiroWarningVisible({ leanAppMappingNode, miroConfigStatus }) && (
        <WppTooltip slot="right" text={t('os.navigation_menu.miro_app_warning_text')}>
          <WppIconWarning />
        </WppTooltip>
      )}
    </TreeListItem>
  )
}
