import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import aaronQuirk from 'pages/landing/assets/stories/aaronQuirk.png'
import constellationBrands1 from 'pages/landing/assets/stories/carouselImages/constellation-brands-1.png'
import constellationBrands2 from 'pages/landing/assets/stories/carouselImages/constellation-brands-2.png'
import lvmh1 from 'pages/landing/assets/stories/carouselImages/lvmh-1.png'
import lvmh2 from 'pages/landing/assets/stories/carouselImages/lvmh-2.png'
import toyota1 from 'pages/landing/assets/stories/carouselImages/toyota-1.png'
import toyota2 from 'pages/landing/assets/stories/carouselImages/toyota-2.png'
import constellationBrands from 'pages/landing/assets/stories/constellationBrands.png'
import constellationBrandsActive from 'pages/landing/assets/stories/constellationBrandsActive.png'
import constellationBrandsDescription from 'pages/landing/assets/stories/constellationBrandsDescription.png'
import delphineHernoux from 'pages/landing/assets/stories/delphineHernoux.png'
import lvmh from 'pages/landing/assets/stories/lvmh.png'
import lvmhActive from 'pages/landing/assets/stories/lvmhActive.png'
import lvmhDescription from 'pages/landing/assets/stories/lvmhDescription.png'
import manishLulla from 'pages/landing/assets/stories/manishLulla.png'
import { ReactComponent as QuoteMark } from 'pages/landing/assets/stories/quoteMark.svg'
import toyota from 'pages/landing/assets/stories/toyota.png'
import toyotaActive from 'pages/landing/assets/stories/toyotaActive.png'
import toyotaDescription from 'pages/landing/assets/stories/toyotaDescription.png'
import { GetStartedSteps } from 'pages/landing/components/getStartedSteps/GetStartedSteps'
import { Hero } from 'pages/landing/components/hero/Hero'
import { Carousel } from 'pages/landing/storiesPage/components/carousel/Carousel'
import styles from 'pages/landing/storiesPage/Stories.module.scss'

export const Stories = () => {
  const TABS = {
    LVMH: {
      id: 'lvmh',
      logo: lvmh,
      logoActive: lvmhActive,
      logoAlt: 'LVMH',
      description: {
        clientInput:
          'The purpose of the Tech&Tool presentation session during the media pitch for LVMH North America was to demonstrate how the outlined process can be transformed into an actual delivery flow. The focus was on showcasing the tools that are presently accessible and can be utilised, rather than presenting a purely theoretical approach.',
        challenge:
          'The dedicated domain was requested, which required full system setup and configuration. The solution was built fully from scratch.',
        approach:
          'The OS instance was spined up on dedicated domain. Full customisation applied, the process was created and ass requested tools integrated.',
        timeline: '3 weeks',
        image: lvmhDescription,
      },
      quote: {
        author: 'Delphine Hernoux',
        jobTitle: 'Chief Data & Analytics Officer, GroupM North America',
        text: 'The WPP Open is a very powerful platform giving to all client teams a single destination to access curated solutions and tools. It easily unlocks the access to all GroupM proprietary solutions and more, all creating a unique application layer of solutions to push further clients’ data-driven strategies. With the access that we can give to clients, it also represents a great opportunity to provide access to Training, strategic thought leadership and more and, operates as an extended partner with clients. Recently showcased during a large new business opportunity in North America, the team has been able to bring to life our data-driven applications in a flawless manner, leading to get very positive feedback from the client about our approach to data, tech, and tools.',
        image: delphineHernoux,
      },
      slides: [lvmh1, lvmh2],
    },
    CONSTELLATION_BRANDS: {
      id: 'constellationBrands',
      logo: constellationBrands,
      logoActive: constellationBrandsActive,
      logoAlt: 'Constellation Brands',
      description: {
        clientInput:
          'It was important to demonstrate to the client the clarity and transparency of the delivery process. Emphasise how the team is connected to the process and tools used. The OS aimed to be presented as an excellence hub for the team to work in a collaborative space.',
        challenge:
          'More advanced level of the customisation of the home page and navigation was requested to be delivered within the short time frame. Emphasising comprehensive cross-brand collaboration and overall performance tracking was a highest priority.',
        approach:
          'Two distinct tenants were established, each one carefully customised to accommodate the needs of either the Global or Specific brand.',
        timeline: '2 weeks',
        image: constellationBrandsDescription,
      },
      quote: {
        author: 'Manish Lulla',
        jobTitle: 'Managing Partner​ Data, Audience & Platform Strategy at EssenceMediacom',
        text: 'WPP Open was very well received both by the client as well as the EssenceMediacom Senior Leadership and New Business team. The OS is a game changer in our approach to demonstrating our tool kit to new clients in a pitch situation. The OS is a fresh new look and helps us bring our best foot forward for new business pitches. We really enjoyed the enterprising approach that the WPP Open team brought to the table and turned the OS around in a short period of time. We look forward to working closely with the WPP Open OS team to continue producing winning tools for new business.',
        image: manishLulla,
      },
      slides: [constellationBrands1, constellationBrands2],
    },
    TOYOTA: {
      id: 'toyota',
      logo: toyota,
      logoActive: toyotaActive,
      logoAlt: 'Toyota',
      description: {
        clientInput:
          'For a contract renewal meeting the team needed a solid working solution that would provide to the client the desired clarity of the delivery flow. The next topics were a priority: performance tracking, single entry-point to the tools, data and documents, clarity in delivery timelines and following the unified process.',
        challenge:
          'Create and implement presentation scenarios, upload content, and assemble all pieces in just 4 days.',
        approach:
          'The created OS tenant merges tool presentation, practical work, and report/dashboard samples into a cohesive user experience.',
        timeline: '2 weeks',
        image: toyotaDescription,
      },
      quote: {
        author: 'Aaron Quirk',
        jobTitle: 'EMEA CEO mSix&Partners',
        text: 'mSix+Partners have been delighted to work with Inna and the team to create Operating Systems for our most recent pitches. One has even resulted in a WIN! We feel that the OS is a great way to show collaboration, ways of working and how we look to partner with one another, our clients and agencies.',
        image: aaronQuirk,
      },
      slides: [toyota1, toyota2],
    },
  }

  const [selectedTab, setTab] = useState(TABS.LVMH.id)

  const tabDescription = (description: {
    clientInput: string
    challenge: string
    approach: string
    timeline: string
    image: string
  }) => (
    <Flex direction="row" gap={80}>
      <Flex direction="column" gap={24}>
        <Flex direction="column">
          <WppTypography type="m-strong">Client input</WppTypography>
          <WppTypography type="m-body">{description.clientInput}</WppTypography>
        </Flex>
        <Flex direction="column">
          <WppTypography type="m-strong">Challenge</WppTypography>
          <WppTypography type="m-body">{description.challenge}</WppTypography>
        </Flex>
        <Flex direction="column">
          <WppTypography type="m-strong">Approach</WppTypography>
          <WppTypography type="m-body">{description.approach}</WppTypography>
        </Flex>
        <Flex direction="column">
          <WppTypography type="m-strong">Timeline</WppTypography>
          <WppTypography type="m-body">{description.timeline}</WppTypography>
        </Flex>
      </Flex>
      <Flex align="center">
        <img src={description.image} />
      </Flex>
    </Flex>
  )

  const tabQuote = (quote: { image: string; author: string; jobTitle: string; text: string }) => (
    <div className={styles.quote}>
      <QuoteMark className={styles.quoteMark} />
      <div className={styles.quoteImage}>
        <img src={quote.image} />
      </div>
      <Flex direction="column">
        <WppTypography type="3xl-heading" className={styles.quoteAuthor}>
          {quote.author}
        </WppTypography>
        <WppTypography type="m-midi" className={styles.quoteJobTitle}>
          {quote.jobTitle}
        </WppTypography>
        <WppTypography type="m-body">{quote.text}</WppTypography>
      </Flex>
    </div>
  )

  const getTabContent = (tabId: string) => {
    switch (tabId) {
      case TABS.LVMH.id:
        return (
          <>
            {tabDescription(TABS.LVMH.description)}
            {tabQuote(TABS.LVMH.quote)}
          </>
        )
      case TABS.CONSTELLATION_BRANDS.id:
        return (
          <>
            {tabDescription(TABS.CONSTELLATION_BRANDS.description)}
            {tabQuote(TABS.CONSTELLATION_BRANDS.quote)}
          </>
        )
      case TABS.TOYOTA.id:
        return (
          <>
            {tabDescription(TABS.TOYOTA.description)}
            {tabQuote(TABS.TOYOTA.quote)}
          </>
        )
    }
  }

  const getCarousel = (tabId: string) => {
    switch (tabId) {
      case TABS.LVMH.id:
        return <Carousel slides={TABS.LVMH.slides} />
      case TABS.CONSTELLATION_BRANDS.id:
        return <Carousel slides={TABS.CONSTELLATION_BRANDS.slides} />
      case TABS.TOYOTA.id:
        return <Carousel slides={TABS.TOYOTA.slides} />
    }
  }

  return (
    <Flex direction="column" align="center" className={styles.container}>
      <Hero
        title="Success stories"
        subtitle="From groundbreaking pitches to cutting-edge solutions, the following success stories provide tangible examples of the positive impact that Open can have on your organisation."
      />
      <Flex direction="column" align="center" className={styles.storiesContent} gap={80} data-testid="tab-content">
        <Flex gap={16} data-testid="tab-selector">
          {Object.values(TABS).map(tab => {
            const active = tab.id === selectedTab
            return (
              <div
                key={tab.id}
                className={clsx(styles.tabSelector, { [styles.tabSelectorActive]: active })}
                onClick={() => setTab(tab.id)}
              >
                <img className={styles.tabSelectorImg} src={active ? tab.logoActive : tab.logo} alt={tab.logoAlt} />
              </div>
            )
          })}
        </Flex>
        <Flex direction="column" gap={80} align="center">
          {getTabContent(selectedTab)}
        </Flex>
        {getCarousel(selectedTab)}
      </Flex>
      <div className={styles.getStartedStepsWrapper}>
        <GetStartedSteps />
      </div>
    </Flex>
  )
}
