import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import fastDeepEqual from 'fast-deep-equal/es6'
import { Component, ComponentPropsWithoutRef, lazy, Suspense } from 'react'
import { Location, useLocation } from 'react-router-dom'

import styles from 'components/renderError/boundary/RenderErrorBoundary.module.scss'
import { RenderError, RenderErrorType } from 'components/renderError/utils'

const NotFoundErrorState = lazy(() => import('components/renderError/errorState/notFound/NotFoundErrorState'))

const ForbiddenPageErrorState = lazy(
  () => import('components/renderError/errorState/forbiddenPage/ForbiddenPageErrorState'),
)

const ForbiddenOSAccessErrorState = lazy(
  () => import('components/renderError/errorState/forbiddenOSAccess/ForbiddenOSAccessErrorState'),
)

const OsIsNotAvailableErrorState = lazy(
  () => import('components/renderError/errorState/osIsNotAvailable/OsIsNotAvailableErrorState'),
)

const DataIsNotAvailableErrorState = lazy(
  () => import('components/renderError/errorState/dataIsNotAvailable/DataIsNotAvailableErrorState'),
)

const CriticalErrorState = lazy(() => import('components/renderError/errorState/criticalError/CriticalErrorState'))

type InnerProps = Props & { location: Location }

interface State {
  error: MayBeNull<RenderError>
}

class RenderErrorBoundaryInner extends Component<InnerProps, State> {
  static defaultProps = {
    root: false,
    catchErrors: [RenderErrorType.OsIsNotAvailable],
  }

  state: State = {
    error: null,
  }

  componentDidUpdate(prevProps: InnerProps) {
    const { error } = this.state
    const { location } = this.props

    const shouldResetError =
      !!error &&
      !fastDeepEqual([prevProps.location.pathname, prevProps.location.search], [location.pathname, location.search])

    if (shouldResetError) {
      this.resetError()
    }
  }

  componentDidCatch(error: unknown) {
    const { catchErrors, root } = this.props

    const mappedError = error instanceof RenderError ? error : new RenderError(RenderErrorType.OsIsNotAvailable)
    const isProcessable = catchErrors!.includes(mappedError.cause)

    if (isProcessable) {
      this.setState({ error: mappedError })
    } else {
      if (root) {
        /*
          Top level boundary.
          Technicaly we can get here only in a case of render boundaries hierarchy missconfiguration.
          In this case we always remap to `OsIsNotAvailable` error.
        */
        this.setState({
          error: new RenderError(RenderErrorType.OsIsNotAvailable),
        })
      } else {
        throw mappedError
      }
    }
  }

  resetError = () => {
    this.setState({ error: null })
  }

  render() {
    const { error } = this.state
    const { height = RenderErrorHeight.Adapt, root, children, catchErrors, location, className, ...rest } = this.props

    return error ? (
      <Suspense>
        <div
          {...rest}
          className={clsx(
            styles.root,
            {
              [styles.fullscreen]: height === RenderErrorHeight.Fullscreen,
              [styles.fullPage]: height === RenderErrorHeight.FullPage,
            },
            className,
          )}
        >
          {error.cause === RenderErrorType.OsIsNotAvailable && <OsIsNotAvailableErrorState />}
          {error.cause === RenderErrorType.DataIsNotAvailable && <DataIsNotAvailableErrorState />}
          {error.cause === RenderErrorType.ForbiddenPage && <ForbiddenPageErrorState resetError={this.resetError} />}
          {error.cause === RenderErrorType.ForbiddenOSAccess && (
            <ForbiddenOSAccessErrorState resetError={this.resetError} />
          )}
          {error.cause === RenderErrorType.NotFound && <NotFoundErrorState resetError={this.resetError} />}
          {error.cause === RenderErrorType.CriticalError && <CriticalErrorState resetError={this.resetError} />}
        </div>
      </Suspense>
    ) : (
      children
    )
  }
}

export enum RenderErrorHeight {
  Adapt = 'Adapt',
  Fullscreen = 'Fullscreen',
  FullPage = 'FullPage',
}

type Props = ComponentPropsWithoutRef<'div'> & {
  root?: boolean
  catchErrors?: RenderErrorType[]
  height?: RenderErrorHeight
}

export const RenderErrorBoundary = (props: Props) => {
  const location = useLocation()

  return <RenderErrorBoundaryInner {...props} location={location} />
}
