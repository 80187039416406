import { WppMenuGroup, WppTypography } from '@platform-ui-kit/components-library-react'
import { ChildHierarchyLevelType } from '@wpp-open/core'
import { ReactNode, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { FadeIn } from 'components/common/fadeIn/FadeIn'
import { Flex } from 'components/common/flex/Flex'
import { AppMenuItem } from 'components/navigationMenu/navigationMenuContent/appMenuItem/AppMenuItem'
import styles from 'components/navigationMenu/navigationMenuContent/appsNavigationMenuColumn/AppsNavigationMenuColumn.module.scss'
import { LeanAppNavigationMenuItem } from 'components/navigationMenu/navigationMenuContent/leanAppNavigationMenuItem/LeanAppNavigationMenuItem'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import { useNavigationMenuController } from 'components/navigationMenu/navigationMenuContent/NavigationMenuControllerContext'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { useHardcodedApps } from 'components/navigationMenu/navigationMenuContent/utils'
import { AppsTreeList } from 'components/treeList/appsTreeList/AppsTreeList'
import { AppData, LOCAL_APP_CODE, LOCAL_APP_NAME } from 'constants/apps'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useData } from 'providers/data/DataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import {
  getWorkspacePathPointerNodes,
  useHierarchyLevelLabel,
  getHierarchyContainerNodePath,
  getPointerNodeLeanAppsChildren,
} from 'utils/mapping/common'
import { getAppUrl } from 'utils/navigation'
import { routesManager } from 'utils/routesManager'

export const AppsNavigationMenuColumn = () => {
  const { t } = useTranslation()
  const { navigationTree } = useData()
  const { navigationHierarchy } = useTenantAndUserData()
  const { nodesPathsMapping } = useHeaderNavigation()
  const { localNodePath } = useNavigationMenuController()

  const workspacePointerNodes = getWorkspacePathPointerNodes({
    pointerNode: nodesPathsMapping[localNodePath],
    navigationHierarchy,
  })

  const hasLocalApp = !!process.env.DEV

  const selectedWorkspaceId = workspacePointerNodes.length
    ? workspacePointerNodes[workspacePointerNodes.length - 1].nodeId
    : undefined

  const hardcodedAppSections = useHardcodedApps(selectedWorkspaceId)

  const parentPointerNode =
    workspacePointerNodes.at(-1) || nodesPathsMapping[getHierarchyContainerNodePath(navigationTree)]

  const leanAppsPointerNodes = useMemo(() => getPointerNodeLeanAppsChildren(parentPointerNode), [parentPointerNode])

  const levelName = useHierarchyLevelLabel({
    levelType: workspacePointerNodes.length ? (parentPointerNode.node.type as ChildHierarchyLevelType) : null,
  })

  const hasApps = hasLocalApp || hardcodedAppSections.length > 0 || leanAppsPointerNodes.length > 0

  const hardcodedUngrouped: ReactNode[] = []
  const hardcodedGrouped: { name: string; children: ReactNode[] }[] = []

  const renderHardcodedAppItem = (app: AppData) => (
    <AppMenuItem
      key={app.code}
      appStableId={app.code}
      appName={app.name}
      href={getAppUrl(app.baseUrl, selectedWorkspaceId)}
      parentNodePath={parentPointerNode.nodePath}
    />
  )

  hardcodedAppSections.forEach(({ name, apps }) => {
    if (!name) {
      hardcodedUngrouped.push(...apps.map(renderHardcodedAppItem))
    } else {
      hardcodedGrouped.push({ name, children: apps.map(renderHardcodedAppItem) })
    }
  })

  return (
    <NavigationMenuTreeListColumn data-testid="apps-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <WppTypography type="l-strong" data-testid="apps-navigation-menu-column-title">
          {t('os.navigation_menu.apps_header_text', { levelName })}
        </WppTypography>
      </NavigationMenuColumnHeader>

      <Flex as={FadeIn} direction="column" gap={4}>
        {hasApps ? (
          <>
            {hasLocalApp && (
              <AppMenuItem
                appStableId={LOCAL_APP_CODE}
                appName={LOCAL_APP_NAME}
                href={routesManager.systemApps.local({ workspaceId: selectedWorkspaceId })}
                parentNodePath={parentPointerNode.nodePath}
              />
            )}

            {hardcodedUngrouped}

            <AppsTreeList
              tree={navigationTree}
              pointerNodes={leanAppsPointerNodes}
              renderNode={pointerNode => <LeanAppNavigationMenuItem pointerNode={pointerNode} />}
            />

            {hardcodedGrouped.map(({ name, children }) => (
              <Flex as={WppMenuGroup} key={name} header={name} gap={4} direction="column">
                {children}
              </Flex>
            ))}
          </>
        ) : (
          <WppTypography className={styles.emptyState} type="s-midi" data-testid="no-apps-yet">
            {t('os.navigation_menu.no_apps')}
          </WppTypography>
        )}
      </Flex>
    </NavigationMenuTreeListColumn>
  )
}
