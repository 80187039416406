import { Fragment, PropsWithChildren } from 'react'
import { Route, Routes } from 'react-router-dom'

import { DevResetQueries } from 'providers/tenantAndUserData/devResetQueries/DevResetQueries'
import { GENERIC_TENANT_DEV_ROUTE } from 'providers/tenantAndUserData/utils'

// Enable dev routes for generic tenant.
// Other routes will only be rendered when the loaded data is related to generic tenant
// instead of a specific one. This happens during auth callback or when the user
// clicks a browser's back button.
export const GenericTenantRoutes = process.env.DEV
  ? ({ children }: PropsWithChildren<{}>) => (
      <Routes>
        <Route path={`${GENERIC_TENANT_DEV_ROUTE}/*`} element={children} />
        <Route index path="*" element={<DevResetQueries />} />
      </Routes>
    )
  : Fragment
