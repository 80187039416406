import { GENERIC_TENANT_DEV_ROUTE } from 'providers/tenantAndUserData/utils'

export const landingRoutes = {
  root: process.env.DEV ? `/${GENERIC_TENANT_DEV_ROUTE}` : '',
  aboutOpen: 'about-open',
  whatWeOffer: 'what-we-offer',
  ourProducts: 'our-products',
  successStories: 'success-stories',
  selectOs: 'select-os',
  terms: 'terms-conditions',
  contact: 'contact-us',
}

export const demoTenantLink = 'https://demo.os.wpp.com'

export const aboutPageVideoUrl = 'https://vimeo.com/881806528'
