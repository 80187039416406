import { CaasApp, CaasAppShort } from 'types/apps/leanApps'

export const getLegacyAppCode = (app: CaasAppShort | CaasApp) => `CAAS:${app.config.windowLibraryName}`

export const isHybridLegacyApp = (app: CaasAppShort | CaasApp) => {
  const isHardcodedBrandUrl = app.config.osRoute.includes(':brandId')
  const isSlashFirst = app.config.osRoute.startsWith('/')

  // To be certain that the legacy app has a hybrid url,
  // it shouldn't have a hardcoded parameter and shouldn't start with a slash,
  // like all hardcoded legacy apps do.
  return !isHardcodedBrandUrl && !isSlashFirst
}
