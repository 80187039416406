import clsx from 'clsx'
import { PropsWithChildren, ComponentType, ComponentProps } from 'react'
import { useCss } from 'react-use'

import styles from 'pages/landing/components/button/Button.module.scss'

interface BaseProps extends PropsWithChildren {
  variant?: string
  size?: string
  className?: string
}

type ExtendedProps<T extends ComponentType<any>> = BaseProps & ComponentProps<T>

type ParentComponentProps<T extends ComponentType<any>> = {
  component: T
} & ExtendedProps<T>

enum Sizes {
  m = '16px 24px',
  s = '8px 16px',
}

export function Button<T extends ComponentType<any>>({
  component: Component = 'button',
  variant = 'primary',
  size = 's',
  children,
  className,
  ...props
}: ParentComponentProps<T>) {
  const getButtonSize = () => {
    return size === 'm' ? Sizes.m : size === 's' ? Sizes.s : ''
  }

  return (
    <Component
      {...props}
      className={clsx(
        styles.root,
        className,
        {
          [styles.primary]: variant === 'primary',
          [styles.secondary]: variant === 'secondary',
          [styles.secondaryWithBorder]: variant === 'secondary-with-border',
          [styles.transparent]: variant === 'transparent',
        },
        useCss({
          padding: getButtonSize(),
        }),
      )}
    >
      {children}
    </Component>
  )
}
