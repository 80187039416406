import { useMemo } from 'react'

import { useAppState } from 'providers/appState/AppStateProvider'
import { ProjectCanvasFluid } from 'types/projects/projectCanvas'
import { ProjectCanvasApplication, ProjectFluidConnectionType } from 'types/projects/projectPhase'
import { excludeFalsy, sortBy, uniqueWith } from 'utils/common'
import { isProjectActivityData, isProjectApplicationData } from 'utils/projects'

interface FluidCanvasConnectedApps {
  previousApps: ProjectCanvasApplication[]
  currentApp: ProjectCanvasApplication
  nextApps: ProjectCanvasApplication[]
}

export const useFluidCanvasConnectedApps = (): FluidCanvasConnectedApps => {
  const { microAppData } = useAppState()

  return useMemo((): FluidCanvasConnectedApps => {
    const { projectCanvas, projectItem } = microAppData
    const fluidCanvas = projectCanvas as ProjectCanvasFluid
    const canvasApp = projectItem!

    const appActivity = fluidCanvas.items
      .filter(isProjectActivityData)
      .find(item => item.items.find(activityItem => activityItem.application.id === canvasApp.id))
    const currentContainer = fluidCanvas.containers.find(
      ({ itemId }) => itemId === (appActivity ? appActivity.id : canvasApp.id),
    )!

    const fromApps = getConnectedApps({
      fluidCanvas,
      currentContainerId: currentContainer.id,
      isPrevious: true,
    })
    const toAppsUnique = getConnectedApps({
      fluidCanvas,
      currentContainerId: currentContainer.id,
      isPrevious: false,
    })

    if (!appActivity) {
      return {
        previousApps: fromApps,
        currentApp: canvasApp,
        nextApps: toAppsUnique,
      }
    }

    // Apps within an activity are considered linked between themselves like a train
    const sortedActivityItems = sortBy(appActivity.items, ({ orderNumber }) => orderNumber)
    const currentItemWithinActivity = sortedActivityItems.find(item => item.application.id === canvasApp.id)!
    const currentItemWithinActivityIndex = sortedActivityItems.indexOf(currentItemWithinActivity)
    const isFirst = currentItemWithinActivityIndex === 0
    const isLast = currentItemWithinActivityIndex === sortedActivityItems.length - 1

    const previousApps = isFirst ? fromApps : [sortedActivityItems[currentItemWithinActivityIndex - 1].application]
    const nextApps = isLast ? toAppsUnique : [sortedActivityItems[currentItemWithinActivityIndex + 1].application]

    return {
      previousApps,
      currentApp: currentItemWithinActivity.application,
      nextApps,
    }
  }, [microAppData])
}

const getConnectedApps = ({
  fluidCanvas,
  currentContainerId,
  isPrevious,
}: {
  fluidCanvas: ProjectCanvasFluid
  currentContainerId: string
  isPrevious: boolean
}) => {
  // Containers that are connected to the current container
  const containers = fluidCanvas.connections
    .filter(({ sourceId, targetId, type }) => {
      const destinationId = isPrevious ? targetId : sourceId

      return currentContainerId === destinationId && type === ProjectFluidConnectionType.Flow
    })
    .map(
      ({ sourceId, targetId }) =>
        fluidCanvas.containers.find(({ id }) => {
          const connectedId = isPrevious ? sourceId : targetId

          return id === connectedId
        })!,
    )

  // Mapped items within connected containers. Each container has exactly one mapped item.
  const items = containers.map(({ itemId }) => fluidCanvas.items.find(({ id }) => itemId === id)!)

  // If an item is an activity, it is further mapped either to the first or last app within that activity,
  // depending on its position relative to the current container
  const apps = items
    .map(item => {
      if (isProjectApplicationData(item)) {
        return item
      }

      const sortedActivityItems = sortBy(item.items, ({ orderNumber }) => orderNumber)

      return sortedActivityItems.at(isPrevious ? -1 : 0)?.application
    })
    .filter(excludeFalsy)

  // Filter out duplicate links
  return uniqueWith(apps, (a, b) => a.id === b.id)
}
