import { HierarchyContainerNodeId, MayBeNull, NavigationTree, ProjectsContainerNodeId } from '@wpp-open/core'

import { isHierarchyNode } from 'utils/navigation'

export type WorkspaceLevels = string[]

export enum WorkspaceType {
  All = 'All',
  Hierarchy = 'Hierarchy',
}

interface ResolvedWorkspaceResult {
  isWorkspaceValid: boolean
  workspaceLevels: WorkspaceLevels
}

export const resolveWorkspace = ({
  workspaceAzId,
  navigationTree,
  workspaceType = WorkspaceType.Hierarchy,
}: {
  workspaceAzId: MayBeNull<string>
  navigationTree: NavigationTree
  workspaceType?: WorkspaceType
}): ResolvedWorkspaceResult => {
  const getResult = (isValid: boolean, workspaceLevels: WorkspaceLevels = []): ResolvedWorkspaceResult => ({
    isWorkspaceValid: isValid,
    workspaceLevels,
  })

  // Return empty valid result if no workspaceAzId provided
  if (!workspaceAzId) {
    return getResult(true)
  }

  const { rootId, mapping } = navigationTree
  const isRoot = workspaceAzId === rootId

  // Return empty invalid result early if entity for workspaceAzId is not found
  if (
    !mapping[workspaceAzId] ||
    (workspaceType === WorkspaceType.Hierarchy && (!isHierarchyNode(mapping[workspaceAzId]) || isRoot))
  ) {
    return getResult(false)
  }

  const findParentNodeAzId = (childAzId: string) => {
    const parentNode = Object.values(mapping).find(node => node.children.includes(childAzId))
    let parentAzId = parentNode?.azId || null

    if (workspaceType !== WorkspaceType.Hierarchy && parentNode) {
      // Container nodes have azId set as null
      if (mapping[HierarchyContainerNodeId] === parentNode) {
        parentAzId = HierarchyContainerNodeId
      } else if (mapping[ProjectsContainerNodeId] === parentNode) {
        parentAzId = ProjectsContainerNodeId
      }
    }

    return workspaceType !== WorkspaceType.Hierarchy || parentAzId !== rootId ? parentAzId : null
  }

  const workspaceLevels: string[] = []
  let currentAzId: MayBeNull<string> = workspaceAzId

  do {
    workspaceLevels.unshift(currentAzId)
    currentAzId = findParentNodeAzId(currentAzId)

    if (currentAzId && workspaceLevels.includes(currentAzId)) {
      throw new Error('Circular dependency in Navigation Tree')
    }
  } while (currentAzId)

  return getResult(true, workspaceLevels)
}
