import {
  WppActionButton,
  WppIconChevron,
  WppListItem,
  WppMenuContext,
  WppMenuGroup,
} from '@platform-ui-kit/components-library-react'
import { HierarchyLevelType, NavigationTree, AnalyticsActionType } from '@wpp-open/core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import { useHardcodedApps } from 'components/navigationMenu/navigationMenuContent/utils'
import { BreadcrumbsContainer } from 'layout/header/breadcrumbs/container/BreadcrumbsContainer'
import { BreadcrumbMenuLeanAppItem } from 'layout/header/breadcrumbs/contextMenu/leanAppItem/BreadcrumbMenuLeanAppItem'
import { BreadcrumbMenuNativeAppItem } from 'layout/header/breadcrumbs/contextMenu/nativeAppItem/BreadcrumbMenuNativeAppItem'
import styles from 'layout/header/breadcrumbs/hierarchy/HierarchyBreadcrumbs.module.scss'
import { BreadcrumbItem } from 'layout/header/breadcrumbs/item/BreadcrumbItem'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useData } from 'providers/data/DataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { BreadcrumbItemHierarchy, BreadcrumbItemHierarchyType } from 'types/appState/breadcrumbs'
import { trackAnalytics } from 'utils/analytics'
import { getPointerNodeLeanAppsChildren, PointerNode, getWorkspacePathPointerNodes } from 'utils/mapping/common'
import { isHierarchyNode } from 'utils/navigation'

interface Props {
  items: BreadcrumbItemHierarchy[]
}

export const HierarchyBreadcrumbs = ({ items }: Props) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { openNavigation } = useAppState()
  const { navigationTree } = useData()
  const { nodesPathsMapping, activeNodePath } = useHeaderNavigation()

  const { currentTenant, navigationHierarchy, userDetails } = useTenantAndUserData()

  const { rootId, leanAppsMapping } = navigationTree
  const collapsibleItems = useMemo(() => items.slice(0, -1), [items])
  const lastItem = items.at(-1)

  // Apps in navigation tree have full activeNodePath, which ends in an app node.
  // Other apps (like hardcoded ones or the local app) don't have their own node, so for them
  // activeNodePath ends either in a selected workspace or container node or root node.
  const parentNode =
    isHierarchyNode(nodesPathsMapping[activeNodePath].node) || activeNodePath === rootId
      ? nodesPathsMapping[activeNodePath]
      : (nodesPathsMapping[activeNodePath].parent as PointerNode<NavigationTree>)
  const appNodes = parentNode ? getPointerNodeLeanAppsChildren(parentNode) : []
  const appWorkspaceAzId =
    parentNode?.node && isHierarchyNode(parentNode.node) && parentNode.node.type !== HierarchyLevelType.Tenant
      ? parentNode.nodeId
      : undefined
  const hardcodedAppSections = useHardcodedApps(appWorkspaceAzId)
  const isListEmpty = !appNodes.length && !hardcodedAppSections.length

  const [client, market, brand] = getWorkspacePathPointerNodes({ pointerNode: parentNode, navigationHierarchy })

  const handleItemClick = ({ type, id, link }: BreadcrumbItemHierarchy) => {
    if (link) {
      navigate(link)
    } else if (type === BreadcrumbItemHierarchyType.Hierarchy) {
      openNavigation(id)
    }
  }

  const handleOnItemClick = (nodeId: string) => {
    const data = {
      tenantId: currentTenant.id,
      tenantName: currentTenant.name,
      source: 'CORE',
      launchedWithContext: [market, brand, client].some(Boolean),
      market: market?.node.name || '',
      brand: brand?.node.name || '',
      client: client?.node.name || '',
      productName: leanAppsMapping[nodeId].name,
      productType: leanAppsMapping[nodeId].type,
      appId: leanAppsMapping[nodeId].id,
      // browserLocation: '', // disabled for now
      userId: userDetails.id,
      userEmail: userDetails.email,
      userAgency: userDetails.agency,
      coreNavigation: false,
      coreBreadcrumbs: true,
    }

    const params = Object.entries(data).map(([key, value]) => ({
      key,
      value,
    }))

    trackAnalytics({
      type: AnalyticsActionType.action,
      payload: {
        action: 'navigation_app_run',
        params: params,
      },
    })
  }

  return (
    <BreadcrumbsContainer items={collapsibleItems} onItemClick={handleItemClick}>
      {!!lastItem && (
        <WppMenuContext
          className={styles.appsMenuWrapper}
          externalClass={styles.appsMenu}
          dropdownConfig={{
            offset: [-18, 0], // Align with apps column
          }}
        >
          <BreadcrumbItem slot="trigger-element" label={lastItem.label}>
            <WppActionButton variant="secondary">
              <WppIconChevron slot="icon-start" direction="down" />
            </WppActionButton>
          </BreadcrumbItem>

          <Flex gap={4} direction="column">
            {appNodes.map(({ nodePath, nodeId }) => (
              <BreadcrumbMenuLeanAppItem
                key={nodeId}
                isChecked={nodePath === activeNodePath}
                leanApp={leanAppsMapping[nodeId]}
                workspaceAzId={appWorkspaceAzId}
                onClick={() => handleOnItemClick(nodeId)}
              />
            ))}

            {hardcodedAppSections.map(({ name, apps }) => (
              <Flex as={WppMenuGroup} key={name} header={name || ''} gap={4} direction="column">
                {apps.map(app => (
                  <BreadcrumbMenuNativeAppItem key={app.code} app={app} workspaceAzId={appWorkspaceAzId} />
                ))}
              </Flex>
            ))}

            {isListEmpty && (
              <WppListItem disabled>
                <span slot="label">{t('os.header.breadcrumbs.no_apps')}</span>
              </WppListItem>
            )}
          </Flex>
        </WppMenuContext>
      )}
    </BreadcrumbsContainer>
  )
}
