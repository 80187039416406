import { LeanAppType } from '../apps'
import { AttachmentMetadata, AttachmentMetadataResponse } from '../attachments'
import { MayBeNull } from '../common'
import { HierarchyLevelType } from '../tenant'

export const LeanAppNodeType = 'LEAN_APP'
export type LeanAppNodeType = typeof LeanAppNodeType

export const ContainerNodeType = 'CONTAINER'
export type ContainerNodeType = typeof ContainerNodeType

export const HierarchyContainerNodeId = 'hierarchy-container-node'
export type HierarchyContainerNodeId = typeof HierarchyContainerNodeId

export interface HierarchyContainerNode {
  azId: null
  type: ContainerNodeType
  name: HierarchyContainerNodeId
  logoOriginal: null
  logoThumbnail: null
  children: string[]
  apps: readonly []
}

export interface HierarchyNode {
  azId: string
  type: HierarchyLevelType
  name: string
  logoOriginal: MayBeNull<AttachmentMetadata>
  logoThumbnail: MayBeNull<AttachmentMetadata>
  children: string[]
  apps: readonly []
}

export interface LeanAppNode {
  azId: null
  type: LeanAppNodeType
  name: string
  logoOriginal: null
  logoThumbnail: null
  children: string[]
  apps: readonly []
}

interface LeanAppsMappingNodeBase {
  id: string
  name: string
  description: MayBeNull<string>
  logo: MayBeNull<AttachmentMetadataResponse>
  groups: {
    [hierarchyNodeId: string]: {
      id: string
      name: string
      order: number
    }
  }[]
}

export interface LeanAppsMappingExternalLinkNode extends LeanAppsMappingNodeBase {
  type: LeanAppType.LINK_EMBEDDED
  originId: null
  initialized: null
  config: {
    osRoute: string
    url: string
  }
}

export interface LeanAppsMappingLinkDetachedNode extends LeanAppsMappingNodeBase {
  type: LeanAppType.LINK_DETACHED
  originId: null
  initialized: null
  config: {
    url: string
  }
}

export interface LeanAppsMappingCodeEmbeddedNode extends LeanAppsMappingNodeBase {
  type: LeanAppType.CODE_EMBEDDED
  originId: null
  initialized: null
  config: {
    embeddedCode: string
    osRoute: string
  }
}

export interface LeanAppsMappingCustomPageNode extends LeanAppsMappingNodeBase {
  type: LeanAppType.CUSTOM_PAGE
  originId: null
  initialized: null
  config: {
    osRoute: string
  }
}

export interface LeanAppsMappingCaasNode extends LeanAppsMappingNodeBase {
  type: LeanAppType.CAAS
  originId: null
  initialized: null
  config: {
    osRoute: string
    bundleUrl: string
    windowLibraryName: string
    containerId: string
    requiredHierarchy: HierarchyLevelType[]
    viewPermissionName: MayBeNull<string>
  }
}

export interface LeanAppsMappingNativeNode extends LeanAppsMappingNodeBase {
  type: LeanAppType.NATIVE
  originId: null
  initialized: null
  config: {
    osRoute: string
    bundleUrl: string
    requiredHierarchy: HierarchyLevelType[]
    viewPermissionName: MayBeNull<string>
  }
}

export interface LeanAppsMappingMiroBoardInstanceNode extends LeanAppsMappingNodeBase {
  type: LeanAppType.MIRO_BOARD_INSTANCE
  originId: string
  initialized: boolean
  owner: string
  config: {
    boardId: string
    frames: { id: string; name: string }[]
    osRoute: string
    url: string
  }
}

export type LeanAppsMappingNode =
  | LeanAppsMappingExternalLinkNode
  | LeanAppsMappingLinkDetachedNode
  | LeanAppsMappingCodeEmbeddedNode
  | LeanAppsMappingCustomPageNode
  | LeanAppsMappingCaasNode
  | LeanAppsMappingNativeNode
  | LeanAppsMappingMiroBoardInstanceNode

export type LeanAppsMapping = Record<string, LeanAppsMappingNode>

export enum AppLibraryType {
  Window = 'WINDOW',
  SystemJS = 'SYSTEM_JS',
  ESM = 'ESM',
}

export interface AppLibrary {
  type: AppLibraryType
  name: MayBeNull<string>
}

export interface AppsMappingNode {
  azId: string
  name: string
  description: MayBeNull<string>
  bundleUrl: string
  osRoute: string
  configuration: MayBeNull<string>
  library: MayBeNull<AppLibrary>
  appCodeName: string
}
export type AppsMapping = Record<string, AppsMappingNode>
