import clsx from 'clsx'
import { lazy, Suspense } from 'react'
import { Navigate, Outlet, Route, Routes } from 'react-router-dom'

import styles from 'app/secureRoutes/SecureRoutes.module.scss'
import { PermittedAccess } from 'components/common/permittedAccess/PermittedAccess'
import { useIntercomUser } from 'components/intercom/utils'
import { NotFoundError, RenderErrorBoundary, RenderErrorType } from 'components/renderError'
import { RenderErrorHeight } from 'components/renderError/boundary/RenderErrorBoundary'
import {
  LOCAL_APP_BASE_URL,
  LOCAL_APP_DEFAULT_URL_CONFIG,
  LOCAL_LEGACY_APP_BASE_URL,
  LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG,
} from 'constants/apps'
import { TENANT_NAME_WPP_PITCH_OS, TENANT_SUBDOMAIN_WPP } from 'constants/common'
import { Permission } from 'constants/permission'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { PageContainer } from 'layout/pageContainer/PageContainer'
import { HomePageElement } from 'pages/homePageElement/HomePageElement'
import { MicroAppsNavigation } from 'pages/microAppsNavigation/MicroAppsNavigation'
import { MiroAuthCallback } from 'pages/miroAuth/MiroAuthCallback'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useMicroAppsContext } from 'providers/microApps/MicroAppsProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { MicroAppFromUrlType } from 'types/appState/appState'
import { routesManager } from 'utils/routesManager'

const UserProfile = lazy(() => import('pages/userProfile/UserProfile'))
const Links = lazy(() => import('pages/links/Links'))
const Contacts = lazy(() => import('pages/network/contacts/Contacts'))
const AdminPage = lazy(() => import('pages/admin/Admin'))
const Roles = lazy(() => import('pages/admin/roles/Roles'))
const Navigation = lazy(() => import('pages/admin/navigation/Navigation'))
const PlatformConfiguration = lazy(() => import('pages/admin/platformConfiguration/PlatformConfiguration'))
const Hierarchy = lazy(() => import('pages/admin/hierarchy/Hierarchy'))
const Settings = lazy(() => import('pages/admin/settings/Settings'))
const Members = lazy(() => import('pages/admin/members/Members'))
const Apps = lazy(() => import('pages/admin/apps/Apps'))
const Requests = lazy(() => import('pages/admin/requests/Requests'))
const PitchPlaybook = lazy(() => import('pages/wppPitchOsDashboard/pages/pitchPlaybook/PitchPlaybook'))
const NestleDemo = lazy(() => import('pages/wppOpenDashboard/pages/nestleDemo/NestleDemo'))
const VolkswagenDemo = lazy(() => import('pages/wppOpenDashboard/pages/volkswagenDemo/VolkswagenDemo'))
const BeiersdorfDemo = lazy(() => import('pages/wppOpenDashboard/pages/beiersdorfDemo/BeiersdorfDemo'))
const SpotifyDemo = lazy(() => import('pages/wppOpenDashboard/pages/spotifyDemo/SpotifyDemo'))
const MindshareDemo = lazy(() => import('pages/wppOpenDashboard/pages/mindshareDemo/MindshareDemo'))
const MSixDemo = lazy(() => import('pages/wppOpenDashboard/pages/mSixDemo/MSixDemo'))
const FutureArticle = lazy(() => import('pages/wppOpenDashboard/components/futureOfDataWidget/FutureArticle'))

export const SecureRoutes = () => {
  const { isMicroAppActive } = useMicroAppsContext()
  const { microAppData } = useAppState()
  const { currentTenant } = useTenantAndUserData()

  const isLoading = microAppData.type === MicroAppFromUrlType.LOADING
  // TODO [WPPLONOP-15160]: Hardcoded by tenant name
  const isWppPitchOsPageEnabled = currentTenant.name === TENANT_NAME_WPP_PITCH_OS
  const isWppOpenPageEnabled = currentTenant.subdomain === TENANT_SUBDOMAIN_WPP

  useIntercomUser()

  return (
    <RenderErrorBoundary
      catchErrors={[
        RenderErrorType.DataIsNotAvailable,
        RenderErrorType.ForbiddenPage,
        RenderErrorType.NotFound,
        RenderErrorType.CriticalError,
      ]}
      height={RenderErrorHeight.FullPage}
    >
      {/** Note: In some cases single-spa routing happens faster than React re-renders,
         so this MicroApp container is always available */}
      <div
        className={clsx(styles.microAppContainer, {
          [styles.hidden]: !isMicroAppActive || isLoading,
        })}
        id="micro-app"
      />

      {isLoading ? (
        <LoadingPage />
      ) : (
        <Suspense>
          <Routes>
            <Route
              element={
                <PageContainer>
                  <Outlet />
                </PageContainer>
              }
            >
              <Route path="miro-auth-callback" element={<MiroAuthCallback />} />

              <Route path="home/*" element={<HomePageElement />} />

              <Route path="admin" element={<PermittedAccess permission={Permission.OS_ADMIN_SETTING_ACCESS} />}>
                <Route element={<AdminPage />}>
                  <Route
                    path="platform-config"
                    element={<PermittedAccess permission={Permission.OS_GLOBAL_CONFIG_ACCESS} />}
                  >
                    <Route index path="*" element={<PlatformConfiguration />} />
                  </Route>
                  <Route path="navigation" element={<Navigation />} />
                  <Route path="hierarchy/*" element={<Hierarchy />} />
                  <Route path="members/*" element={<Members />} />
                  <Route path="roles" element={<Roles />} />
                  <Route path="apps" element={<PermittedAccess permission={Permission.OS_LEAN_APPS_ENTITIES_MANAGE} />}>
                    <Route index path="*" element={<Apps />} />
                  </Route>

                  <Route path="requests/*" element={<Requests />} />

                  <Route path="settings" element={<Settings />} />

                  <Route index element={<Navigate replace to="settings" />} />
                  <Route path="*" element={<NotFoundError />} />
                </Route>
              </Route>

              <Route path="links" element={<PermittedAccess permission={Permission.OS_APPS_ACCESS} />}>
                <Route index element={<Links />} />
                <Route path="*" element={<NotFoundError />} />
              </Route>

              <Route path="user" element={<PermittedAccess permission={Permission.OS_USERDETAILS_APP_ACCESS} />}>
                <Route index element={<Navigate replace to="profile" />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="*" element={<NotFoundError />} />
              </Route>

              <Route path="network" element={<PermittedAccess permission={Permission.NETWORK_APP_ACCESS} />}>
                <Route index element={<Navigate replace to="contacts/primary" />} />
                <Route path="contacts/*" element={<Contacts />} />
                <Route path="*" element={<NotFoundError />} />
              </Route>

              {isWppPitchOsPageEnabled && <Route path="pitch-playbook" element={<PitchPlaybook />} />}

              {isWppOpenPageEnabled && (
                <>
                  <Route path="nestle">
                    <Route index path="*" element={<NestleDemo />} />
                  </Route>
                  <Route path="volkswagen">
                    <Route index path="*" element={<VolkswagenDemo />} />
                  </Route>
                  <Route path="beiersdorf">
                    <Route index path="*" element={<BeiersdorfDemo />} />
                  </Route>
                  <Route path="spotify">
                    <Route index path="*" element={<SpotifyDemo />} />
                  </Route>
                  <Route path="mindshare">
                    <Route index path="*" element={<MindshareDemo />} />
                  </Route>
                  <Route path="msix">
                    <Route index path="*" element={<MSixDemo />} />
                  </Route>
                  <Route path="future-of-data">
                    <Route index path="*" element={<FutureArticle />} />
                  </Route>
                </>
              )}
            </Route>

            <Route path={LOCAL_APP_BASE_URL} element={<Navigate replace to={LOCAL_APP_DEFAULT_URL_CONFIG} />} />
            <Route
              path={LOCAL_LEGACY_APP_BASE_URL}
              element={<Navigate replace to={LOCAL_LEGACY_APP_DEFAULT_URL_CONFIG} />}
            />
            <Route path="/" element={<Navigate replace to={routesManager.internalPages.home.root()} />} />

            <Route path="*" element={<MicroAppsNavigation />} />
          </Routes>
        </Suspense>
      )}
    </RenderErrorBoundary>
  )
}
