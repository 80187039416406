import { facadeApi } from 'api'
import { LeanApp } from 'types/apps/leanApps'

interface Params {
  leanAppId: string
  tenantId: string
}

export const fetchPrelaunchedLeanAppApi = ({ tenantId, leanAppId }: Params) =>
  facadeApi.post<LeanApp>(`/tenants/${tenantId}/lean-apps/${leanAppId}/hooks/pre-launch`)
