import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { ForbiddenPageError } from 'components/renderError'
import { useToast } from 'providers/toast/ToastProvider'

export const MiroForbiddenState = () => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()

  useEffect(() => {
    enqueueToast({
      header: t('os.lean_app_page.miro_forbidden_toast.header'),
      message: t('os.lean_app_page.miro_forbidden_toast.text'),
      type: 'error',
    })
  }, [t, enqueueToast])

  return <ForbiddenPageError />
}
