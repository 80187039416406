import { MayBeNull } from '@wpp-open/core'
import { useCallback } from 'react'

import { hasPermission as hasPermissionLegacy } from 'legacy/navigation/utils'
import { useData } from 'providers/data/DataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'

export const useHasPermission = () => {
  const { currentTenant } = useTenantAndUserData()
  const { permissions } = useData()

  return {
    hasPermission: useCallback(
      (permission?: MayBeNull<string>) =>
        !permission || hasPermissionLegacy(permissions, permission, currentTenant.azMeta.organizationsId),
      [permissions, currentTenant.azMeta.organizationsId],
    ),
  }
}
