import { Outlet } from 'react-router-dom'

import { NotFoundError } from 'components/renderError'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'

export const IsPlaygroundTenant = () => {
  const { currentTenant } = useTenantAndUserData()
  const playgroundTenantId = '7a68cd32-7976-4982-9ba4-a7b9b389a892'
  return currentTenant.id === playgroundTenantId ? <Outlet /> : <NotFoundError />
}
