import {
  ChildHierarchyLevelType,
  MayBeNull,
  NavigationTree,
  OpenAppHandler,
  OpenLeanAppHandler,
  SelectedWorkspace,
  SelectedWorkspaceItem,
  LeanAppType,
  OpenProjectLeanAppHandler,
} from '@wpp-open/core'
import { useNavigate } from 'react-router-dom'

import { ApiQueryKeys } from 'constants/apiQueryKeys'
import { useStableCallback } from 'hooks/useStableCallback'
import { useData } from 'providers/data/DataProvider'
import { queryClient } from 'providers/osQueryClient/utils'
import { simulateLinkClick } from 'utils/links'
import { getAppUrl, getLeanAppUrl } from 'utils/navigation'
import { resolveWorkspace, WorkspaceLevels } from 'utils/workspace'

export const mapResolvedWorkspaceToContext = ({
  workspaceLevels,
  navigationTree,
}: {
  workspaceLevels: WorkspaceLevels
  navigationTree: NavigationTree
}): MayBeNull<SelectedWorkspace> => {
  const rootId = workspaceLevels.length ? workspaceLevels[workspaceLevels.length - 1] : null

  if (!rootId) {
    return null
  }

  return {
    azId: rootId,
    mapping: Object.fromEntries(
      workspaceLevels.map((nodeAzId, index) => [
        nodeAzId,
        getStructureItem({
          azId: nodeAzId,
          parentAzId: index === 0 ? undefined : workspaceLevels[index - 1],
          navigationTree,
        }),
      ]),
    ),
  }
}

const getStructureItem = ({
  azId,
  parentAzId,
  navigationTree,
}: {
  azId: string
  parentAzId?: string
  navigationTree: NavigationTree
}): SelectedWorkspaceItem => ({
  azId,
  name: navigationTree.mapping[azId].name,
  type: navigationTree.mapping[azId].type as ChildHierarchyLevelType,
  parentAzId,
})

export const useOpenApp = (): OpenAppHandler => {
  const navigate = useNavigate()
  const { navigationTree } = useData()
  const navigateStable = useStableCallback(navigate)

  return useStableCallback((appCode: string, workspaceAzId?: string) => {
    const app = Object.values(navigationTree.appsMapping).find(appNode => appNode.appCodeName === appCode)

    if (!app) {
      console.error(`App with code '${appCode}' is not found`)
      return
    }

    const appUrl = getAppUrl(app.osRoute, workspaceAzId)

    const { isWorkspaceValid } = resolveWorkspace({
      workspaceAzId: workspaceAzId ?? null,
      navigationTree,
    })

    if (!isWorkspaceValid) {
      console.error(`Workspace with az id '${workspaceAzId}' is not valid`)
      return
    }

    navigateStable(appUrl)
  })
}

export const useOpenLeanApp = (): OpenLeanAppHandler => {
  const navigate = useNavigate()
  const { navigationTree, leanApps } = useData()
  const navigateStable = useStableCallback(navigate)

  return useStableCallback(async ({ leanAppId, instanceId, workspaceAzId }) => {
    const app = leanApps.find(leanApp => leanApp.id === leanAppId)

    if (!app) {
      console.error(`Lean app with id '${leanAppId}' is not found`)
      return
    }

    if (app.type === LeanAppType.MIRO_BOARD && !instanceId) {
      console.error(`Miro board instance ID is required to open lean app with id '${leanAppId}'`)
      return
    }

    const appUrl = getLeanAppUrl({
      leanApp: app,
      instanceId,
      workspaceAzId,
    })

    if (app.type === LeanAppType.LINK_DETACHED) {
      simulateLinkClick({ href: appUrl, target: '_blank' })
      return
    }

    const { isWorkspaceValid } = resolveWorkspace({
      workspaceAzId: workspaceAzId ?? null,
      navigationTree,
    })

    if (!isWorkspaceValid) {
      console.error(`Workspace with az id '${workspaceAzId}' is not valid`)
      return
    }

    navigateStable(appUrl)
  })
}

export const useOpenProjectLeanApp = (): OpenProjectLeanAppHandler => {
  const navigate = useNavigate()
  const { leanApps } = useData()
  const navigateStable = useStableCallback(navigate)

  return useStableCallback(async ({ leanAppId, instanceId, projectId, itemId }) => {
    const app = leanApps.find(leanApp => leanApp.id === leanAppId)

    if (!app) {
      console.error(`Lean app with id '${leanAppId}' is not found`)
      return
    }

    if (app.type === LeanAppType.MIRO_BOARD && !instanceId) {
      console.error(`Miro board instance ID is required to open lean app with id '${leanAppId}'`)
      return
    }

    await Promise.all([
      queryClient.resetQueries({ queryKey: [ApiQueryKeys.PROJECT, { id: projectId }] }),
      queryClient.resetQueries({ queryKey: [ApiQueryKeys.PROJECT_WORKFLOW_LINEAR, { id: projectId }] }),
      queryClient.resetQueries({ queryKey: [ApiQueryKeys.PROJECT_WORKFLOW_FLUID, { id: projectId }] }),
    ])

    const appUrl = getLeanAppUrl({
      leanApp: app,
      instanceId,
      projectId,
      projectItemId: itemId,
    })

    if (app.type === LeanAppType.LINK_DETACHED) {
      simulateLinkClick({ href: appUrl, target: '_blank' })
      return
    }

    navigateStable(appUrl)
  })
}
