import { LeanAppType } from '@wpp-open/core'

import { DataFromUrlShort, LeanAppDataFromUrl, LegacyAppDataFromUrl } from 'types/appState/appState'
import { ProjectCanvasActivity, ProjectCanvasApplication, ProjectCanvasItem } from 'types/projects/projectPhase'

export const isProjectApplicationData = (itemData: ProjectCanvasItem): itemData is ProjectCanvasApplication =>
  'externalAppId' in itemData

export const isProjectActivityData = (itemData: ProjectCanvasItem): itemData is ProjectCanvasActivity =>
  'items' in itemData

export const isProjectAppActive = (
  projectAppData: ProjectCanvasApplication,
  microAppDataShort: LeanAppDataFromUrl<DataFromUrlShort> | LegacyAppDataFromUrl<DataFromUrlShort>,
) => {
  const osAppId =
    microAppDataShort.app.type === LeanAppType.MIRO_BOARD
      ? projectAppData.externalAppOriginId
      : projectAppData.externalAppId

  return projectAppData.id === microAppDataShort.projectItemId && osAppId === microAppDataShort.app.id
}
