import { WppTypography } from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType, HierarchyLevelType } from '@wpp-open/core'
import { useTranslation } from 'react-i18next'

import { Avatar } from 'components/common/avatar/Avatar'
import styles from 'components/navigationMenu/navigationMenuContent/hierarchyNavigationMenuColumn/HierarchyNavigationMenuColumn.module.scss'
import { NavigationMenuColumnHeader } from 'components/navigationMenu/navigationMenuContent/navigationMenuColumnHeader/NavigationMenuColumnHeader'
import { useNavigationMenuController } from 'components/navigationMenu/navigationMenuContent/NavigationMenuControllerContext'
import { NavigationMenuTreeListColumn } from 'components/navigationMenu/navigationMenuContent/navigationMenuTreeListColumn/NavigationMenuTreeListColumn'
import { HierarchyTreeList } from 'components/treeList/hierarchyTreeList/HierarchyTreeList'
import { SearchableTreeList } from 'components/treeList/searchableTreeList/SearchableTreeList'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { DEFAULT_PLURAL_COUNT } from 'constants/i18n'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useData } from 'providers/data/DataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { trackAnalytics } from 'utils/analytics'
import {
  getWorkspacePathPointerNodes,
  getPointerNodeHierarchyChildren,
  getHierarchyContainerNodePath,
  useGetFirstLevelNodesLogoUrl,
  getNextHierarchyLevelType,
  getHierarchyLevelTKey,
} from 'utils/mapping/common'

export const HierarchyNavigationMenuColumn = () => {
  const { t } = useTranslation()
  const { navigationTree } = useData()
  const { navigationHierarchy, currentTenant } = useTenantAndUserData()
  const { nodesPathsMapping } = useHeaderNavigation()
  const { localNodePath, setLocalNodePath } = useNavigationMenuController()
  const getNodeLogoUrl = useGetFirstLevelNodesLogoUrl(navigationTree)

  const workspacePointerNodes = getWorkspacePathPointerNodes({
    pointerNode: nodesPathsMapping[localNodePath],
    navigationHierarchy,
  })

  const isHierarchySet = navigationHierarchy.length > 0
  const isFirstHierarchyLevelVisible = !workspacePointerNodes.length || navigationHierarchy.length === 1
  const lastWorkspaceNode = workspacePointerNodes.at(-1)

  const levelType = getNextHierarchyLevelType({
    type: (lastWorkspaceNode?.node.type as HierarchyLevelType) || HierarchyLevelType.Tenant,
    currentTenant,
  })

  const levelTKey = getHierarchyLevelTKey(levelType)
  const levelLabelPlural = t(levelTKey, { count: DEFAULT_PLURAL_COUNT })

  return (
    <NavigationMenuTreeListColumn data-testid="hierarchy-navigation-menu-column">
      <NavigationMenuColumnHeader>
        <WppTypography type="l-strong" data-testid="hierarchy-navigation-menu-column-title">
          {levelLabelPlural}
        </WppTypography>
      </NavigationMenuColumnHeader>

      {isHierarchySet ? (
        <>
          {isFirstHierarchyLevelVisible ? (
            <SearchableTreeList
              pointerNodes={getPointerNodeHierarchyChildren({
                pointerNode: nodesPathsMapping[getHierarchyContainerNodePath(navigationTree)],
                navigationHierarchy,
              })}
              inputPlaceholder={t('os.navigation_menu.search_placeholder', {
                entity: levelLabelPlural.toLocaleLowerCase(),
              })}
              renderNode={({ nodePath, node: { azId, name, logoThumbnail, type } }) => (
                <TreeListItem
                  checked={localNodePath.startsWith(nodePath)}
                  onClick={() => {
                    if (type === HierarchyLevelType.Client) {
                      trackAnalytics({
                        type: AnalyticsActionType.action,
                        payload: {
                          action: 'Recent Clients',
                          params: [
                            { key: 'clientId', value: azId! },
                            { key: 'tenantId', value: currentTenant.id },
                          ],
                        },
                      })
                    }

                    setLocalNodePath(nodePath)
                  }}
                >
                  <Avatar slot="left" src={getNodeLogoUrl(logoThumbnail?.key)} name={name} />
                  <span slot="label">{name}</span>
                </TreeListItem>
              )}
              emptyState={
                <WppTypography data-testid="no-hierarchy-item-label" type="s-midi">
                  {t('os.navigation_menu.no_hierarchy_items', { levelName: levelLabelPlural })}
                </WppTypography>
              }
            />
          ) : (
            <HierarchyTreeList
              workspacePointerNodes={workspacePointerNodes}
              nodesPathsMapping={nodesPathsMapping}
              onParentNodeClick={({ parent }) => setLocalNodePath(parent!.nodePath)}
              getNodeLogoUrl={getNodeLogoUrl}
              renderNode={({ nodePath, node: { name } }) => (
                <TreeListItem
                  checked={localNodePath.startsWith(nodePath)}
                  onClick={e => {
                    if (!e.currentTarget.checked) {
                      setLocalNodePath(nodePath)
                    }
                  }}
                >
                  <span slot="label">{name}</span>
                </TreeListItem>
              )}
              emptyState={
                <WppTypography type="s-midi">
                  {t('os.navigation_menu.no_hierarchy_items', { levelName: levelLabelPlural })}
                </WppTypography>
              }
            />
          )}
        </>
      ) : (
        <WppTypography data-testid="hierarchy-not-configured-label" className={styles.emptyState} type="s-midi">
          {t('os.navigation_menu.hierarchy_not_configured')}
        </WppTypography>
      )}
    </NavigationMenuTreeListColumn>
  )
}
