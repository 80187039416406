export enum ApiQueryKeys {
  CURRENT_USER = 'current-user',
  USER_BY_EMAIL = 'user-by-email',
  PRIMARY_USERS_LIST = 'primary-users-list',
  USERS_LIST = 'users-list',
  USERS_LIST_BY_EMAILS = 'users-list-by-emails',
  USER_PERMISSIONS = 'user-permissions',
  CURREN_USER_PERMISSIONS = 'current-user-permissions',
  AZ_USER_BY_EMAIL = 'az-user-by-email',
  USERS_LIST_AZ = 'users-list-az',
  USER_PROFILE_COUNTRIES_LIST = 'user-profile-countries-list',

  WIDGETS = 'widgets',
  WIDGET_TEMPLATES = 'widget-templates',

  NEWS = 'news',
  NEWS_CREDENTIALS = 'news_credentials',
  RECENT_CLIENTS = 'recent-clients',

  CREDENTIALS = 'credentials',

  WORKSPACE_AGENCIES = 'workspace-agencies',
  APPLICATIONS = 'applications',
  ACCOUNTS = 'accounts',

  TENANT_ROLES_INFINITE = 'tenant-roles-infinite',

  COUNTRIES_INFINITE = 'countries-infinite',
  JOB_TITLES_INFINITE = 'job-titles-infinite',
  DEPARTMENTS_INFINITE = 'departments-infinite',
  USERS_LIST_INFINITE = 'users-list-infinite',

  WORKSPACE = 'workspace',
  RND_WRIKE_METRICS = 'RND_WRIKE_METRICS',
  MY_WORKSPACES = 'my-workspaces',
  OTHER_WORKSPACES = 'other-workspaces',
  APPS = 'apps',
  LEAN_APPS = 'lean-apps',
  LEAN_APPS_INFINITE = 'lean-apps-infinite',
  LEAN_APP = 'lean-app',
  LEAN_APP_PRELAUNCHED = 'lean-app-prelaunched',
  LEAN_APP_ASSET = 'lean-app-asset',
  NAVIGATION_NODES = 'navigation-nodes',

  AVAILABLE_TENANTS = 'available-tenants',
  ALL_TENANTS = 'all-tenants',
  TENANT = 'tenant',
  TENANT_IDPS = 'tenant-idps',
  DEFAULT_IDPS = 'default-tenant-idps',

  HOST_INFO = 'host-info',
  CURRENT_TENANT_THEME = 'current-tenant/tenant-theme',
  DEFAULT_TENANT = 'default-tenant',

  NAVIGATION_TREE = 'navigation-tree',
  HIERARCHY_TREE = 'hierarchy-tree',
  NAVIGATION_GROUPS = 'navigation-groups',

  TENANTS_DOWNLOAD_URLS = 'tenants-download-urls',
  MASTER_DATA_DOWNLOAD_URLS = 'master-data-download-urls',
  USER_DETAILS_DOWNLOAD_URLS = 'user-details-download-urls',

  GROUPS_LIST_INFINITE = 'groups-list-infinite',
  GROUPS_LIST = 'groups-list',
  GROUP = 'group',
  GROUP_USERS = 'group-users',
  GROUP_ROLES = 'group-roles',

  ROLE = 'role',
  ROLES = 'roles',
  ROLES_LIST = 'roles-list',
  ROLE_MEMBERS = 'role-members',
  ROLE_MEMBERS_INFINITE = 'role-members-infinite',
  ROLES_SOURCES_INFINITE = 'roles-sources-infinite',

  MEMBER_ROLES = 'member-roles',
  MEMBER_ROLES_EDIT = 'member-roles-edit',

  MASTER_DATA_AGENCIES = 'master-data-agencies',
  MASTER_DATA_BRANDS = 'master-data-brands',
  MASTER_DATA_CLIENTS = 'master-data-clients',
  MASTER_DATA_INDUSTRIES = 'master-data-industries',
  MASTER_DATA_MARKETS = 'master-data-markets',
  MASTER_DATA_REGIONS = 'master-data-regions',

  ORGANIZATION_UNIT = 'organization-unit',
  CLIENT_NODES_INFINITE = 'client-nodes-infinite',
  MARKET_NODES_INFINITE = 'market-nodes-infinite',
  BRAND_NODES_INFINITE = 'brand-nodes-infinite',
  TENANT_THEME = 'tenant-theme',

  PROJECTS = 'projects',
  PROJECT = 'project',
  WIDGET_PROJECTS = 'widget-projects',
  PROJECT_WORKFLOW_LINEAR = 'project-workflow-linear',
  PROJECT_WORKFLOW_FLUID = 'project-workflow-fluid',
  PROJECT_PHASE = 'project-phase',
  WORKFLOW_TEMPLATES = 'workflow-templates',
  INTERCOM_DATA = 'intercom-data',

  MIRO_CONFIG_STATUS = 'miro-config-status',
  MIRO_CONFIG = 'miro-config',
  MIRO_AUTH_LINK = 'miro-auth-link',
  MIRO_AUTH_ME = 'miro-auth-me',
  CHECK_MIRO_AUTH_TOKEN = 'check-miro-auth-token',

  ACCESS_REQUESTS = 'access-requests',
  ACCESS_REQUESTS_COUNT = 'access-requests-count',
  SIGN_UP_REQUESTS = 'sign-up-requests',
  SIGN_UP_REQUESTS_COUNT = 'sign-up-requests-count',

  TAXONOMY = 'taxonomy',

  WIDGET_ASSET = 'widget-asset',

  CALENDAR_WIDGET_EVENTS = 'calendar-widget-events',
  CALENDAR_WIDGET_ALL_EVENTS = 'calendar-widget-all-events',
  TIMEZONES = 'timezones',
  TIMEZONE_INFO = 'timezone-info',

  USER_FEEDS = 'user-feeds',
  FEEDS_STATUSES = 'feeds-statuses',

  WORKFRONT_AUTH_LINK = 'workfront-auth-link',
  WORKFRONT_AUTH_REFRESH = 'workfront-auth-refresh',
  WORKFRONT_AUTHENTICATE = 'workfront-authenticate',
  WORKFRONT_PROJECTS = 'workfront-projects',
  WORKFRONT_ASSIGNMENTS = 'workfront-assignments',
  WORKFRONT_TASK = 'workfront-task',
}
