import { WppInput, WppTextareaInput, WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { SyntheticEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { useSendContactApi } from 'api/landing/queries/useSendContactApi'
import { Flex } from 'components/common/flex/Flex'
import image from 'pages/landing/assets/contact/contact.png'
import { Button } from 'pages/landing/components/button/Button'
import styles from 'pages/landing/contactPage/Contact.module.scss'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { useToast } from 'providers/toast/ToastProvider'
import { mailto } from 'utils/links'

const contactEmail = 'wppopensupport@wpp.com'

export const Contact = () => {
  const { userDetails } = useTenantAndUserData()
  const { t } = useTranslation()

  const { enqueueToast } = useToast()

  const [description, setDescription] = useState('')
  const { mutateAsync: handleSendContact } = useSendContactApi()

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault()
    try {
      await handleSendContact({
        message: description,
      })

      enqueueToast({
        type: 'success',
        message: 'Email sent',
      })
    } catch {
      enqueueToast({
        message: t('os.common.errors.general'),
        type: 'error',
      })
    } finally {
      setDescription('')
    }
  }

  return (
    <Flex className={styles.container} justify="center">
      <Flex className={styles.content} direction="column" gap={40}>
        <WppTypography type="4xl-display">Contact</WppTypography>

        <Flex gap={80} align="center">
          <Flex direction="column" gap={40}>
            <WppTypography type="l-body">
              For any general comments or queries related to WPP Open, please contact us at&nbsp;
              <Link to={mailto(contactEmail)} className={styles.link}>
                {contactEmail}
              </Link>
              &nbsp;or send us a message in the form below.
            </WppTypography>

            <Flex direction="column" as="form" gap={24} onSubmit={handleSubmit}>
              <WppInput
                disabled
                required
                value={userDetails.email}
                labelConfig={{ text: 'Email' }}
                className={styles.input}
              />
              <WppTextareaInput
                required
                labelConfig={{ text: 'Add your comment or query below' }}
                placeholder="Write a description"
                value={description}
                charactersLimit={200}
                warningThreshold={201}
                onWppChange={({ detail }) => setDescription(detail.value || '')}
              />
              <Button
                disabled={!description || description.length > 200}
                className={clsx(styles.button, { [styles.disabledButton]: !description || description.length > 200 })}
                size="m"
                type="submit"
              >
                <WppTypography type="l-body">Send</WppTypography>
              </Button>
            </Flex>
          </Flex>

          <img src={image} className={styles.image} />
        </Flex>
      </Flex>
    </Flex>
  )
}
