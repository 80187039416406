import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import getStartedStepsData from 'pages/landing/assets/landing/jsons/getStartedSteps.json'
import { Button } from 'pages/landing/components/button/Button'
import styles from 'pages/landing/components/getStartedSteps/GetStartedSteps.module.scss'
import { landingRoutes } from 'pages/landing/const'

interface GetStartedStep {
  image: string
  title: string
  text: string
}

export const GetStartedSteps = () => {
  const getStartedSteps: GetStartedStep[] = getStartedStepsData?.data || []

  return (
    <Flex
      direction="column"
      gap={40}
      className={styles.wrapper}
      justify="center"
      align="center"
      data-testid="get-started-section"
    >
      <WppTypography type="4xl-display">Get started in 3 simple steps</WppTypography>
      <Flex direction="row" gap={50} justify="center">
        {getStartedSteps.map(getStartedStep => (
          <Flex
            key={getStartedStep.title}
            direction="column"
            align="center"
            gap={16}
            className={styles.getStartedStepsItem}
          >
            <img src={getStartedStep.image} className={styles.getStartedStepsItemImg} />
            <Flex direction="column" gap={8}>
              <WppTypography type="2xl-heading">{getStartedStep.title}</WppTypography>
              <WppTypography type="m-body">{getStartedStep.text}</WppTypography>
            </Flex>
          </Flex>
        ))}
      </Flex>
      <Button size="m" component={Link} to={`${landingRoutes.root}/${landingRoutes.selectOs}`}>
        <WppTypography type="l-midi">Access Open</WppTypography>
      </Button>
    </Flex>
  )
}
