import { AppLibraryType, HierarchyLevelType, LeanAppType, MayBeNull, NavigationTreeMapping } from '@wpp-open/core'
import { useMemo } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import {
  AppData,
  appsList,
  LOCAL_APP_BASE_URL,
  LOCAL_APP_CODE,
  LOCAL_APP_NAME,
  LOCAL_LEGACY_APP_BASE_URL,
} from 'constants/apps'
import { isHybridLegacyApp } from 'legacy/utils/apps'
import { useData } from 'providers/data/DataProvider'
import { CaasAppShort, LeanAppShort } from 'types/apps/leanApps'
import {
  AppDataFromUrl,
  DataFromUrlFull,
  DataFromUrlShort,
  InvalidAppDataFromUrl,
  LeanAppDataFromUrl,
  LegacyAppDataFromUrl,
  LoadingAppDataFromUrlFull,
  MicroAppDataFromUrlFull,
  MicroAppDataFromUrlShort,
  MicroAppFromUrlType,
} from 'types/appState/appState'
import { LocalAppLibraryType } from 'types/common/singleSpa'
import { Project } from 'types/projects/project'
import { ProjectCanvasFluid, ProjectCanvasLinear } from 'types/projects/projectCanvas'
import { ProjectCanvasApplication, ProjectPhase } from 'types/projects/projectPhase'
import { isRenderableLeanAppShortOrNative } from 'utils/apps'
import { isProjectActivityData, isProjectAppActive } from 'utils/projects'
import { WorkspaceLevels } from 'utils/workspace'

// Workspace is always set with this prefix `/{prefix}/:workspaceId/{app-route}`
export const WORKSPACE_URL_PREFIX = 'w'

// Project is always set with this prefix `/{prefix}/:projectId/:phaseId/{app-route}`
export const PROJECT_URL_PREFIX = 'p'

interface WorkspaceParts {
  workspaceAzId: string | undefined
  restOfPath: string
}

interface LocalAppParts {
  baseUrl: string
  libraryType: AppLibraryType
  bundleUrl: string
  windowLibraryName?: string
}

interface LocalLegacyAppParts {
  osRoute: string
  bundleUrl: string
  windowLibraryName: string
}

export const getLegacyAppDataFromUrl = ({
  pathname,
  leanApps,
}: {
  pathname: string
  leanApps: LeanAppShort[]
}): MayBeNull<LegacyAppDataFromUrl<DataFromUrlShort>> => {
  // All legacy apps that use workspaces have a `/brands/:brandId` part
  const legacyAppWorkspaceMatch = matchPath('/brands/:brandId/*', pathname)

  let brandId: string | undefined
  let restOfPath: string

  if (legacyAppWorkspaceMatch) {
    const { params } = legacyAppWorkspaceMatch

    brandId = params.brandId
    restOfPath = params['*']!
  } else {
    restOfPath = pathname.slice(1)
  }

  if (!restOfPath) {
    return null
  }

  const fillLegacyAppBaseUrlWithBrandId = (requiredBaseUrl: string) => {
    const baseUrl = brandId ? requiredBaseUrl.replace(':brandId', brandId) : requiredBaseUrl
    return baseUrl.replace(/\/+$/g, '')
  }

  const app = leanApps
    .filter((app): app is CaasAppShort => app.type === LeanAppType.CAAS)
    .find(({ config }) => matchPath(`${fillLegacyAppBaseUrlWithBrandId(config.osRoute)}/*`, pathname))

  if (!app) {
    return null
  }

  return {
    type: MicroAppFromUrlType.LEGACY,
    app,
    workspaceAzId: brandId,
    currentBaseUrl: fillLegacyAppBaseUrlWithBrandId(app.config.osRoute),
  }
}

const getHybridLegacyAppDataFromUrl = ({
  pathname,
  leanApps,
  localLegacyAppData,
}: {
  pathname: string
  leanApps: LeanAppShort[]
  localLegacyAppData: MayBeNull<CaasAppShort>
}): MayBeNull<LegacyAppDataFromUrl<DataFromUrlShort>> => {
  const workspaceMatch = matchPath(`/${WORKSPACE_URL_PREFIX}/:workspaceAzId/*`, pathname)
  const projectMatch = matchPath(`/${PROJECT_URL_PREFIX}/:projectId/:itemId/*`, pathname)

  let workspaceAzId: string | undefined
  let projectId: string | undefined
  let projectItemId: string | undefined
  let restOfPath: string

  if (workspaceMatch) {
    const { params } = workspaceMatch

    workspaceAzId = params.workspaceAzId
    restOfPath = params['*']!
  } else if (projectMatch) {
    const { params } = projectMatch

    projectId = params.projectId
    projectItemId = params.itemId
    restOfPath = params['*']!
  } else {
    restOfPath = pathname.slice(1)
  }

  if (!restOfPath) {
    return null
  }

  const getResult = (app: CaasAppShort): LegacyAppDataFromUrl<DataFromUrlShort> => {
    let currentBaseUrl: string = app.config.osRoute

    if (workspaceAzId) {
      currentBaseUrl = `${WORKSPACE_URL_PREFIX}/${workspaceAzId}/${app.config.osRoute}`
    } else if (projectId) {
      currentBaseUrl = `${PROJECT_URL_PREFIX}/${projectId}/${projectItemId}/${app.config.osRoute}`
    }

    return {
      type: MicroAppFromUrlType.LEGACY,
      app,
      workspaceAzId,
      projectId,
      projectItemId,
      currentBaseUrl,
    }
  }

  if (localLegacyAppData) {
    return getResult(localLegacyAppData)
  }

  const app = leanApps
    .filter((app): app is CaasAppShort => app.type === LeanAppType.CAAS)
    .filter(isHybridLegacyApp)
    .find(({ config }) => matchPath(`/${config.osRoute}/*`, `/${restOfPath}`))

  if (!app) {
    return null
  }

  return getResult(app)
}

const getAppDataFromUrl = ({
  workspaceParts,
  localAppData,
}: {
  workspaceParts: MayBeNull<WorkspaceParts>
  localAppData: MayBeNull<AppData>
}): MayBeNull<AppDataFromUrl<DataFromUrlShort>> => {
  if (!workspaceParts) {
    return null
  }

  const { workspaceAzId, restOfPath } = workspaceParts

  const getResult = (app: AppData): AppDataFromUrl<DataFromUrlShort> => ({
    type: MicroAppFromUrlType.LATEST,
    app,
    workspaceAzId,
    currentBaseUrl: workspaceAzId ? `${WORKSPACE_URL_PREFIX}/${workspaceAzId}/${app.baseUrl}` : app.baseUrl,
  })

  if (localAppData) {
    return getResult(localAppData)
  }

  const app = appsList.find(({ baseUrl }) => matchPath(`/${baseUrl}/*`, `/${restOfPath}`))

  if (!app) {
    return null
  }

  return getResult(app)
}

const getLeanAppDataFromUrl = ({
  pathname,
  leanApps,
}: {
  pathname: string
  leanApps: LeanAppShort[]
}): MayBeNull<LeanAppDataFromUrl<DataFromUrlShort>> => {
  const leanAppWorkspaceMatch = matchPath(`/${WORKSPACE_URL_PREFIX}/:workspaceAzId/*`, pathname)
  const leanAppProjectMatch = matchPath(`/${PROJECT_URL_PREFIX}/:projectId/:itemId/*`, pathname)

  let workspaceAzId: string | undefined
  let projectId: string | undefined
  let projectItemId: string | undefined
  let restOfPath: string

  if (leanAppWorkspaceMatch) {
    const { params } = leanAppWorkspaceMatch

    workspaceAzId = params.workspaceAzId
    restOfPath = params['*']!
  } else if (leanAppProjectMatch) {
    const { params } = leanAppProjectMatch

    projectId = params.projectId
    projectItemId = params.itemId
    restOfPath = params['*']!
  } else {
    restOfPath = pathname.slice(1)
  }

  if (!restOfPath) {
    return null
  }

  const renderableOrNativeApp = leanApps
    .filter(isRenderableLeanAppShortOrNative)
    .find(({ config }) => matchPath(`/${config.osRoute}/*`, `/${restOfPath}`))

  // Old CaaS apps use only legacy routes and are directly searched for as
  // legacy apps in getLegacyAppDataFromUrl(), not as lean apps.
  // Here we search for a CaaS app only when project data is set in a special route
  // to redirect the user to the legacy url. See Knowledge Base docs for more details.
  const caasApp =
    projectId && projectItemId
      ? leanApps
          .filter((app): app is CaasAppShort => app.type === LeanAppType.CAAS)
          .find(({ id }) => restOfPath.startsWith(id))
      : null

  const app = renderableOrNativeApp || caasApp || null

  if (!app) {
    return null
  }

  let currentBaseUrl: string = app.config.osRoute

  if (workspaceAzId) {
    currentBaseUrl = `${WORKSPACE_URL_PREFIX}/${workspaceAzId}/${app.config.osRoute}`
  } else if (projectId) {
    currentBaseUrl = `${PROJECT_URL_PREFIX}/${projectId}/${projectItemId}/${app.config.osRoute}`
  }

  return {
    type: MicroAppFromUrlType.LEAN,
    app,
    workspaceAzId,
    projectId,
    projectItemId,
    currentBaseUrl,
  }
}

export const getLoadingAppDataFromUrl = (microAppDataShort: MicroAppDataFromUrlShort): LoadingAppDataFromUrlFull => ({
  type: MicroAppFromUrlType.LOADING,
  app: null,
  project: null,
  projectCanvas: null,
  projectPhase: null,
  projectItem: null,
  workspaceLevels: [],
  currentBaseUrl: microAppDataShort.currentBaseUrl,
})

export const getInvalidAppDataFromUrl = (
  microAppDataShort: MicroAppDataFromUrlShort,
): InvalidAppDataFromUrl<DataFromUrlFull> => ({
  type: MicroAppFromUrlType.INVALID,
  app: null,
  project: null,
  projectCanvas: null,
  projectPhase: null,
  projectItem: null,
  workspaceLevels: [],
  currentBaseUrl: microAppDataShort.currentBaseUrl,
})

// Typescript cannot correctly map union types
// https://github.com/microsoft/TypeScript/issues/33014
// https://github.com/microsoft/TypeScript/issues/35873
export const mapShortAppDataToFull = ({
  microAppDataShort,
  workspaceLevels,
  project,
  projectCanvasLinear,
  projectCanvasFluid,
  projectPhase,
  projectItem,
}: {
  microAppDataShort: MicroAppDataFromUrlShort
  workspaceLevels: WorkspaceLevels
  project: MayBeNull<Project>
  projectCanvasLinear: MayBeNull<ProjectCanvasLinear>
  projectCanvasFluid: MayBeNull<ProjectCanvasFluid>
  projectPhase: MayBeNull<ProjectPhase>
  projectItem: MayBeNull<ProjectCanvasApplication>
}): MicroAppDataFromUrlFull => {
  const { type, app, currentBaseUrl } = microAppDataShort

  const commonData: DataFromUrlFull = {
    project,
    projectCanvas: projectCanvasLinear || projectCanvasFluid,
    projectPhase,
    projectItem,
    workspaceLevels,
    currentBaseUrl,
  }

  switch (type) {
    case MicroAppFromUrlType.LEGACY: {
      return {
        ...commonData,
        type,
        app,
      }
    }

    case MicroAppFromUrlType.LATEST: {
      return {
        ...commonData,
        type,
        app,
      }
    }

    case MicroAppFromUrlType.LEAN: {
      return {
        ...commonData,
        type,
        app,
      }
    }

    case MicroAppFromUrlType.INVALID: {
      return {
        ...commonData,
        type,
        app,
      }
    }
  }
}

export const getAppStableId = (microAppData: MicroAppDataFromUrlFull) => {
  if (microAppData.type === MicroAppFromUrlType.LEGACY) {
    return microAppData.app.config.windowLibraryName
  }

  if (microAppData.type === MicroAppFromUrlType.LATEST) {
    return microAppData.app.code
  }

  return null
}

// TODO: Will be refactored for app instances
export const useMicroAppDataFromUrl = (): MicroAppDataFromUrlShort => {
  const { pathname } = useLocation()
  const { leanApps } = useData()

  const workspaceParts = useMemo(() => getUrlPathWorkspaceParts(pathname), [pathname])

  // Stable local app objects that don't change during app's inner routing.
  // While pathname is updated, local app parts remain the same.
  // This means that apps won't be re-registered in single-spa on any route change.
  const localAppParts = useMemo(() => getLocalAppParts(workspaceParts), [workspaceParts])
  const localAppData = useMemo(() => {
    if (localAppParts?.baseUrl && localAppParts?.libraryType && localAppParts?.bundleUrl) {
      return mapLocalAppData({
        baseUrl: localAppParts.baseUrl,
        libraryType: localAppParts.libraryType,
        bundleUrl: localAppParts.bundleUrl,
        windowLibraryName: localAppParts.windowLibraryName,
      })
    }

    return null
  }, [localAppParts?.baseUrl, localAppParts?.bundleUrl, localAppParts?.libraryType, localAppParts?.windowLibraryName])

  const localLegacyAppParts = useMemo(() => getLocalLegacyAppParts(workspaceParts), [workspaceParts])
  const localLegacyAppData = useMemo(() => {
    if (localLegacyAppParts?.osRoute && localLegacyAppParts?.bundleUrl && localLegacyAppParts?.windowLibraryName) {
      return mapLocalLegacyAppData({
        osRoute: localLegacyAppParts.osRoute,
        bundleUrl: localLegacyAppParts.bundleUrl,
        windowLibraryName: localLegacyAppParts.windowLibraryName,
      })
    }

    return null
  }, [localLegacyAppParts?.bundleUrl, localLegacyAppParts?.osRoute, localLegacyAppParts?.windowLibraryName])

  return useMemo(() => {
    const legacyAppDataFromUrl = getLegacyAppDataFromUrl({ pathname, leanApps })

    if (legacyAppDataFromUrl) {
      return legacyAppDataFromUrl
    }

    const hybridLegacyAppDataFromUrl = getHybridLegacyAppDataFromUrl({ pathname, leanApps, localLegacyAppData })

    if (hybridLegacyAppDataFromUrl) {
      return hybridLegacyAppDataFromUrl
    }

    const leanAppDataFromUrl = getLeanAppDataFromUrl({ pathname, leanApps })

    if (leanAppDataFromUrl) {
      return leanAppDataFromUrl
    }

    const appDataFromUrl = getAppDataFromUrl({ workspaceParts, localAppData })

    if (appDataFromUrl) {
      return appDataFromUrl
    }

    return {
      type: MicroAppFromUrlType.INVALID,
      app: null,
      workspaceLevels: [],
      currentBaseUrl: '',
    }
  }, [leanApps, localAppData, localLegacyAppData, pathname, workspaceParts])
}

const mapLocalAppLibraryTypeToConfig = (type: LocalAppLibraryType): AppLibraryType => {
  switch (type) {
    case LocalAppLibraryType.Window:
      return AppLibraryType.Window
    case LocalAppLibraryType.SystemJS:
      return AppLibraryType.SystemJS
    case LocalAppLibraryType.ESM:
      return AppLibraryType.ESM

    default:
      throw new Error('Unsupported LocalAppLibraryType')
  }
}

const getUrlPathWorkspaceParts = (pathname: string): MayBeNull<WorkspaceParts> => {
  const appWorkspaceMatch = matchPath(`/${WORKSPACE_URL_PREFIX}/:workspaceAzId/*`, pathname)

  let workspaceAzId: string | undefined
  let restOfPath: string

  if (appWorkspaceMatch) {
    const { params } = appWorkspaceMatch

    workspaceAzId = params.workspaceAzId
    restOfPath = params['*']!
  } else {
    restOfPath = pathname.slice(1)
  }

  if (!restOfPath) {
    return null
  }

  return {
    workspaceAzId,
    restOfPath,
  }
}

const getLocalAppParts = (workspaceParts: MayBeNull<WorkspaceParts>): MayBeNull<LocalAppParts> => {
  if (!workspaceParts) {
    return null
  }

  const { restOfPath } = workspaceParts
  const localAppMatch = matchPath(`/${LOCAL_APP_BASE_URL}/:port/:type/:name/*`, `/${restOfPath}`)

  if (!localAppMatch) {
    return null
  }

  const { port, type, name } = localAppMatch.params

  const resolvedLibraryType = Object.values(LocalAppLibraryType).includes(type as LocalAppLibraryType)
    ? (type as LocalAppLibraryType)
    : LocalAppLibraryType.SystemJS

  const libraryType = mapLocalAppLibraryTypeToConfig(resolvedLibraryType)
  const baseUrl = `${LOCAL_APP_BASE_URL}/${port}/${type}/${name}`
  const bundleUrl = `http://localhost:${port}/${name}.js`
  const windowLibraryName = libraryType === AppLibraryType.Window ? name : undefined

  return {
    baseUrl,
    libraryType,
    bundleUrl,
    windowLibraryName,
  }
}

const getLocalLegacyAppParts = (workspaceParts: MayBeNull<WorkspaceParts>): MayBeNull<LocalLegacyAppParts> => {
  if (!workspaceParts) {
    return null
  }

  const { restOfPath } = workspaceParts
  const localAppMatch = matchPath(
    `/${LOCAL_LEGACY_APP_BASE_URL}/:port/:jsBundleName/:windowLibraryName/*`,
    `/${restOfPath}`,
  )

  if (!localAppMatch) {
    return null
  }

  const { port, jsBundleName, windowLibraryName = 'localApp' } = localAppMatch.params

  const osRoute = `${LOCAL_LEGACY_APP_BASE_URL}/${port}/${jsBundleName}/${windowLibraryName}`
  const bundleUrl = `http://localhost:${port}/${jsBundleName}.js`

  return {
    osRoute,
    bundleUrl,
    windowLibraryName,
  }
}

const mapLocalAppData = ({ baseUrl, libraryType, bundleUrl, windowLibraryName }: LocalAppParts): AppData => ({
  name: LOCAL_APP_NAME,
  code: LOCAL_APP_CODE,
  baseUrl,
  libraryType,
  bundleUrl,
  workspaceLevels: Object.values(HierarchyLevelType),
  windowLibraryName,
})

const mapLocalLegacyAppData = ({ osRoute, bundleUrl, windowLibraryName }: LocalLegacyAppParts): CaasAppShort => ({
  type: LeanAppType.CAAS,
  id: LOCAL_APP_CODE,
  name: LOCAL_APP_NAME,
  logo: null,
  description: null,
  config: {
    osRoute,
    bundleUrl,
    windowLibraryName,
    containerId: LOCAL_APP_CODE,
    requiredHierarchy: Object.values(HierarchyLevelType),
    viewPermissionName: null,
  },
})

export const getValidatedProjectData = ({
  microAppDataShort,
  projectCanvasLinear,
  projectCanvasFluid,
}: {
  microAppDataShort: MicroAppDataFromUrlShort
  projectCanvasLinear: MayBeNull<ProjectCanvasLinear>
  projectCanvasFluid: MayBeNull<ProjectCanvasFluid>
}): {
  isValid: boolean
  projectPhase: MayBeNull<ProjectPhase>
  projectItem: MayBeNull<ProjectCanvasApplication>
} => {
  const getResult = (
    isValid: boolean,
    projectPhase: MayBeNull<ProjectPhase> = null,
    projectItem: MayBeNull<ProjectCanvasApplication> = null,
  ) => ({
    isValid,
    projectPhase,
    projectItem,
  })

  const isLeanApp = microAppDataShort.type === MicroAppFromUrlType.LEAN
  const isLegacyApp = microAppDataShort.type === MicroAppFromUrlType.LEGACY

  if (!isLeanApp && !isLegacyApp) {
    return getResult(true)
  }

  if (projectCanvasLinear) {
    for (const projectPhase of projectCanvasLinear.phases) {
      for (const phaseItem of projectPhase.phaseItems) {
        if (isProjectActivityData(phaseItem.item)) {
          for (const activityChildItem of phaseItem.item.items) {
            if (isProjectAppActive(activityChildItem.application, microAppDataShort)) {
              return getResult(true, projectPhase, activityChildItem.application)
            }
          }
        } else if (isProjectAppActive(phaseItem.item, microAppDataShort)) {
          return getResult(true, projectPhase, phaseItem.item)
        }
      }
    }

    return getResult(false)
  } else if (projectCanvasFluid) {
    for (const phaseItemData of projectCanvasFluid.items) {
      if (isProjectActivityData(phaseItemData)) {
        for (const activityChildItem of phaseItemData.items) {
          if (isProjectAppActive(activityChildItem.application, microAppDataShort)) {
            return getResult(true, null, activityChildItem.application)
          }
        }
      } else if (isProjectAppActive(phaseItemData, microAppDataShort)) {
        return getResult(true, null, phaseItemData)
      }
    }

    return getResult(false)
  }

  return getResult(true)
}

export const isRequiredWorkspaceLevel = ({
  workspaceAzId,
  microAppDataShort,
  mapping,
}: {
  workspaceAzId: MayBeNull<string>
  microAppDataShort: MicroAppDataFromUrlShort
  mapping: NavigationTreeMapping
}) => {
  const currentWorkspaceLevel = !workspaceAzId
    ? HierarchyLevelType.Tenant
    : (mapping[workspaceAzId].type as HierarchyLevelType)
  let requiredLevels: MayBeNull<HierarchyLevelType[]> = null

  if (microAppDataShort.type === MicroAppFromUrlType.LEGACY) {
    requiredLevels = microAppDataShort.app.config.requiredHierarchy
  } else if (microAppDataShort.type === MicroAppFromUrlType.LEAN && microAppDataShort.app.type === LeanAppType.NATIVE) {
    requiredLevels = microAppDataShort.app.config.requiredHierarchy
  } else if (microAppDataShort.type === MicroAppFromUrlType.LATEST) {
    requiredLevels = microAppDataShort.app.workspaceLevels
  }

  return !requiredLevels || !requiredLevels.length || requiredLevels.includes(currentWorkspaceLevel)
}
