import { useIntercomState } from 'components/intercom/utils'
import { useZendeskWidgetPresence } from 'components/zendesk'
import { environment } from 'environment'
import { usePublicData } from 'providers/publicData/PublicDataProvider'
import { HostType } from 'types/tenants/tenant'

export const useWidgetsState = () => {
  const { currentTenantPublic, tenantType } = usePublicData()
  const [intercomActive] = useIntercomState()
  const [zendeskActive] = useZendeskWidgetPresence()

  const intercomEnabled =
    !!environment.INTERCOM_APP_ID &&
    (currentTenantPublic?.supportService === 'intercom' || tenantType === HostType.GENERIC)
  const zendeskEnabled = !!environment.ZENDESK_KEY && currentTenantPublic?.supportService === 'zendesk'

  return {
    intercomEnabled,
    intercomActive: intercomEnabled && intercomActive,
    zendeskEnabled,
    zendeskActive,
  }
}
