import { WppIconWarning, WppTypography } from '@platform-ui-kit/components-library-react'
import { LeanAppsMappingMiroBoardInstanceNode } from '@wpp-open/core'
import { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { CriticalError } from 'components/renderError'
import { SvgForbidden } from 'components/svg/renderError/SvgForbidden'
import styles from 'pages/leanApp/miroBoardAppRenderer/MiroBoardAppRenderer.module.scss'
import { useData } from 'providers/data/DataProvider'
import { MiroConfigStatus, MiroConfigStatusResponse } from 'types/miro'
import { mailto } from 'utils/links'

interface Props {
  miroConfigStatus: MiroConfigStatusResponse
  instanceId: string
}

export const MiroIssuesState = ({ miroConfigStatus, instanceId }: Props) => {
  const { t } = useTranslation()
  const [isCriticalError] = useState(false)
  const isMiroConfigSet = miroConfigStatus.status === MiroConfigStatus.Valid
  const {
    navigationTree: { leanAppsMapping },
  } = useData()

  const email = (leanAppsMapping[instanceId] as LeanAppsMappingMiroBoardInstanceNode).owner

  if (isCriticalError) {
    return <CriticalError />
  }

  return (
    <Flex
      className={styles.fullHeight}
      direction="column"
      justify="center"
      align="center"
      gap={12}
      data-testid="miro-issue-page"
    >
      {isMiroConfigSet ? <SvgForbidden /> : <WppIconWarning />}
      <WppTypography type="xl-heading" data-testid="warning-title">
        {isMiroConfigSet
          ? t('os.lean_app_page.miro_issues_state.no_token_title')
          : t('os.lean_app_page.miro_issues_state.no_config_title')}
      </WppTypography>
      <WppTypography type="s-body" className={styles.issueDescription}>
        <Trans
          i18nKey={
            isMiroConfigSet
              ? 'os.lean_app_page.miro_issues_state.no_token_description'
              : 'os.lean_app_page.miro_issues_state.no_config_description'
          }
          values={{
            email,
          }}
          components={[
            <a key="0" href={mailto(email)} className={styles.email}>
              email
            </a>,
          ]}
        />
      </WppTypography>
    </Flex>
  )
}
