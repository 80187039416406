import styles from 'components/svg/common.module.scss'

export const SvgNoTenants = (props: JSX.IntrinsicElements['svg']) => (
  <svg width="188" height="167" viewBox="0 0 188 167" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g filter="url(#filter0_f_35530_8031)">
      <ellipse cx="93.8737" cy="131.818" rx="71.8332" ry="12.9414" className={styles.fillPrimary300} />
    </g>
    <path
      d="M99 148.491C137.66 148.491 169 117.151 169 78.4911C169 39.8312 137.66 8.49115 99 8.49115C60.3401 8.49115 29 39.8312 29 78.4911C29 117.151 60.3401 148.491 99 148.491Z"
      className={styles.fillPrimary100}
    />
    <path
      d="M50.5517 39H146.448C147.39 39 148.294 39.4816 148.96 40.3389C149.626 41.1962 150 42.359 150 43.5714V66.4286C150 67.641 149.626 68.8038 148.96 69.6611C148.294 70.5184 147.39 71 146.448 71H50.5517C49.6097 71 48.7064 70.5184 48.0403 69.6611C47.3742 68.8038 47 67.641 47 66.4286V43.5714C47 42.359 47.3742 41.1962 48.0403 40.3389C48.7064 39.4816 49.6097 39 50.5517 39V39Z"
      className={styles.fillWhite}
    />
    <path
      d="M50.5517 79H146.448C147.39 79 148.294 79.4816 148.96 80.3389C149.626 81.1962 150 82.359 150 83.5714V106.429C150 107.641 149.626 108.804 148.96 109.661C148.294 110.518 147.39 111 146.448 111H50.5517C49.6097 111 48.7064 110.518 48.0403 109.661C47.3742 108.804 47 107.641 47 106.429V83.5714C47 82.359 47.3742 81.1962 48.0403 80.3389C48.7064 79.4816 49.6097 79 50.5517 79V79Z"
      className={styles.fillWhite}
    />
    <path
      d="M50.5517 119H146.448C147.39 119 148.294 119.482 148.96 120.339C149.626 121.196 150 122.359 150 123.571V146.429C150 147.641 149.626 148.804 148.96 149.661C148.294 150.518 147.39 151 146.448 151H50.5517C49.6097 151 48.7064 150.518 48.0403 149.661C47.3742 148.804 47 147.641 47 146.429V123.571C47 122.359 47.3742 121.196 48.0403 120.339C48.7064 119.482 49.6097 119 50.5517 119V119Z"
      className={styles.fillWhite}
    />
    <path
      d="M111 55C111 48.3726 105.627 43 99 43C92.3726 43 87 48.3726 87 55C87 61.6274 92.3726 67 99 67C105.627 67 111 61.6274 111 55Z"
      className={styles.fillPrimary300}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M162 45.2857C163.262 45.2857 164.286 44.2624 164.286 43C164.286 41.7376 163.262 40.7143 162 40.7143C160.738 40.7143 159.714 41.7376 159.714 43C159.714 44.2624 160.738 45.2857 162 45.2857ZM162 47C164.209 47 166 45.2091 166 43C166 40.7909 164.209 39 162 39C159.791 39 158 40.7909 158 43C158 45.2091 159.791 47 162 47Z"
      className={styles.fillPrimary300}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M39 26.2857C40.2624 26.2857 41.2857 25.2624 41.2857 24C41.2857 22.7376 40.2624 21.7143 39 21.7143C37.7376 21.7143 36.7143 22.7376 36.7143 24C36.7143 25.2624 37.7376 26.2857 39 26.2857ZM39 28C41.2091 28 43 26.2091 43 24C43 21.7909 41.2091 20 39 20C36.7909 20 35 21.7909 35 24C35 26.2091 36.7909 28 39 28Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M32.2985 87.0306C34.0586 86.7438 35.4816 88.5253 34.8456 90.2194L33.9838 92.5153C33.3544 94.1918 31.1761 94.5261 30.0518 93.1186L28.5283 91.2113C27.4041 89.8038 28.1712 87.703 29.9131 87.4192L32.2985 87.0306Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M161.067 105.833C160.656 104.141 162.209 102.63 163.894 103.08L167.289 103.986C169.014 104.447 169.582 106.605 168.306 107.847L165.737 110.347C164.461 111.589 162.312 110.97 161.892 109.239L161.067 105.833Z"
      className={styles.fillPrimary300}
    />
    <path
      d="M112.34 5.60414C112.917 3.46529 116.083 3.46529 116.66 5.60414L116.962 6.72508C117.171 7.50148 117.815 8.10048 118.631 8.27684L119.26 8.41279C121.58 8.91448 121.58 12.0855 119.26 12.5872L118.631 12.7232C117.815 12.8995 117.171 13.4985 116.962 14.2749L116.66 15.3959C116.083 17.5347 112.917 17.5347 112.34 15.3959L112.038 14.2749C111.829 13.4985 111.185 12.8995 110.369 12.7232L109.74 12.5872C107.42 12.0855 107.42 8.91448 109.74 8.41279L110.369 8.27684C111.185 8.10048 111.829 7.50148 112.038 6.72508L112.34 5.60414Z"
      className={styles.fillPrimary400}
    />
    <rect x="87" y="83" width="24" height="24" className={styles.fillPrimary200} />
    <path d="M99 123L111 147H87L99 123Z" className={styles.fillPrimary100} />
    <defs>
      <filter
        id="filter0_f_35530_8031"
        x="0.267532"
        y="97.1031"
        width="187.212"
        height="69.4289"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="10.8865" result="effect1_foregroundBlur_35530_8031" />
      </filter>
    </defs>
  </svg>
)
