import { lazy } from 'react'

import { TENANT_SUBDOMAIN_WPP } from 'constants/common'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'

const WppOpen = lazy(() => import('pages/wppOpenDashboard/WppOpenDashboard'))
const Home = lazy(() => import('pages/home/Home'))

export const HomePageElement = () => {
  const { currentTenant } = useTenantAndUserData()

  switch (currentTenant.subdomain) {
    case TENANT_SUBDOMAIN_WPP:
      return <WppOpen />
    default:
      return <Home />
  }
}
