import {
  Tenant,
  TenantConfigNavigationType,
  LeanAppType,
  MayBeNull,
  NavigationTreeNode,
  ContainerNodeType,
  HierarchyContainerNodeId,
  HierarchyNode,
  HierarchyLevelType,
  LeanAppsMappingNode,
} from '@wpp-open/core'
import qs from 'qs'

import { isHybridLegacyApp } from 'legacy/utils/apps'
import { PROJECT_URL_PREFIX, WORKSPACE_URL_PREFIX } from 'providers/appState/utils'
import { CustomWidgetApp, LeanApp, LeanAppShort, MiroBoardApp } from 'types/apps/leanApps'

export const getAppUrl = (osRoute: string, workspaceAzId?: string) => {
  const workspacePart = workspaceAzId ? `/${WORKSPACE_URL_PREFIX}/${workspaceAzId}` : ''

  return `${workspacePart}/${osRoute}`
}

interface Params {
  workspaceAzId?: MayBeNull<string>
  projectId?: MayBeNull<string>
  projectItemId?: MayBeNull<string>
  instanceId?: string
  leanApp: LeanAppShort | Exclude<LeanApp, MiroBoardApp | CustomWidgetApp> | LeanAppsMappingNode
}

export const getLeanAppUrl = (params: Params) => {
  const { leanApp, projectId, projectItemId, workspaceAzId, instanceId } = params

  if (leanApp.type === LeanAppType.LINK_DETACHED) {
    return leanApp.config.url
  }

  const isProjectSet = !!projectId && !!projectItemId
  const projectPart = isProjectSet ? `/${PROJECT_URL_PREFIX}/${projectId}/${projectItemId}` : ''
  const workspacePart = workspaceAzId ? `/${WORKSPACE_URL_PREFIX}/${workspaceAzId}` : ''

  const baseUrl = `${projectPart || workspacePart}/${leanApp.config.osRoute}`

  if (leanApp.type === LeanAppType.CAAS) {
    if (isHybridLegacyApp(leanApp)) {
      return baseUrl
    } else if (!isProjectSet) {
      return leanApp.config.osRoute.replace(':brandId', workspaceAzId || '')
    } else {
      return `${projectPart}/${leanApp.id}`
    }
  }

  if (leanApp.type === LeanAppType.MIRO_BOARD) {
    return `${baseUrl}?${qs.stringify({ instanceId })}`
  }

  if (leanApp.type === LeanAppType.MIRO_BOARD_INSTANCE) {
    return `${baseUrl}?${qs.stringify({ instanceId: leanApp.id })}`
  }

  return baseUrl
}

export const getNavigationTypeFlags = ({
  type,
  currentTenant,
}: {
  currentTenant: Tenant
  type: TenantConfigNavigationType
}) => currentTenant.config.navigation.find(configNavigation => configNavigation.type === type)!.flags

export const isNavigationTypeEnabled = ({
  type,
  currentTenant,
}: {
  currentTenant: Tenant
  type: TenantConfigNavigationType
}) => getNavigationTypeFlags({ type, currentTenant }).enabled

export const formatNavigationNodeId = (type: NavigationTreeNode['type'], id: string) => `${type}:${id}`
export const formatNavigationNodeIdFromNode = (node: NavigationTreeNode) =>
  formatNavigationNodeId(node.type, node.type === ContainerNodeType ? HierarchyContainerNodeId : node.azId!)

export const isHierarchyNode = (node: NavigationTreeNode): node is HierarchyNode =>
  Object.values(HierarchyLevelType).includes(node.type as HierarchyLevelType)
