import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/landing/components/benefitSection/BenefitSection.module.scss'
import { Button } from 'pages/landing/components/button/Button'
import { landingRoutes } from 'pages/landing/const'

export interface BenefitItem {
  text: string
}
export interface Benefit {
  title?: string
  subTitle?: string
  description?: string
  list: BenefitItem[]
  image?: string
  action?: {
    title: string
    link: string
  }
}
interface Props extends PropsWithChildren {
  benefit: Benefit
  reverse?: Boolean
  imageClassName?: string
  size?: 'small' | 'medium'
  imageTestId?: string
}

enum Sizes {
  small = 's-body',
  medium = 'l-body',
}

export const BenefitSection = ({ benefit, reverse = false, size = 'small', imageClassName, imageTestId }: Props) => {
  const { title, subTitle, description, list = [], image = '', action } = benefit

  const getFontType = () => {
    return size === 'small' ? Sizes.small : Sizes.medium
  }

  return (
    <Flex direction="row" gap={80} align="center">
      {!reverse && <img src={image} className={clsx(styles.image, imageClassName)} data-testid={imageTestId} />}

      <Flex direction="column" gap={24} className={styles.wrapper}>
        {!!title && <WppTypography type="3xl-heading">{title}</WppTypography>}
        {!!subTitle && <WppTypography type="l-body">{subTitle}</WppTypography>}
        {!!description && <WppTypography type={getFontType()}>{description}</WppTypography>}

        <ul className={styles.list}>
          {list.map(benefitsListItem => (
            <li key={benefitsListItem.text}>
              <WppTypography type={getFontType()}>{benefitsListItem.text}</WppTypography>
            </li>
          ))}
        </ul>

        {!!action && (
          <Button size="m" component={Link} to={`${landingRoutes.root}/${action.link}`}>
            <WppTypography type="l-midi">{action.title}</WppTypography>
          </Button>
        )}
      </Flex>

      {reverse && <img src={image} className={clsx(styles.image, imageClassName)} data-testid={imageTestId} />}
    </Flex>
  )
}
