import { WppActionButton, WppIconChevron, WppMenuContext } from '@platform-ui-kit/components-library-react'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { BreadcrumbsContainer } from 'layout/header/breadcrumbs/container/BreadcrumbsContainer'
import { BreadcrumbItem } from 'layout/header/breadcrumbs/item/BreadcrumbItem'
import { ProjectLinearBreadcrumbsAppsColumn } from 'layout/header/breadcrumbs/projectLinear/appsColumn/ProjectLinearBreadcrumbsAppsColumn'
import { ProjectLinearBreadcrumbsFullMenu } from 'layout/header/breadcrumbs/projectLinear/fullMenu/ProjectLinearBreadcrumbsFullMenu'
import styles from 'layout/header/breadcrumbs/projectLinear/ProjectLinearBreadcrumbs.module.scss'
import { useAppState } from 'providers/appState/AppStateProvider'
import { BreadcrumbItemProject } from 'types/appState/breadcrumbs'
import { ProjectCanvasLinear } from 'types/projects/projectCanvas'

interface Props {
  items: BreadcrumbItemProject[]
}

export const ProjectLinearBreadcrumbs = ({ items }: Props) => {
  const navigate = useNavigate()
  const { microAppData } = useAppState()

  const { project, projectCanvas, projectPhase } = microAppData
  const initialPhase = projectPhase!
  const collapsibleItems = useMemo(() => items.slice(0, -1), [items])
  const lastItem = items.at(-1)

  const [selectedPhase, setSelectedPhase] = useState(initialPhase)

  const linearCanvas = projectCanvas as ProjectCanvasLinear
  const hasSinglePhase = linearCanvas.phases.length === 1

  useEffect(() => {
    setSelectedPhase(initialPhase)
  }, [initialPhase])

  const handleItemClick = ({ link }: BreadcrumbItemProject) => {
    if (link) {
      navigate(link)
    }
  }

  return (
    <BreadcrumbsContainer items={collapsibleItems} onItemClick={handleItemClick}>
      {!!lastItem && (
        <WppMenuContext
          className={styles.projectMenuWrapper}
          externalClass={styles.projectMenu}
          listWidth={hasSinglePhase ? '336px' : '600px'}
          dropdownConfig={{
            offset: [hasSinglePhase ? -26 : -290, 0], // Align with apps column
            maxWidth: 'initial',
            onHidden: () => {
              setSelectedPhase(initialPhase)
            },
          }}
        >
          <BreadcrumbItem slot="trigger-element" label={lastItem.label}>
            <WppActionButton variant="secondary">
              <WppIconChevron slot="icon-start" direction="down" />
            </WppActionButton>
          </BreadcrumbItem>

          {hasSinglePhase ? (
            <ProjectLinearBreadcrumbsAppsColumn projectId={project!.id} selectedPhase={selectedPhase} />
          ) : (
            <ProjectLinearBreadcrumbsFullMenu
              projectId={project!.id}
              linearCanvas={linearCanvas}
              selectedPhase={selectedPhase}
              setSelectedPhase={setSelectedPhase}
            />
          )}
        </WppMenuContext>
      )}
    </BreadcrumbsContainer>
  )
}
