import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/landing/termsPage/Terms.module.scss'
import { mailto } from 'utils/links'

export const Terms = () => {
  return (
    <Flex direction="column" gap={40} className={styles.container}>
      <WppTypography type="4xl-display">Terms & conditions</WppTypography>

      <Flex gap={16} direction="column">
        <WppTypography type="2xl-heading">Conditions of use</WppTypography>

        <WppTypography type="m-body">
          By accessing this website you agree to be bound by these Terms & conditions (“terms”), please read them
          carefully. If you do not agree to be bound by these terms you should not access or view this website.
        </WppTypography>

        <ol className={styles.list}>
          <li>
            <WppTypography type="m-body">
              The information contained in this website is intended for general information purposes only. WPP has made
              all reasonable efforts to ensure that the information on this website is accurate at the time of
              inclusion, however there may be inaccuracies and occasional errors for which WPP apologises.
            </WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">
              WPP makes no representations or warranties about the information provided through this website, including
              any hypertext links to any website or other items used either directly or indirectly from this website.
              WPP accepts no liability for any inaccuracies or omissions in this website and any decisions based on
              information contained in WPP’s websites are the sole responsibility of the visitor.
            </WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">
              The information and images contained within this website are © Copyright WPP plc. We permit you to make
              copies of this website as necessary incidental acts during your viewing of it; and you may take a print
              for your personal use of so much of the site as is reasonable for private purposes. You may not frame this
              site nor link to a page other than the home page without our express permission in writing. Please note:
              the illustrations used in WPP’s Annual and Interim Reports and Corporate Responsibility Reports are
              subject to strict copyright arrangements and therefore should not be copied or reproduced in any form.
            </WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">
              You shall not use this website for any illegal purposes and in particular agree that you shall not send,
              use, copy, post or allow any posting which is defamatory or obscene within the meaning of the Obscene
              Publications Act or which is abusive, indecent or in breach of the privacy of any person. You agree not to
              send any unsolicited promotional or advertising material, spam or similar materials or any volume messages
              that may interfere with the operation of this website or with the enjoyment of this website by other
              visitors.
            </WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">
              WPP reserves the right at any time and without notice to enhance, modify, alter, suspend or permanently
              discontinue all or any part of this website and to restrict or prohibit access to it.
            </WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">
              You hereby agree to indemnify WPP against any costs, claims, losses and damages (including legal fees)
              incurred by or awarded against WPP as a result of your misuse of this website or your breach of these
              terms.
            </WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">
              If you are in breach of these terms or of WPP’s Privacy Policy WPP may as its option, suspend or block
              your access to this website and refuse to provide you with any further access to it.
            </WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">
              This website is provided to you free of charge, and neither WPP nor any of its subsidiary or affiliated
              companies accept any liability to you (except in the case of personal injury or death caused by its
              negligence or for fraud or as required by law) whether in contract, tort (including negligence) or
              otherwise, arising out of it in connection with this website. WPP accepts no liability for any direct,
              special, indirect or consequential damages, or any other damages of whatsoever kind resulting from
              whatever cause through the use of any information obtained either directly or indirectly from this
              website. Your sole remedy is to discontinue using this website.
            </WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">These terms may be amended by WPP from time to time.</WppTypography>
          </li>
          <li>
            <WppTypography type="m-body">
              Your use of this website and downloads from it, and the operation of these terms & conditions, shall be
              governed in accordance with the laws of England and Wales. The English courts shall have exclusive
              jurisdiction over any dispute arising out of your use of this website.
            </WppTypography>
          </li>
        </ol>

        <WppTypography type="m-body">
          In the event that any or any part of the terms contained in these terms & conditions shall be determined by
          any competent authority to be invalid, unlawful or unenforceable to any extent, such term shall to that extent
          be severed from the remaining terms which shall continue to be valid and enforceable to the fullest extent
          permitted by law.
        </WppTypography>
      </Flex>

      <Flex gap={16} direction="column">
        <WppTypography type="2xl-heading">Financial Services</WppTypography>

        <WppTypography type="m-body">
          Certain statements provided through this website may be forward-looking statements as that term is defined in
          the United States Private Securities Litigation Reform Act of 1995. Such forward-looking statements involve
          risks and uncertainties that could cause actual results to differ materially from those expressed or implied
          by such statements.
        </WppTypography>

        <WppTypography type="m-body">
          No information contained in this website constitutes or would be deemed to constitute an invitation to invest
          or otherwise deal in the shares or ADRs of WPP plc or an invitation by or on behalf of WPP plc or any of its
          subsidiaries to enter into a contract with you. Please read our Financial Disclaimer in full.
        </WppTypography>
      </Flex>

      <Flex gap={16} direction="column">
        <WppTypography type="2xl-heading">Privacy policy</WppTypography>

        <WppTypography type="m-body">
          WPP respects the privacy of visitors and users of this website, and will only collect personally identifiable
          data, such as your name or email address, when it is voluntarily submitted to us at this website. Please read
          our&nbsp;
          <Link to="https://www.wpp.com/en/privacy-policy" target="_blank" className={styles.link}>
            Privacy Policy
          </Link>
          &nbsp;in full.
        </WppTypography>

        <WppTypography type="m-body">
          References to “WPP”, “we”, “us” and “our” are references to WPP plc and its affiliates.
        </WppTypography>

        <WppTypography type="m-body">“WPP” is a trademark of WPP plc.</WppTypography>

        <WppTypography type="m-body">
          Please send any enquiries by email to&nbsp;
          <Link to={mailto('enquiries@wpp.com')} className={styles.link}>
            enquiries@wpp.com.
          </Link>
        </WppTypography>

        <WppTypography type="m-body">
          WPP plc. Registered in Jersey. Registered Office: 22 Grenville Street, St Helier, Jersey JE4 8PX, registered
          number 111714.
        </WppTypography>
      </Flex>
    </Flex>
  )
}
