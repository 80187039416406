import { useEffect, useMemo } from 'react'
import { createGlobalState } from 'react-use'

import { useIntercomData } from 'api/users/queries/useIntercomData'
import { useWidgetsState } from 'hooks/useWidgetsState'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { join } from 'utils/common'

export const useIntercomState = createGlobalState(false)

export const openIntercomWidget = () => {
  window.Intercom?.('show')
}

export const clearIntercomUserData = () => {
  window.Intercom?.('shutdown')
}

export const openIntercomSurvey = () => {
  window.Intercom?.('startSurvey', 223875)
}

export const useIntercomUser = () => {
  const { intercomEnabled } = useWidgetsState()
  const [loaded] = useIntercomState()
  const {
    userDetails: { email, createdAt: created_at, firstname, lastname },
  } = useTenantAndUserData()

  const { data: userHash } = useIntercomData({ enabled: intercomEnabled })

  const params = useMemo(() => {
    if (userHash && email && created_at) {
      return {
        email,
        created_at,
        name: join([firstname, lastname], ' '),
        user_hash: userHash,
      }
    }
    return null
  }, [created_at, email, userHash, firstname, lastname])

  useEffect(() => {
    if (intercomEnabled && loaded && params) {
      window.Intercom?.('update', {
        ...params,
        hide_default_launcher: false,
      })
    } else {
      window.Intercom?.('update', {
        hide_default_launcher: !intercomEnabled,
      })
    }
  }, [intercomEnabled, params, loaded])
}
