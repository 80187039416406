import {
  WppActionButton,
  WppButton,
  WppDivider,
  WppTypography,
  WppIconMail,
} from '@platform-ui-kit/components-library-react'
import { AnalyticsActionType } from '@wpp-open/core'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useDefaultIdpsApi } from 'api/tenant/queries/useDefaultIdpsApi'
import { useTenantIdpsApi } from 'api/tenant/queries/useTenantIdpsApi'
import { Flex } from 'components/common/flex/Flex'
import { openIntercomWidget } from 'components/intercom/utils'
import { handleOpenWidget } from 'components/zendesk'
import { WIDGET_PAGE_CODES } from 'components/zendesk/utils/constants'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'
import { useWidgetsState } from 'hooks/useWidgetsState'
import { AuthLayout } from 'layout/authLayout/AuthLayout'
import { showSignUpSideModal } from 'pages/login/components/signUp/SignUpSideModal'
import styles from 'pages/login/Login.module.scss'
import { getIdpsPayload, getTenantFullIDPS } from 'pages/login/utils'
import { usePublicData } from 'providers/publicData/PublicDataProvider'
import { IdentityProvider } from 'types/auth/identityProvider'
import { HostType } from 'types/tenants/tenant'
import { trackAnalytics } from 'utils/analytics'
import { EVENTS } from 'utils/events'

export interface LoginProps {
  className?: string
  login: (identityProvider?: IdentityProvider) => void
}

export const Login = ({ className, login }: LoginProps) => {
  const { t } = useTranslation()
  const { logoOriginal, id } = useTenantLogoAndMetaData()
  const { tenantType } = usePublicData()
  const { data: tenantIdps } = useTenantIdpsApi({
    params: {
      // TODO: Refactor this, id is nullable
      tenantId: id!,
    },
  })
  const { data: defaultIdps } = useDefaultIdpsApi()
  const { intercomEnabled } = useWidgetsState()

  const { idps: filteredTenantIdps, email, signUp } = getTenantFullIDPS(defaultIdps, tenantIdps)

  const isGeneric = tenantType === HostType.GENERIC

  useEffect(() => {
    trackAnalytics({
      type: AnalyticsActionType.page,
      payload: EVENTS.TENANT_LOGIN_PAGE.PAGE.LOGIN,
    })
  }, [])

  return (
    <AuthLayout className={className}>
      <Flex className={styles.root} direction="column" align="center" justify="center" gap={56}>
        <img className={styles.tenantLogo} alt="Tenant logo" src={logoOriginal} />

        <Flex className={styles.content} direction="column" align="center">
          <WppTypography className={styles.signInLabel} type="2xl-heading">
            {t('os.login.sing_in')}
          </WppTypography>

          <Flex as="ul" direction="column" gap={24}>
            {filteredTenantIdps.map(idp => {
              const idpEventPayload = getIdpsPayload(idp.alias)
              return (
                <li key={idp.alias}>
                  <WppButton
                    data-testid={idp.alias}
                    className={styles.identityProvider}
                    variant="secondary"
                    onClick={() => {
                      login(idp.legacyIDP)
                      idpEventPayload &&
                        trackAnalytics({
                          type: AnalyticsActionType.page,
                          payload: idpEventPayload,
                        })
                    }}
                  >
                    {!!idp.logo && (
                      <img
                        slot="icon-start"
                        alt={`${idp.alias} icon`}
                        src={idp.logo?.url || ''}
                        width="20"
                        height="20"
                      />
                    )}
                    {idp.title}
                  </WppButton>
                </li>
              )
            })}
          </Flex>
          {!isGeneric && (
            <>
              {!!filteredTenantIdps.length && !!email ? (
                <>
                  <Flex className={styles.dividerContainer} align="center" gap={8}>
                    <WppDivider className={styles.divider} />
                    <WppTypography type="xs-midi" className={styles.dividerText}>
                      {t('os.common.or')}
                    </WppTypography>
                    <WppDivider className={styles.divider} />
                  </Flex>

                  <WppActionButton
                    data-testid={email.alias}
                    variant="secondary"
                    className={styles.identityProvider}
                    onClick={() => {
                      login()
                      trackAnalytics({
                        type: AnalyticsActionType.page,
                        payload: EVENTS.TENANT_LOGIN_PAGE.ACTIONS.EMAIL,
                      })
                    }}
                  >
                    {email.title}
                  </WppActionButton>
                </>
              ) : (
                !!email && (
                  <WppButton
                    data-testid={email.alias}
                    className={styles.identityProvider}
                    variant="secondary"
                    onClick={() => {
                      login()
                      trackAnalytics({
                        type: AnalyticsActionType.page,
                        payload: EVENTS.TENANT_LOGIN_PAGE.ACTIONS.EMAIL,
                      })
                    }}
                  >
                    <WppIconMail slot="icon-start" />
                    {email.title}
                  </WppButton>
                )
              )}
            </>
          )}
        </Flex>

        <Flex direction="column" gap={28}>
          <Flex justify="center" gap={24}>
            <button
              className={styles.link}
              onClick={() => {
                if (intercomEnabled) {
                  openIntercomWidget()
                } else {
                  handleOpenWidget({ appOrPageCode: WIDGET_PAGE_CODES.LOGIN })
                }
              }}
            >
              <WppTypography type="s-body" data-testid="get-support">
                {t('os.help.get_support')}
              </WppTypography>
            </button>

            {id && signUp && (
              <button
                className={styles.link}
                data-testid="sign-up"
                onClick={() => {
                  showSignUpSideModal({ tenantId: id, title: signUp.title })

                  trackAnalytics({
                    type: AnalyticsActionType.page,
                    payload: EVENTS.TENANT_LOGIN_PAGE.ACTIONS.SIGN_UP,
                  })
                }}
              >
                <WppTypography type="s-body">{signUp.title}</WppTypography>
              </button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </AuthLayout>
  )
}
