import { BundleUrlConfigs, ApiUrlConfigs, Environment, LegacyBoxEnv, OsDeploymentEnvironment } from 'environment/common'

/*
  Dev only interfaces. Not a full list. Feel free to extend.
*/
export enum TenantSubdomain {
  Choreograph = 'ch',
  Essence = 'es',
  Greenhouse = 'gh',
  GroupM = 'gm',
  Mediacom = 'mc',
  Mindshare = 'ms',
  Msix = 'msix',
  Wavemaker = 'wm',
  WppMediaServices = 'wms',
  WppOpen = 'wpp',
  Mercier = 'mercier',
  TerminalSix = 'terminalsix',
  Pitch = 'wpppitchos',
}

const getLocalDevKeycloakConfig = (
  legacyBoxEnv: LegacyBoxEnv,
): Pick<Environment, 'KEYCLOAK_AUTHORITY' | 'KEYCLOAK_CLIENT_ID'> => {
  switch (legacyBoxEnv) {
    case LegacyBoxEnv.Staging: {
      return {
        KEYCLOAK_AUTHORITY: 'https://authenticate.choreograph.com/auth/realms/az-beta',
        KEYCLOAK_CLIENT_ID: 'box-staging-fe',
      }
    }

    default: {
      return {
        KEYCLOAK_AUTHORITY: 'https://authenticate.choreograph.com/auth/realms/az-beta',
        KEYCLOAK_CLIENT_ID: 'box-dev-fe',
      }
    }
  }
}

const getLocalDevGenericTenantUrl = (
  legacyBoxEnv: LegacyBoxEnv,
  deploymentEnv: OsDeploymentEnvironment,
): Pick<Environment, 'GENERIC_TENANT_URL'> => {
  switch (legacyBoxEnv) {
    case LegacyBoxEnv.Staging: {
      return {
        GENERIC_TENANT_URL: 'https://wpp-stage.os-dev.io/',
      }
    }

    default: {
      return {
        GENERIC_TENANT_URL: `https://wpp-${deploymentEnv}.os-dev.io`,
      }
    }
  }
}

const getLocalDevBundleUrls = (
  legacyBoxEnv: LegacyBoxEnv,
  deploymentEnv: OsDeploymentEnvironment,
): BundleUrlConfigs => {
  const commonConfig = {
    NEWS_BUNDLE_URL: 'https://wpp-app-news-fe-ch-hulk.os-dev.io',
    ORCHESTRATION_BUNDLE_URL: `https://wpp-app-orchestration-fe-ch-${deploymentEnv}.os-dev.io`,
    DEVHUB_BUNDLE_URL: 'https://devhub-fe-ch-hulk.os-dev.io/',
    THEME_BUILDER_BUNDLE_URL: 'https://wpp-app-theme-builder-ch-hulk.os-dev.io/',
    FUNDING_PROCESS_BUNDLE_URL: 'https://investmenthub-fe-ch-hulk.os-dev.io/main.js',
    BENEFITS_ROADMAP_BUNDLE_URL: 'https://benefits-roadmap-fe-ch-hulk.os-dev.io/',
    SITECORE_ASSETS_REVIEW_BUNDLE_URL: 'https://commerce-content-optimizer-fe-ch-elektra.os-dev.io/main.js',
    AUGMENTED_OCCASIONS_BUNDLE_URL: 'https://augmented-occasions-fe-ch-elektra.os-dev.io/',
    AI_ASSISTANT_BUNDLE_URL: 'https://open-pa-fe-ch-elektra.os-dev.io/',
    TECH_BRIEF_BUNDLE_URL: 'https://ai-tech-brief-fe-ch-witch.os-dev.io/',
    PROCESS_BUILDER_BUNDLE_URL: 'https://wpp-app-process-builder-ch-elektra.os-dev.io/main.js',
  } satisfies Partial<BundleUrlConfigs>

  switch (legacyBoxEnv) {
    case LegacyBoxEnv.Staging: {
      return {
        ...commonConfig,
        MARKETPLACE_BUNDLE_URL: 'https://wpp-app-market-fe-ch-stage.os-dev.io/main.js',
      }
    }

    default: {
      return {
        ...commonConfig,
        MARKETPLACE_BUNDLE_URL: 'https://wpp-app-market-fe-ch-hulk.os-dev.io/main.js',
      }
    }
  }
}

const getLocalApiUrls = (deploymentEnv: OsDeploymentEnvironment): ApiUrlConfigs => ({
  RECENT_WORK_API_URL: `https://recent-work-api-ch-${deploymentEnv}.os-dev.io/`,
  NEWS_API_URL: `https://news-feed-api-ch-${deploymentEnv}.os-dev.io/`,
  RND_API_URL: 'https://pitch-rnd-api-ch-elektra.os-dev.io/',
  WORKFRONT_API: 'https://collider-api-ch-elektra.os-dev.io/api/workfront',
})

export const REACT_QUERY_DEV_OFFLINE_CACHE_KEY = 'REACT_QUERY_WPP_OPEN_OS_OFFLINE_CACHE'

export const localDevConfig = {
  legacyBoxEnv: LegacyBoxEnv.Development,
  tenantSubdomain: TenantSubdomain.WppOpen,
  deploymentEnv: OsDeploymentEnvironment.Hulk,
  segmentAnalyticsKey: '',
  intercomAppId: '',
  intercomApiBase: '',
  zendeskKey: '',
}

export type LocalDevConfig = typeof localDevConfig

export const devEnvironment: Environment = {
  ENV: localDevConfig.deploymentEnv,
  LEGACY_BOX_ENV: localDevConfig.legacyBoxEnv,
  SEGMENT_ANALYTICS_KEY: localDevConfig.segmentAnalyticsKey,
  INTERCOM_APP_ID: localDevConfig.intercomAppId,
  INTERCOM_API_BASE: localDevConfig.intercomApiBase,
  ZENDESK_KEY: localDevConfig.zendeskKey,
  ...getLocalDevKeycloakConfig(localDevConfig.legacyBoxEnv),
  ...getLocalDevGenericTenantUrl(localDevConfig.legacyBoxEnv, localDevConfig.deploymentEnv),
  ...getLocalDevBundleUrls(localDevConfig.legacyBoxEnv, localDevConfig.deploymentEnv),
  ...getLocalApiUrls(localDevConfig.deploymentEnv),
}
