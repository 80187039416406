import { AppLibraryType, MicroAppCustomProps } from '@wpp-open/core'
import { useEffect } from 'react'

import { environment } from 'environment'
import { useMicroAppsContext } from 'providers/microApps/MicroAppsProvider'
import { AppConfig } from 'types/common/singleSpa'
import { registerApp, unregisterApp } from 'utils/singleSpa'

const APP_CODE = 'OS-ASSISTANT'

const getAppConfig = (customProps: MicroAppCustomProps): AppConfig => ({
  libraryType: AppLibraryType.SystemJS,
  code: APP_CODE,
  appUrl: environment.AI_ASSISTANT_BUNDLE_URL,
  activeWhen: () => true,
  customProps,
})

export const OsAssistant = () => {
  const { getMicroAppCustomProps } = useMicroAppsContext()

  useEffect(() => {
    const customProps: MicroAppCustomProps = {
      ...getMicroAppCustomProps({ code: APP_CODE }),
      domElementGetter: () => document.getElementById('chat-assistant')!,
    }

    registerApp(getAppConfig(customProps))

    return () => {
      unregisterApp({ code: APP_CODE })
    }
  }, [getMicroAppCustomProps])

  return <div id="chat-assistant" />
}
