import { WppAvatar, WppTypography } from '@platform-ui-kit/components-library-react'
import { useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import personsData from 'pages/landing/assets/landing/jsons/persons.json'
import { Carousel } from 'pages/landing/components/carousel/Carousel'
import styles from 'pages/landing/homePage/transformingWPPsImpact/transformingWPPsImpactCarousel/TransformingWPPsImpactCarousel.module.scss'

interface Person {
  brand?: string
  title: string
  avatar: string
  text: string
  personInfo: {
    name: string
    position: string
  }
}

export const TransformingWPPsImpactCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0)
  const persons: Person[] = personsData?.data || []

  return (
    <Carousel currentIndex={currentIndex} itemsCount={persons.length} onMove={setCurrentIndex}>
      <Flex direction="row" gap={24} align="center">
        <WppAvatar
          className={styles.avatar}
          name={persons[currentIndex].title}
          {...(!!persons[currentIndex]?.avatar && { src: persons[currentIndex]?.avatar })}
        />
        <Flex direction="column" gap={16}>
          <div className={styles.logo}>
            <img src={persons[currentIndex].brand} />
          </div>
          <WppTypography type="l-body">{persons[currentIndex].text}</WppTypography>
          <Flex direction="column">
            <WppTypography type="s-midi">{persons[currentIndex].personInfo.name}</WppTypography>
            <WppTypography type="s-body">{persons[currentIndex].personInfo.position}</WppTypography>
          </Flex>
        </Flex>
      </Flex>
    </Carousel>
  )
}
