import clsx from 'clsx'
import { PropsWithChildren } from 'react'
import { NavLink } from 'react-router-dom'

import styles from 'pages/landing/components/header/MenuItem.module.scss'

interface Props {
  to: string
}

export const MenuItem = ({ children, to }: PropsWithChildren<Props>) => {
  return (
    <NavLink className={({ isActive }) => clsx(styles.root, { [styles.active]: isActive })} to={to}>
      {children}
    </NavLink>
  )
}
