import { PropsWithChildren } from 'react'

import { Header } from 'layout/header/Header'
import { HideHeader } from 'layout/hideHeader/HideHeader'
import styles from 'layout/SecureLayout.module.scss'
import { isEmbedded } from 'utils/layout'

export const SecureLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <div className={styles.root}>
      {isEmbedded() ? <HideHeader /> : <Header />}
      {children}
    </div>
  )
}
