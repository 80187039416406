import clsx from 'clsx'
import { useCss } from 'react-use'

import styles from 'layout/authLayout/AuthLayout.module.scss'
import { usePublicData } from 'providers/publicData/PublicDataProvider'

export const AuthLayout = ({ className, children, ...rest }: JSX.IntrinsicElements['div']) => {
  const { defaultTenant, currentTenantPublic } = usePublicData()
  const tenantBackgroundUrl = currentTenantPublic?.loginBackground?.url || defaultTenant.loginBackground?.url

  return (
    <div {...rest} className={clsx(styles.container, className)}>
      <div
        data-testid="login-preview"
        className={clsx(
          styles.background,
          useCss({
            backgroundImage: `url(${tenantBackgroundUrl})`,
          }),
        )}
      />
      <div className={styles.card}>{children}</div>
    </div>
  )
}
