import clsx from 'clsx'
import { useEffect, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/landing/storiesPage/components/carousel/Carousel.module.scss'

interface Props {
  slides: string[]
}

export const Carousel = ({ slides }: Props) => {
  const [activeSlide, setActiveSlide] = useState(0)

  const changeSlide = (slide: number) => {
    setActiveSlide(slide)
  }

  useEffect(() => {
    setActiveSlide(0)
  }, [slides])

  const previousSlide = activeSlide - 1 < 0 ? slides.length - 1 : activeSlide - 1
  const nextSlide = activeSlide + 1 > slides.length - 1 ? 0 : activeSlide + 1

  return (
    <Flex className={styles.carousel} align="center" gap={24} justify="center">
      <Flex justify="between" align="center">
        <button className={styles.clickableSlide} onClick={() => changeSlide(previousSlide)}>
          <img className={clsx(styles.slide, styles.leftSlide)} src={slides[previousSlide]} />
        </button>
        <Flex>
          <div className={styles.activeSlide}>
            <img className={styles.slide} src={slides[activeSlide]} />
            <Flex className={styles.controls} justify="center" gap={8}>
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={clsx(styles.control, index === activeSlide && styles.activeControl)}
                  onClick={() => changeSlide(index)}
                />
              ))}
            </Flex>
          </div>
        </Flex>
        <button className={styles.clickableSlide} onClick={() => changeSlide(nextSlide)}>
          <img className={clsx(styles.slide, styles.rightSlide)} src={slides[nextSlide]} />
        </button>
      </Flex>
    </Flex>
  )
}
