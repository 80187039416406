import { LeanAppShort } from 'types/apps/leanApps'
import { ProjectCanvasApplication, ProjectCanvasApplicationType } from 'types/projects/projectPhase'

export const matchLeanApp = (leanApps: LeanAppShort[], projectApp: ProjectCanvasApplication) => {
  if (projectApp.type === ProjectCanvasApplicationType.MiroBoard) {
    return null
  }

  const appId =
    projectApp.type === ProjectCanvasApplicationType.MiroBoardInstance
      ? projectApp.externalAppOriginId
      : projectApp.externalAppId

  return leanApps.find(({ id }) => id === appId) || null
}
