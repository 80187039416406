import { WppActionButton, WppButton, WppCard, WppInput, WppTypography } from '@platform-ui-kit/components-library-react'
import { UserDetails } from '@wpp-open/core'
import clsx from 'clsx'
import { useDeferredValue, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { showRequestTenantModal } from 'components/modal/requestTenantModal/RequestTenantModal'
import { SvgNoRecords } from 'components/svg/network/SvgNoRecords'
import { SvgNoTenants } from 'components/svg/tenants/SvgNoTenants'
import { REACT_QUERY_DEV_OFFLINE_CACHE_KEY } from 'environment/dev'
import { useTenantLogoAndMetaData } from 'hooks/useTenantLogoAndMetaData'
import { AuthLayout } from 'layout/authLayout/AuthLayout'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import styles from 'pages/tenantSelection/TenantSelection.module.scss'
import { TenantPublicShort, TenantShort } from 'types/tenants/tenant'

interface Props {
  availableTenants: TenantShort[]
  requestableTenants: TenantPublicShort[]
  userDetails: UserDetails
}
export const minimalNumberOfTenantsForSearch = 13

export const TenantSelection = ({ availableTenants, requestableTenants, userDetails }: Props) => {
  const { t } = useTranslation()
  const { logoOriginal } = useTenantLogoAndMetaData()
  const [tenantSearch, setTenantSearch] = useState('')

  const hasSingleTenant = availableTenants.length === 1
  const preferredTenantId = userDetails.preferredTenantId
  const preferredTenant = preferredTenantId ? availableTenants.find(tenant => tenant.id === preferredTenantId) : null
  const primaryTenant = availableTenants.find(tenant => tenant.flags.isPrimary)
  const shouldRedirectToTenant = !!(hasSingleTenant || preferredTenant || primaryTenant)

  const handleSelectTenant = () => {
    // Clear offline cache for dev mode
    if (process.env.DEV) {
      window.sessionStorage.removeItem(REACT_QUERY_DEV_OFFLINE_CACHE_KEY)
    }
  }

  useEffect(() => {
    if (shouldRedirectToTenant) {
      const targetTenant = (hasSingleTenant ? availableTenants[0] : preferredTenant || primaryTenant)!
      const targetUrl = process.env.DEV ? '/' : targetTenant.homeUrl

      window.location.replace(targetUrl)
    }
  }, [availableTenants, hasSingleTenant, preferredTenant, primaryTenant, shouldRedirectToTenant])

  const isTenantSearchShown = availableTenants.length >= minimalNumberOfTenantsForSearch

  const filteredTenants = useDeferredValue(
    tenantSearch
      ? availableTenants.filter(({ name }) => name.toLocaleLowerCase().includes(tenantSearch.toLocaleLowerCase()))
      : availableTenants,
  )

  // User will be redirected to specific tenant
  if (shouldRedirectToTenant) {
    return <LoadingPage />
  }

  if (availableTenants.length === 0) {
    return (
      <AuthLayout>
        <div className={clsx(styles.cardContent, styles.grouped)}>
          <img className={styles.currentTenantLogo} alt="Tenant logo" src={logoOriginal} />

          <SvgNoTenants className={styles.noTenantsImage} />

          <div className={styles.centered}>
            <WppTypography className={styles.text} type="l-strong">
              {t('os.tenant_selection.no_available_tenants.message')}
            </WppTypography>

            {!!requestableTenants.length && (
              <WppButton
                className={styles.noTenantsRequestAccess}
                onClick={() =>
                  showRequestTenantModal({
                    title: t('os.tenant_selection.request_access'),
                    tenants: requestableTenants,
                  })
                }
                data-testid="request-tenant-access-button"
              >
                {t('os.tenant_selection.no_available_tenants.request_access')}
              </WppButton>
            )}
          </div>
        </div>
      </AuthLayout>
    )
  }

  return (
    <AuthLayout>
      <div className={styles.cardContent}>
        <div className={styles.message}>
          <WppTypography className={styles.text} type="l-strong">
            {t('os.tenant_selection.multiple_os')}
          </WppTypography>
        </div>
        {isTenantSearchShown && (
          <div className={styles.search}>
            <WppInput
              size="s"
              type="search"
              className={styles.searchInput}
              onWppChange={({ detail }) => setTenantSearch(detail.value!.trim())}
              placeholder={t('os.tenant_selection.search_placeholder')}
            />
          </div>
        )}

        <div className={styles.tenants}>
          <div className={styles.tenantsList}>
            {filteredTenants.map(tenant => {
              const logo = tenant.logoOriginal?.url
              const targetUrl = process.env.DEV ? '/' : tenant.homeUrl

              return (
                <a
                  key={tenant.id}
                  className={styles.tenantLink}
                  href={targetUrl}
                  onClick={handleSelectTenant}
                  data-testid={tenant.name}
                >
                  <WppCard size="s" className={styles.tenantCard} interactive>
                    {logo ? (
                      <img className={styles.tenantLogo} alt="Tenant logo" src={logo} />
                    ) : (
                      <WppTypography className={styles.text} type="m-strong">
                        {tenant.name}
                      </WppTypography>
                    )}
                  </WppCard>
                </a>
              )
            })}
            {filteredTenants.length === 0 && (
              <Flex direction="column" align="center" gap={12}>
                <SvgNoRecords data-testid="no-records-svg" />
                <WppTypography type="m-strong" data-testid="no-tenant-found-text">
                  {t('os.tenant_selection.no_search_records', { search: tenantSearch })}
                </WppTypography>
              </Flex>
            )}
          </div>
        </div>

        {!!requestableTenants.length && (
          <div className={styles.centered}>
            <WppTypography className={styles.text} type="m-strong" data-testid="request-tenant-access-text">
              {t('os.tenant_selection.dont_see')}
            </WppTypography>
            <WppActionButton
              variant="secondary"
              onClick={() =>
                showRequestTenantModal({
                  title: t('os.tenant_selection.request_access'),
                  tenants: requestableTenants,
                })
              }
              data-testid="request-tenant-access-button"
            >
              <WppTypography type="s-body" className={styles.underlined}>
                {t('os.tenant_selection.request_access')}
              </WppTypography>
            </WppActionButton>
          </div>
        )}
      </div>
    </AuthLayout>
  )
}
