import { WppMenuGroup } from '@platform-ui-kit/components-library-react'
import { Fragment } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/appsTreeList/AppsTreeList.module.scss'
import { useGroupAppsPointerNodes } from 'components/treeList/appsTreeList/utils'
import { PointerNode, Tree } from 'utils/mapping/common'

interface Props<T extends Tree> {
  tree: T
  pointerNodes: PointerNode<T>[]
  renderNode: (pointerNode: PointerNode<T>) => JSX.Element
}

export const AppsTreeList = <T extends Tree>({ pointerNodes, tree, renderNode }: Props<T>) => {
  const { ungrouped, grouped } = useGroupAppsPointerNodes({ pointerNodes, tree })

  return (
    <>
      {!!pointerNodes.length && (
        <Flex direction="column" gap={6} data-testid="apps-tree-list">
          {!!ungrouped.length && (
            <Flex direction="column" gap={4} data-testid="ungrouped-apps">
              {ungrouped.map(pointerNode => (
                <Fragment key={pointerNode.nodePath}>{renderNode(pointerNode)}</Fragment>
              ))}
            </Flex>
          )}

          {grouped.map(({ group, children }) => (
            <Flex
              as={WppMenuGroup}
              key={group.id}
              header={group.name}
              className={styles.group}
              gap={4}
              direction="column"
              data-testid="app-group"
            >
              {children.map(pointerNode => (
                <Fragment key={pointerNode.nodePath}>{renderNode(pointerNode)}</Fragment>
              ))}
            </Flex>
          ))}
        </Flex>
      )}
    </>
  )
}
