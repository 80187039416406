import { Link } from 'react-router-dom'

import { ReactComponent as Logo } from 'pages/landing/assets/footer_logo.svg'
import styles from 'pages/landing/components/footer/Footer.module.scss'
import { landingRoutes } from 'pages/landing/const'

export const Footer = () => (
  <div className={styles.root} data-testid="landing-footer-container">
    <div className={styles.logo}>
      <Link to="">
        <Logo data-testid="landing-footer-logo" />
      </Link>
    </div>
    <div className={styles.links}>
      <ul>
        <li>
          <Link to={landingRoutes.aboutOpen}>About Open</Link>
        </li>
        <li>
          <Link to={landingRoutes.whatWeOffer}>What we offer</Link>
        </li>
        <li>
          <Link to={landingRoutes.ourProducts}>Our apps</Link>
        </li>
        <li>
          <Link to={landingRoutes.successStories}>Success stories</Link>
        </li>
      </ul>

      <ul>
        <li>
          <Link to={landingRoutes.contact}>Contact</Link>
        </li>
        <li>
          <Link to={landingRoutes.terms}>Terms & Conditions</Link>
        </li>
        <li>
          <a href="https://www.wpp.com">WPP.com</a>
        </li>
      </ul>
    </div>
  </div>
)
