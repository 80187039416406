import { LeanAppType } from '@wpp-open/core'

import { DefaultLeanAppRenderer } from 'pages/leanApp/defaultLeanAppRenderer/DefaultLeanAppRenderer'
import styles from 'pages/leanApp/LeanApp.module.scss'
import { MiroBoardAppRenderer } from 'pages/leanApp/miroBoardAppRenderer/MiroBoardAppRenderer'
import { RenderableLeanAppShort } from 'types/navigation/navigation'

interface Props {
  appShort: RenderableLeanAppShort
}

export const LeanApp = ({ appShort }: Props) => (
  <div className={styles.root}>
    {appShort.type === LeanAppType.MIRO_BOARD ? (
      <MiroBoardAppRenderer />
    ) : (
      <DefaultLeanAppRenderer appShort={appShort} />
    )}
  </div>
)
