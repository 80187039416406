import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import solutionsData from 'pages/landing/assets/landing/jsons/solutions.json'
import statisticsData from 'pages/landing/assets/landing/jsons/statistics.json'
import { Button } from 'pages/landing/components/button/Button'
import { landingRoutes } from 'pages/landing/const'
import styles from 'pages/landing/homePage/holisticSolutions/HolisticSolutions.module.scss'
import { HolisticSolutionsCard } from 'pages/landing/homePage/holisticSolutions/holisticSolutionsCard/HolisticSolutionsCard'

export interface Solution {
  header: string
  description: string
  image: string
}

interface Statistic {
  value: string
  label: string
}

export const HolisticSolutions = () => {
  const solutions: Solution[] = solutionsData?.data || []
  const statistics: Statistic[] = statisticsData?.data || []

  return (
    <Flex direction="column" align="center" gap={40}>
      <Flex direction="column" gap={24} align="center" className={styles.solutionsHeader}>
        <WppTypography type="4xl-display">Holistic Solutions</WppTypography>
        <WppTypography type="l-body">
          Open paves the way for teams to tackle intricate marketing and advertising challenges with the power of
          AI-driven solutions, supporting the marketing life-cycle in four key areas:
        </WppTypography>
      </Flex>
      <Flex direction="row" wrap="wrap" className={styles.solutions}>
        {solutions.map(solutionsItem => (
          <HolisticSolutionsCard key={solutionsItem.header} item={solutionsItem} />
        ))}
      </Flex>
      <Flex direction="row" gap={106} justify="center">
        {statistics.map(statisticsItem => (
          <Flex key={statisticsItem.label} direction="column" align="center" gap={8} className={styles.statisticCard}>
            <WppTypography className={styles.statisticsData}>{statisticsItem.value}</WppTypography>
            <WppTypography type="l-body">{statisticsItem.label}</WppTypography>
          </Flex>
        ))}
      </Flex>
      <Flex direction="row" justify="center">
        <Button size="m" component={Link} to={landingRoutes.whatWeOffer}>
          <WppTypography type="l-midi">See what we offer</WppTypography>
        </Button>
      </Flex>
    </Flex>
  )
}
