import { TenantConfigNavigationType } from '@wpp-open/core'
import { createContext, PropsWithChildren, useContext, useState } from 'react'

import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useData } from 'providers/data/DataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { createNodePath, getHierarchyContainerNodePath } from 'utils/mapping/common'
import { isNavigationTypeEnabled } from 'utils/navigation'

export interface NavigationMenuControllerContextValue {
  localNodePath: string
  setLocalNodePath: (nodePath: string) => void
}

const NavigationMenuControllerContext = createContext<NavigationMenuControllerContextValue>(null!)
export const useNavigationMenuController = () => useContext(NavigationMenuControllerContext)

export const NavigationMenuControllerProvider = ({ children }: PropsWithChildren<{}>) => {
  const { currentTenant } = useTenantAndUserData()
  const { navigationTree } = useData()
  const { preselectedWorkspaceLevels } = useAppState()
  const { nodesPathsMapping, activeNodePath } = useHeaderNavigation()

  const [localNodePath, setLocalNodePath] = useState(() =>
    preselectedWorkspaceLevels?.length
      ? Object.keys(nodesPathsMapping).find(nodePath =>
          nodePath.endsWith(createNodePath(preselectedWorkspaceLevels)),
        ) ||
        (isNavigationTypeEnabled({ type: TenantConfigNavigationType.Hierarchy, currentTenant })
          ? getHierarchyContainerNodePath(navigationTree)
          : navigationTree.rootId)
      : activeNodePath,
  )

  const value: NavigationMenuControllerContextValue = {
    localNodePath,
    setLocalNodePath,
  }

  return <NavigationMenuControllerContext.Provider value={value}>{children}</NavigationMenuControllerContext.Provider>
}
