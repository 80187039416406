import { PropsWithChildren } from 'react'
import { Route, Routes } from 'react-router-dom'

import WorkfrontAuth from 'pages/playground/pages/workfrontAuth/WorkfrontAuth'
import { TermsOfService } from 'pages/termsOfService/TermsOfService'

export const PublicRoutes = ({ children }: PropsWithChildren<{}>) => (
  <Routes>
    <Route path="terms" element={<TermsOfService />} />
    <Route path="*" element={children} />
    <Route path="user/profile/auth/workfront">
      <Route index element={<WorkfrontAuth />} />
    </Route>
  </Routes>
)
