import {
  ChildHierarchyLevelType,
  getChildHierarchyLevels,
  HierarchyLevel,
  MayBeNull,
  Tenant,
  UserDetails,
} from '@wpp-open/core'
import { AxiosError } from 'axios'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Route, Routes } from 'react-router-dom'

import { useAllTenantsApi } from 'api/tenant/queries/useAllTenantsApi'
import { useAvailableTenantsApi } from 'api/tenant/queries/useAvailableTenantsApi'
import { useTenantApi } from 'api/tenant/queries/useTenantApi'
import { useCurrentUserApi } from 'api/users/queries/useCurrentUserApi'
import { is403Error, is404Error } from 'api/utils'
import { ForbiddenOSAccessError, OsIsNotAvailableError } from 'components/renderError'
import { useProviderNoncriticalError } from 'hooks/useProviderNoncriticalError'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { landingRoutes } from 'pages/landing/const'
import { LandingRoutes } from 'pages/landing/LandingRoutes'
import { TenantSelection } from 'pages/tenantSelection/TenantSelection'
import { usePublicData } from 'providers/publicData/PublicDataProvider'
import { GenericTenantRoutes } from 'providers/tenantAndUserData/genericTenantRoutes/GenericTenantRoutes'
import { SpecificTenantRoutes } from 'providers/tenantAndUserData/specificTenantRoutes/SpecificTenantRoutes'
import { HostType, TenantPublicShort, TenantShort } from 'types/tenants/tenant'

export interface TenantAndUserDataContextValue {
  currentTenant: Tenant
  navigationHierarchy: HierarchyLevel<ChildHierarchyLevelType>[]
  availableTenants: TenantShort[]
  requestableTenants: TenantPublicShort[]
  userDetails: UserDetails
}

export const TenantAndUserDataContext = createContext<TenantAndUserDataContextValue>(null!)

export const useTenantAndUserData = () => useContext(TenantAndUserDataContext)

export const TenantAndUserDataProvider = ({ children }: PropsWithChildren<{}>) => {
  const { t } = useTranslation()
  const { currentTenantPublic, tenantType } = usePublicData()
  const { isLoading: isUserLoading, data: userDetails, error: userDetailsError } = useCurrentUserApi()

  const isGenericTenant = tenantType === HostType.GENERIC

  // Note: Tenant queries are dependent on the user because new users are created
  // during the first call on the backend, so tenants are not available before that happens.
  const {
    data: availableTenants,
    isLoading: isAvailableTenantsLoading,
    isError: isAvailableTenantsError,
  } = useAvailableTenantsApi({
    enabled: !!userDetails,
  })

  const {
    data: allTenants,
    isLoading: isAllTenantsLoading,
    isError: isAllTenantsError,
  } = useAllTenantsApi({
    enabled: !!userDetails,
  })

  // We send the request only when we have public tenant data, so 404 or 403 means user doesn't have access
  const isTenantNotPermittedError = (error: MayBeNull<AxiosError>) => is404Error(error) || is403Error(error)

  const {
    data: currentTenantFull,
    isLoading: isCurrentTenantFullLoading,
    error: currentTenantFullError,
  } = useTenantApi({
    params: {
      tenantId: currentTenantPublic?.id!,
    },
    enabled: !!userDetails && !isGenericTenant,
    // [WPPLONOP-15829] Long polling added for OS first time access while user is being created in several services
    retry: (failureCount, error) => isTenantNotPermittedError(error) && failureCount < 13,
    retryDelay: 5000,
  })

  useProviderNoncriticalError({
    isError: isAvailableTenantsError || isAllTenantsError,
    message: t('os.provider_errors.available_tenants'),
  })

  const navigationHierarchy = useMemo(
    () => (currentTenantFull ? getChildHierarchyLevels(currentTenantFull) : []),
    [currentTenantFull],
  )

  const requestableTenants = useMemo(
    () =>
      (allTenants || [])
        .filter(tenant => !availableTenants?.find(availableTenant => availableTenant.id === tenant.id))
        .filter(({ flags }) => !flags.onlyAdminsAccessible && flags.accessRequestable)
        .sort((a, b) => a.name.localeCompare(b.name)),
    [allTenants, availableTenants],
  )

  const sortedAvailableTenants = useMemo(
    () => availableTenants?.sort((a, b) => a.name.localeCompare(b.name)),
    [availableTenants],
  )

  // We send the request only when we have public tenant data, so 404 or 403 means user doesn't have access
  const isTenantNotPermitted = isTenantNotPermittedError(currentTenantFullError)
  const isUserDetailsNotPermitted = is403Error(userDetailsError)
  const isLoading = isAvailableTenantsLoading || isAllTenantsLoading || isCurrentTenantFullLoading || isUserLoading

  if (isTenantNotPermitted || isUserDetailsNotPermitted) {
    return <ForbiddenOSAccessError />
  }

  if (currentTenantFullError || userDetailsError) {
    return <OsIsNotAvailableError />
  }

  if (isLoading) {
    return <LoadingPage />
  }

  return (
    <TenantAndUserDataContext.Provider
      value={{
        currentTenant: currentTenantFull!,
        navigationHierarchy,
        availableTenants: sortedAvailableTenants!,
        requestableTenants,
        userDetails: userDetails!,
      }}
    >
      {isGenericTenant ? (
        <GenericTenantRoutes>
          <Routes>
            <Route index path="*" element={<LandingRoutes />} />
            <Route
              path={landingRoutes.selectOs}
              element={
                <TenantSelection
                  availableTenants={sortedAvailableTenants!}
                  requestableTenants={requestableTenants}
                  userDetails={userDetails!}
                />
              }
            />
          </Routes>
        </GenericTenantRoutes>
      ) : (
        <SpecificTenantRoutes>{children}</SpecificTenantRoutes>
      )}
    </TenantAndUserDataContext.Provider>
  )
}
