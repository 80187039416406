import { useEffect } from 'react'

import { AppData } from 'constants/apps'
import { getActiveWhen } from 'pages/microApp/utils'
import { useMicroAppsContext } from 'providers/microApps/MicroAppsProvider'
import { registerApp } from 'utils/singleSpa'

interface Props {
  app: AppData
}

export const MicroApp = ({ app }: Props) => {
  const { getMicroAppCustomProps, setIsMicroAppActive } = useMicroAppsContext()

  useEffect(() => {
    const { libraryType, code, bundleUrl, windowLibraryName } = app

    registerApp({
      libraryType,
      code,
      appUrl: bundleUrl,
      activeWhen: getActiveWhen(app),
      windowLibraryName,
      customProps: getMicroAppCustomProps(app),
    })
  }, [app, getMicroAppCustomProps])

  useEffect(() => {
    setIsMicroAppActive(true)

    return () => setIsMicroAppActive(false)
  }, [setIsMicroAppActive])

  return null
}
