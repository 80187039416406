import { MicroAppCustomProps, MayBeNull, AppLibraryType } from '@wpp-open/core'
import { BehaviorSubject, Observable, Observer } from 'rxjs'
import { Activity, SingleSpaCustomEventDetail } from 'single-spa'

import { Config, Context, MicroAppAction } from 'legacy/types/osWebRootApi'
import { LegacyAppV2CustomProps } from 'legacy/types/osWebRootApi/legacy'

type SingleSpaEvent =
  | 'before-app-change'
  | 'before-no-app-change'
  | 'before-routing-event'
  | 'before-mount-routing-event'
  | 'before-first-mount'
  | 'first-mount'
  | 'app-change'
  | 'no-app-change'
  | 'routing-event'

type SingleSpaEventsMap = {
  [key in `single-spa:${SingleSpaEvent}`]: CustomEvent<SingleSpaCustomEventDetail>
}

declare global {
  interface WindowEventMap extends SingleSpaEventsMap {}
}

export enum LocalAppLibraryType {
  Window = 'window',
  SystemJS = 'sjs',
  ESM = 'esm',
}

export interface LegacyAppV3CustomProps {
  domElementGetter: () => HTMLElement
  baseHref: string
  assetsPath: string
  baseUrl$: BehaviorSubject<MayBeNull<string>>
  jwt$: BehaviorSubject<MayBeNull<string>>
  context$: BehaviorSubject<MayBeNull<Context>>
  config$: BehaviorSubject<MayBeNull<Config>>
  eventEmitter$: Observer<MicroAppAction>
  events$: Observable<MicroAppAction>
  overlayContainer: null
  appState: Record<string, any>
  openApp: (appCode: string, subPath?: string) => void
}

export interface AppConfig {
  libraryType: AppLibraryType
  code: string
  appUrl: string
  activeWhen: Activity
  windowLibraryName?: string
  customProps: MicroAppCustomProps | LegacyAppV3CustomProps | LegacyAppV2CustomProps
}
