import { MiroBoardAppShort } from 'types/apps/leanApps'
import { RenderableLeanAppShort } from 'types/navigation/navigation'

export enum ConstellationTableauLeanApps {
  CultureGraph = 'a2d5001a-edcb-447d-9060-a4893fbee97b',
}
export enum ModeloTableauLeanApps {
  CultureGraph = '685ad40b-a3ec-4612-b543-5119e3e3d5f5',
}

export enum AllwynTableauLeanApps {
  MissionControl = 'a2da9966-bc53-4f02-af4c-d49b86051a43',
}

export enum PfizerTableuLeanApps {
  GlobalDataAvailabilityMap = 'eca4d077-f2dc-4cb6-965a-51c242d28fa6',
  AdienceEngineDashboard = '91ea6853-e088-4485-8feb-ff1fe7715d2f',
  AdienceEngineDashboardNurtecUK = '43d12b05-0572-4d93-a237-20c5217a29c4',
  AdienceEngineDashboardRittleUS = '38826778-e899-406c-b6d9-bd8017e84a44',
  AdienceEngineDashboardInlytaUS = 'e6185ba9-0456-4dae-9549-88e98a1837ea',
}

export const isTableauApp = (appShort: Exclude<RenderableLeanAppShort, MiroBoardAppShort>) => {
  const isAllwynTableauLeanApp = Object.values<string>(AllwynTableauLeanApps).includes(appShort.id)
  const isConstellationTableauLeanApp = Object.values<string>(ConstellationTableauLeanApps).includes(appShort.id)
  const isModeloTableauLeanApp = Object.values<string>(ModeloTableauLeanApps).includes(appShort.id)
  const isPfizerTableauLeanApp = Object.values<string>(PfizerTableuLeanApps).includes(appShort.id)
  const isTableauLeanApp =
    isAllwynTableauLeanApp || isConstellationTableauLeanApp || isModeloTableauLeanApp || isPfizerTableauLeanApp
  return isTableauLeanApp
}
