import segmentPlugin from '@analytics/segment'
import {
  AnalyticsAction,
  AnalyticsPageAction,
  AnalyticsTrackAction,
  AnalyticsActionType,
  UserDetails,
  Tenant,
} from '@wpp-open/core'
import Analytics from 'analytics'

import { environment } from 'environment'
import { UserAssignedRoles } from 'types/users/users'
import { excludeFalsy, isString, noop } from 'utils/common'

const analytics = Analytics({
  app: 'os-app',
  plugins: [
    !!environment.SEGMENT_ANALYTICS_KEY &&
      segmentPlugin({
        writeKey: environment.SEGMENT_ANALYTICS_KEY,
      }),
  ].filter(excludeFalsy),
})

export const identifyUser = ({
  userDetails,
  currentTenant,
  roles,
}: {
  userDetails: UserDetails
  currentTenant: Tenant
  roles: UserAssignedRoles[]
}) => {
  const { agency, country, createdAt, firstname, lastname, email, id } = userDetails
  const domain = email.match(/(?:@).*(?=[.])/)![0].replace('@', '')

  ;(analytics.plugins as any).segment.group(`v4-${environment.LEGACY_BOX_ENV}-${currentTenant.name}`, {
    tenantId: currentTenant.azId,
    environment: environment.LEGACY_BOX_ENV,
    osVersion: 'v4',
  })
  return analytics.identify(id, {
    firstname,
    lastname,
    email,
    agency,
    tenantAzId: currentTenant.azId,
    createdAt,
    country,
    domain,
    roles,
  })
}

export const reset = () => {
  analytics.reset()
}

const getAnalyticsTenantAzId = () => analytics?.getState()?.user?.traits?.tenantAzId

const trackPage = ({ payload }: AnalyticsPageAction) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    analytics.page(
      {
        title: payload,
      },
      {
        agency: analyticsTenantAzId,
      },
    )
  }
}

const trackAction = ({ payload }: AnalyticsTrackAction) => {
  const analyticsTenantAzId = getAnalyticsTenantAzId()
  if (analyticsTenantAzId) {
    if (isString(payload)) {
      analytics.track(payload, {
        agency: analyticsTenantAzId,
      })
    } else {
      const { action, params } = payload

      analytics.track(action, {
        agency: analyticsTenantAzId,
        ...Object.fromEntries(params.map(({ key, value }) => [key, value])),
      })
    }
  }
}

export const trackAnalytics = process.env.DEV
  ? noop
  : (action: AnalyticsAction) => {
      switch (action.type) {
        case AnalyticsActionType.page:
          trackPage(action)
          break

        case AnalyticsActionType.action:
          trackAction(action)
          break

        default:
          break
      }
    }
