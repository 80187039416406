import { WppListItem, WppMenuGroup } from '@platform-ui-kit/components-library-react'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { Flex } from 'components/common/flex/Flex'
import { ProjectBreadcrumbsAppItem } from 'layout/header/breadcrumbs/contextMenu/projectAppItem/ProjectBreadcrumbsAppItem'
import styles from 'layout/header/breadcrumbs/projectLinear/ProjectLinearBreadcrumbs.module.scss'
import { ProjectPhase } from 'types/projects/projectPhase'
import { sortBy } from 'utils/common'
import { isProjectApplicationData } from 'utils/projects'

interface Props {
  projectId: string
  selectedPhase: ProjectPhase
}

export const ProjectLinearBreadcrumbsAppsColumn = ({ projectId, selectedPhase }: Props) => {
  const { t } = useTranslation()
  const shownPhaseItems = useMemo(() => {
    const filtered = selectedPhase.phaseItems.filter(
      ({ item }) => isProjectApplicationData(item) || item.items.length > 0,
    )

    return sortBy(filtered, ({ orderNumber }) => orderNumber)
  }, [selectedPhase.phaseItems])

  return (
    <Flex className={styles.appsColumn} gap={4} direction="column">
      {shownPhaseItems.map(({ id, item }) => {
        if (isProjectApplicationData(item)) {
          return <ProjectBreadcrumbsAppItem key={id} app={item} projectId={projectId} />
        }

        return (
          <Flex
            key={id}
            as={WppMenuGroup}
            header={item.name || ''}
            gap={4}
            direction="column"
            data-testid="breadcrumbs-menu-project-linear-activity"
          >
            {sortBy(item.items, ({ orderNumber }) => orderNumber).map(({ id, application }) => (
              <ProjectBreadcrumbsAppItem key={id} app={application} projectId={projectId} />
            ))}

            {!item.items.length && (
              <WppListItem disabled data-testid="breadcrumbs-menu-project-linear-no-apps">
                <span slot="label">{t('os.header.breadcrumbs.no_apps')}</span>
              </WppListItem>
            )}
          </Flex>
        )
      })}

      {!selectedPhase.phaseItems.length && (
        <WppListItem disabled data-testid="breadcrumbs-menu-project-linear-no-apps">
          <span slot="label">{t('os.header.breadcrumbs.no_apps')}</span>
        </WppListItem>
      )}
    </Flex>
  )
}
