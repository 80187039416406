import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useCss } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import background from 'pages/landing/assets/landing/hero.png'
import { Button } from 'pages/landing/components/button/Button'
import { landingRoutes } from 'pages/landing/const'
import styles from 'pages/landing/homePage/hero/Hero.module.scss'

export const Hero = () => {
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      gap={36}
      className={clsx(
        styles.heroSection,
        useCss({
          backgroundImage: `url(${background})`,
        }),
      )}
      data-testid="hero-section"
    >
      <WppTypography type="3xl-heading" className={styles.header}>
        Harness the power of AI with WPP Open
      </WppTypography>
      <WppTypography type="l-body" className={styles.headerDescription}>
        One Platform with endless solutions. Access the best data and AI powered tools like{' '}
        <WppTypography type="l-strong" className={styles.headerDescriptionPart}>
          WPP
        </WppTypography>
        <WppTypography type="l-strong" className={styles.headerDescriptionPart}>
          Imagine
        </WppTypography>
        , to harness the collective creative power of the WPP ecosystem.
      </WppTypography>
      <Flex direction="row" gap={24}>
        {/* TODO Provide more appropriate solution */}
        <Button
          className={styles.button}
          onClick={() => localStorage.clear()}
          component={Link}
          to={landingRoutes.selectOs}
          variant="secondary"
          size="m"
        >
          <WppTypography type="l-midi" className={styles.buttonText}>
            Access Open
          </WppTypography>
        </Button>
      </Flex>
    </Flex>
  )
}
