import { WppTypography } from '@platform-ui-kit/components-library-react'

import { Flex } from 'components/common/flex/Flex'
import benefitData from 'pages/landing/assets/landing/jsons/benefitData.json'
import benefitsData from 'pages/landing/assets/landing/jsons/benefits.json'
import { BenefitSection } from 'pages/landing/components/benefitSection/BenefitSection'
import styles from 'pages/landing/homePage/benefits/Benefits.module.scss'

interface Benefit {
  label: string
  image: string
}

export const Benefits = () => {
  const benefits: Benefit[] = benefitsData?.data || []

  return (
    <Flex direction="column" gap={120} className={styles.wrapper}>
      <Flex direction="row" gap={106} justify="center">
        {benefits.map(benefitItem => (
          <Flex key={benefitItem.label} direction="column" align="center" gap={24} className={styles.benefitItem}>
            <Flex align="center" justify="center" className={styles.benefitItemBg}>
              <img src={benefitItem.image} className={styles.benefitItemImg} />
            </Flex>
            <WppTypography type="l-body">{benefitItem.label}</WppTypography>
          </Flex>
        ))}
      </Flex>
      <Flex direction="column" justify="center" align="center" gap={40}>
        <WppTypography type="4xl-display" className={styles.benefitsHeader}>
          WPP Open simplifies and connects everything your team <br /> needs to drive creative transformation
        </WppTypography>
        <BenefitSection benefit={benefitData} size="medium" />
      </Flex>
    </Flex>
  )
}
