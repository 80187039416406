import {
  HierarchyContainerNodeId,
  LeanAppNodeType,
  NavigationTree,
  NavigationTreeNode,
  ProjectsContainerNodeId,
  Tenant,
  TenantConfigNavigationType,
} from '@wpp-open/core'

import { NodesPathsMapping, createNodePath } from 'utils/mapping/common'
import { isNavigationTypeEnabled } from 'utils/navigation'

export const getIsHierarchyColumnVisible = (nodePath: string) => nodePath.includes(HierarchyContainerNodeId)
export const getIsProjectsColumnVisible = (nodePath: string) => nodePath.includes(ProjectsContainerNodeId)

export const getIsComposedColumnVisible = (currentTenant: Tenant) => {
  const isHierarchyNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Hierarchy,
    currentTenant,
  })

  const isProjectsNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Projects,
    currentTenant,
  })

  const isCustomNavigationEnabled = isNavigationTypeEnabled({
    type: TenantConfigNavigationType.Custom,
    currentTenant,
  })

  return isCustomNavigationEnabled || (isHierarchyNavigationEnabled && isProjectsNavigationEnabled)
}

export const getProjectsContainerNodePath = ({ rootId }: NavigationTree) =>
  createNodePath([rootId, ProjectsContainerNodeId])

export const getProjectsPointerNodes = ({
  navigationTree,
  nodesPathsMapping,
}: {
  navigationTree: NavigationTree
  nodesPathsMapping: NodesPathsMapping<NavigationTree>
}) => nodesPathsMapping[getProjectsContainerNodePath(navigationTree)].children

export const getCustomNavigationNodesIds = ({ rootId, mapping }: NavigationTree) => {
  // For now only navigation nodes with type of LEAN_APP are treated as custom. Extend this array if need.
  const customNavigationNodeTypes: NavigationTreeNode['type'][] = [LeanAppNodeType]

  return mapping[rootId].children.filter(nodeId => customNavigationNodeTypes.includes(mapping[nodeId]?.type))
}
