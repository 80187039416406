import { MayBeNull, OpenMenuHandler } from '@wpp-open/core'
import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react'

import { useProjectApi } from 'api/projects/queries/useProjectApi'
import { useProjectCanvasFluidApi } from 'api/projects/queries/useProjectCanvasFluidApi'
import { useProjectCanvasLinearApi } from 'api/projects/queries/useProjectCanvasLinearApi'
import { is403Error, is404Error } from 'api/utils'
import { DataIsNotAvailableError, ForbiddenPageError } from 'components/renderError'
import { useStableCallback } from 'hooks/useStableCallback'
import {
  getInvalidAppDataFromUrl,
  getLoadingAppDataFromUrl,
  getValidatedProjectData,
  isRequiredWorkspaceLevel,
  mapShortAppDataToFull,
  useMicroAppDataFromUrl,
} from 'providers/appState/utils'
import { useData } from 'providers/data/DataProvider'
import { MicroAppDataFromUrlFull, MicroAppFromUrlType } from 'types/appState/appState'
import { ProjectProcessType } from 'types/projects/project'
import { excludeFalsy } from 'utils/common'
import { resolveWorkspace, WorkspaceLevels, WorkspaceType } from 'utils/workspace'

export interface AppStateContextValue {
  isNavigationOpen: boolean
  preselectedWorkspaceLevels: MayBeNull<WorkspaceLevels>
  microAppData: MicroAppDataFromUrlFull
  openNavigation: OpenMenuHandler
  closeNavigation: () => void
}

export const AppStateContext = createContext<AppStateContextValue>(null!)

export const useAppState = () => useContext(AppStateContext)

export const AppStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isNavigationOpen, setIsNavigationOpen] = useState(false)
  const [preselectedWorkspaceLevels, setPreselectedWorkspaceLevels] = useState<MayBeNull<WorkspaceLevels>>(null)
  const microAppDataShort = useMicroAppDataFromUrl()
  const { navigationTree } = useData()

  const isShortDataValid = microAppDataShort.type !== MicroAppFromUrlType.INVALID
  const isProjectSet = isShortDataValid && !!microAppDataShort.projectId

  const {
    isFetching: isProjectLoading,
    data: project,
    error: projectError,
  } = useProjectApi({
    params: {
      id: microAppDataShort.projectId!,
    },
    enabled: isProjectSet,
  })

  const isLinearProject = project?.processType === ProjectProcessType.Linear
  const isFluidProject = project?.processType === ProjectProcessType.Fluid

  const {
    isFetching: isProjectCanvasLinearLoading,
    data: projectCanvasLinear,
    error: projectCanvasLinearError,
  } = useProjectCanvasLinearApi({
    params: {
      id: microAppDataShort.projectId!,
    },
    enabled: isProjectSet && isLinearProject,
  })

  const {
    isFetching: isProjectCanvasFluidLoading,
    data: projectCanvasFluid,
    error: projectCanvasFluidError,
  } = useProjectCanvasFluidApi({
    params: {
      id: microAppDataShort.projectId!,
    },
    enabled: isProjectSet && isFluidProject,
  })

  const isLoading = isProjectLoading || isProjectCanvasLinearLoading || isProjectCanvasFluidLoading
  const projectErrors = [projectError, projectCanvasLinearError, projectCanvasFluidError].filter(excludeFalsy)
  const isError = projectErrors.length > 0

  const isProjectNotFoundError = !!projectErrors.find(e => is404Error(e))
  const isProjectForbiddenError = !!projectErrors.find(e => is403Error(e))

  const microAppData: MicroAppDataFromUrlFull = useMemo(() => {
    if (isLoading) {
      return getLoadingAppDataFromUrl(microAppDataShort)
    }

    const {
      isValid: isProjectDataValid,
      projectPhase,
      projectItem,
    } = getValidatedProjectData({
      microAppDataShort,
      projectCanvasLinear,
      projectCanvasFluid,
    })

    if (!isShortDataValid || isProjectNotFoundError || !isProjectDataValid) {
      return getInvalidAppDataFromUrl(microAppDataShort)
    }

    const workspaceAzId = project?.contextWorkspace || microAppDataShort.workspaceAzId || null
    const { isWorkspaceValid, workspaceLevels } = resolveWorkspace({
      workspaceAzId,
      navigationTree,
    })

    if (
      isWorkspaceValid &&
      isRequiredWorkspaceLevel({
        workspaceAzId,
        microAppDataShort,
        mapping: navigationTree.mapping,
      })
    ) {
      return mapShortAppDataToFull({
        microAppDataShort,
        workspaceLevels,
        project,
        projectCanvasLinear,
        projectCanvasFluid,
        projectPhase,
        projectItem,
      })
    }

    return getInvalidAppDataFromUrl(microAppDataShort)
  }, [
    isLoading,
    isProjectNotFoundError,
    isShortDataValid,
    microAppDataShort,
    navigationTree,
    project,
    projectCanvasFluid,
    projectCanvasLinear,
  ])

  const openNavigation: OpenMenuHandler = useStableCallback((preselectedNodeAzId = null) => {
    const { isWorkspaceValid, workspaceLevels } = resolveWorkspace({
      workspaceAzId: preselectedNodeAzId,
      navigationTree,
      workspaceType: WorkspaceType.All,
    })

    setIsNavigationOpen(true)

    if (isWorkspaceValid) {
      setPreselectedWorkspaceLevels(preselectedNodeAzId ? workspaceLevels : null)
    } else {
      console.warn('openNavigation: Invalid preselectedNodeAzId. Opening navigation without preselected workspace.')
      setPreselectedWorkspaceLevels(null)
    }
  })

  const closeNavigation = useStableCallback(() => {
    setIsNavigationOpen(false)
    setPreselectedWorkspaceLevels(null)
  })

  if (isError && !isProjectNotFoundError) {
    return isProjectForbiddenError ? <ForbiddenPageError /> : <DataIsNotAvailableError />
  }

  return (
    <AppStateContext.Provider
      value={{
        isNavigationOpen,
        preselectedWorkspaceLevels,
        microAppData,
        openNavigation,
        closeNavigation,
      }}
    >
      {children}
    </AppStateContext.Provider>
  )
}
