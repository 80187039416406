import { WppTypography } from '@platform-ui-kit/components-library-react'
import { ComponentProps } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'layout/header/breadcrumbs/item/BreadcrumbItem.module.scss'

interface Props extends ComponentProps<typeof Flex> {
  label: string
  link?: string
}

export const BreadcrumbItem = ({ label, link, children, ...rest }: Props) => {
  const labelEl = (
    <WppTypography className={styles.label} type="s-body">
      {label}
    </WppTypography>
  )

  return (
    <Flex className={styles.root} title={label} align="center" gap={4} data-testid="breadcrumb-item" {...rest}>
      {!link ? (
        labelEl
      ) : (
        <a href={link} onClick={e => e.preventDefault()}>
          {labelEl}
        </a>
      )}

      {children}
    </Flex>
  )
}
