import { AppLibraryType, LeanAppType } from '@wpp-open/core'
import { useEffect, useState } from 'react'
import { addErrorHandler, AppError, removeErrorHandler } from 'single-spa'

import { AppData } from 'constants/apps'
import { CaasAppShort, NativeAppShort } from 'types/apps/leanApps'
import {
  DataFromUrlFull,
  InvalidAppDataFromUrl,
  LoadingAppDataFromUrlFull,
  MicroAppDataFromUrlFull,
  MicroAppFromUrlType,
} from 'types/appState/appState'
import { RenderableLeanAppShort } from 'types/navigation/navigation'
import { isCriticalMicroAppError, unregisterApp } from 'utils/singleSpa'

export const useMicroAppErrorHandler = () => {
  const [isMicroAppError, setIsMicroAppError] = useState(false)

  useEffect(() => {
    const errorHandler = (error: AppError) => {
      if (isCriticalMicroAppError(error)) {
        console.error(`App '${error.appOrParcelName}' has encountered an error and crashed`)

        unregisterApp({ code: error.appOrParcelName })
        setIsMicroAppError(true)
      }
    }

    addErrorHandler(errorHandler)

    return () => {
      removeErrorHandler(errorHandler)
    }
  }, [])

  return {
    isMicroAppError,
  }
}

const mapNativeLeanAppToAppData = (leanApp: NativeAppShort): AppData => ({
  name: leanApp.name,
  code: leanApp.id,
  baseUrl: leanApp.config.osRoute,
  libraryType: AppLibraryType.SystemJS,
  bundleUrl: leanApp.config.bundleUrl,
  workspaceLevels: leanApp.config.requiredHierarchy,
  permission: leanApp.config.viewPermissionName || undefined,
})

interface MatchedLegacy {
  legacyApp: CaasAppShort
  latestApp: null
  leanApp: null
}

interface MatchedLatest {
  legacyApp: null
  latestApp: AppData
  leanApp: null
}

interface MatchedLean {
  legacyApp: null
  latestApp: null
  leanApp: RenderableLeanAppShort | CaasAppShort
}

type MatchedApps = MatchedLegacy | MatchedLatest | MatchedLean

// Typescript cannot correctly map union types
// https://github.com/microsoft/TypeScript/issues/33014
// https://github.com/microsoft/TypeScript/issues/35873
export const mapMicroAppDataToApps = (
  microAppData: Exclude<MicroAppDataFromUrlFull, InvalidAppDataFromUrl<DataFromUrlFull> | LoadingAppDataFromUrlFull>,
): MatchedApps => {
  const { type, app } = microAppData

  switch (type) {
    case MicroAppFromUrlType.LEGACY:
      return {
        legacyApp: app,
        latestApp: null,
        leanApp: null,
      }
    case MicroAppFromUrlType.LATEST:
      return {
        legacyApp: null,
        latestApp: app,
        leanApp: null,
      }
    case MicroAppFromUrlType.LEAN:
      if (app.type === LeanAppType.NATIVE) {
        return {
          legacyApp: null,
          latestApp: mapNativeLeanAppToAppData(app),
          leanApp: null,
        }
      }

      return {
        legacyApp: null,
        latestApp: null,
        leanApp: app,
      }
  }
}
