import { WppDivider, WppIconChevron } from '@platform-ui-kit/components-library-react'
import { Fragment, ReactNode } from 'react'

import { Avatar } from 'components/common/avatar/Avatar'
import { FadeIn } from 'components/common/fadeIn/FadeIn'
import { Flex } from 'components/common/flex/Flex'
import styles from 'components/treeList/hierarchyTreeList/HierarchyTreeList.module.scss'
import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { sortBy } from 'utils/common'
import { NodesPathsMapping, PointerNode, Tree, getPointerNodeHierarchyChildren } from 'utils/mapping/common'

interface Props<T extends Tree> {
  workspacePointerNodes: PointerNode<T>[]
  nodesPathsMapping: NodesPathsMapping<T>
  onParentNodeClick: (pointerNode: PointerNode<T>) => void
  getNodeLogoUrl: (key?: string) => string
  renderNode: (pointerNode: PointerNode<T>) => ReactNode
  emptyState?: ReactNode
  action?: ReactNode
}

export function HierarchyTreeList<T extends Tree>({
  workspacePointerNodes,
  nodesPathsMapping,
  onParentNodeClick,
  renderNode,
  getNodeLogoUrl,
  action,
  emptyState,
}: Props<T>) {
  const { navigationHierarchy } = useTenantAndUserData()

  const displayedParentPointerNodes =
    workspacePointerNodes.length < navigationHierarchy.length
      ? workspacePointerNodes
      : workspacePointerNodes.slice(0, -1)

  const { nodePath } = displayedParentPointerNodes.at(-1)!

  const childrenPointerNodes = sortBy(
    getPointerNodeHierarchyChildren({
      pointerNode: nodesPathsMapping[nodePath],
      navigationHierarchy,
    }),
    ({ node }) => node.name,
  )

  return (
    <Flex as={FadeIn} direction="column" gap={4} data-testid="hierarchy-tree-list">
      <Flex className={styles.parentNodes} direction="column" gap={4} data-testid="hierarchy-tree-list-parent-nodes">
        {displayedParentPointerNodes.map((pointerNode, index) => {
          const { node, nodePath } = pointerNode
          const { name, logoThumbnail } = node
          const isFirstParent = index === 0

          return (
            <TreeListItem key={nodePath} onClick={() => onParentNodeClick(pointerNode)}>
              {isFirstParent && <Avatar slot="left" src={getNodeLogoUrl(logoThumbnail?.key)} name={name} />}
              <span slot="label">{name}</span>
              <WppIconChevron slot="right" direction="down" />
            </TreeListItem>
          )
        })}
        <WppDivider />
      </Flex>

      <Flex
        key={nodePath}
        as={FadeIn}
        className={styles.childrenNodes}
        direction="column"
        gap={4}
        data-testid="hierarchy-tree-list-children-nodes"
      >
        {childrenPointerNodes.length ? (
          <>
            {childrenPointerNodes.map(pointerNode => {
              const { nodePath } = pointerNode

              return <Fragment key={nodePath}>{renderNode(pointerNode)}</Fragment>
            })}
          </>
        ) : (
          <>{!!emptyState && <div className={styles.emptyState}>{emptyState}</div>}</>
        )}
        {action}
      </Flex>
    </Flex>
  )
}
