import { useLocation } from 'react-router-dom'

import { excludeFalsy } from 'utils/common'

export const authRoutes = {
  login: 'login',
  authCallback: 'authentication/callback',
}

export const getTargetUrl = (location: ReturnType<typeof useLocation>) =>
  [location.pathname, location.search, location.hash].filter(excludeFalsy).join('')
