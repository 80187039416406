import { Portal } from 'components/common/portal/Portal'
import { Script } from 'components/common/script/Script'
import { useIntercomState } from 'components/intercom/utils'
import { environment } from 'environment'
import { useWidgetsState } from 'hooks/useWidgetsState'

declare global {
  interface Window {
    Intercom?: (command: string, props?: any) => void
    intercomSettings?: { [k: string]: string }
    intercomQueue?: [string, {}][]
  }
}

export const IntercomWidget = () => {
  const [, setIntercomState] = useIntercomState()
  const { intercomEnabled } = useWidgetsState()

  if (!intercomEnabled) {
    return null
  }

  return (
    <Portal target={document.head}>
      <Script
        async
        onLoad={() => {
          window.Intercom?.('boot', {
            api_base: environment.INTERCOM_API_BASE,
            app_id: environment.INTERCOM_APP_ID,
          })
          setIntercomState(true)
        }}
        src={`https://widget.intercom.io/widget/${environment.INTERCOM_APP_ID}`}
      />
    </Portal>
  )
}
