import { MayBeNull, NavigationTree } from '@wpp-open/core'
import { createContext, PropsWithChildren, useContext, useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'

import { useMiroConfigStatusApi } from 'api/miro/queries/useMiroConfigStatusApi'
import { getActiveNodePathFromAppState } from 'layout/header/headerNavigationProvider/utils'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useData } from 'providers/data/DataProvider'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { MiroConfigStatusResponse } from 'types/miro'
import { NodesPathsMapping, useNodesPathsMapping } from 'utils/mapping/common'

export interface HeaderNavigationContextValue {
  nodesPathsMapping: NodesPathsMapping<NavigationTree>
  activeNodePath: string
  miroConfigStatus: MayBeNull<MiroConfigStatusResponse>
}

const HeaderNavigationContext = createContext<HeaderNavigationContextValue>(null!)
export const useHeaderNavigation = () => useContext(HeaderNavigationContext)

export const HeaderNavigationProvider = ({ children }: PropsWithChildren<{}>) => {
  const { pathname } = useLocation()
  const [searchParams] = useSearchParams()
  const { data: miroConfigStatus } = useMiroConfigStatusApi()

  const { currentTenant } = useTenantAndUserData()
  const { navigationTree } = useData()
  const { microAppData } = useAppState()
  const nodesPathsMapping = useNodesPathsMapping(navigationTree)

  const instanceId = searchParams.get('instanceId')

  const activeNodePath = useMemo(
    () =>
      getActiveNodePathFromAppState({
        navigationTree,
        nodesPathsMapping,
        microAppData,
        instanceId,
        pathname,
        currentTenant,
      }),
    [navigationTree, nodesPathsMapping, microAppData, pathname, currentTenant, instanceId],
  )

  const value: HeaderNavigationContextValue = {
    nodesPathsMapping,
    activeNodePath,
    miroConfigStatus,
  }

  return <HeaderNavigationContext.Provider value={value}>{children}</HeaderNavigationContext.Provider>
}
