import { Outlet } from 'react-router-dom'

import { NotFoundError } from 'components/renderError'
import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'

export const IsCocaColaTenant = () => {
  const { currentTenant } = useTenantAndUserData()

  return currentTenant.name === 'TCCC' ? <Outlet /> : <NotFoundError />
}
