import { WppTypography } from '@platform-ui-kit/components-library-react'
import { PropsWithChildren } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/landing/components/hero/Hero.module.scss'

interface Props extends PropsWithChildren {
  title: string
  subtitle: string
}
export const Hero = ({ title, subtitle }: Props) => {
  return (
    <Flex justify="center" align="center" className={styles.container}>
      <Flex className={styles.wrapper} direction="column" justify="center" align="start" gap={16}>
        <WppTypography type="xl-heading" className={styles.header}>
          {title}
        </WppTypography>
        <WppTypography type="l-body">{subtitle}</WppTypography>
      </Flex>
    </Flex>
  )
}
