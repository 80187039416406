import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import productsData from 'pages/landing/assets/products/jsons/products.json'
import { BenefitSection } from 'pages/landing/components/benefitSection/BenefitSection'
import { Button } from 'pages/landing/components/button/Button'
import { Hero } from 'pages/landing/components/hero/Hero'
import { landingRoutes } from 'pages/landing/const'
import styles from 'pages/landing/productsPage/Products.module.scss'

export const Products = () => {
  const { data: products } = productsData

  return (
    <Flex direction="column" align="center" className={styles.container} gap={80}>
      <Hero
        title="Our APP Portfolio"
        subtitle="Access the Marketplace for a diverse range of applications and services that elevate your work and deliver better results for clients."
      />

      <Flex direction="column" align="center" className={styles.contentWrapper} gap={80}>
        <Flex direction="column" gap={16} className={styles.content}>
          <WppTypography type="3xl-heading">Some of Our Hero Apps</WppTypography>
          <WppTypography type="l-body">
            Here you can see a sample of some of our hero applications available to use in Open.
          </WppTypography>
        </Flex>

        {Object.values(products).map((item, index) => (
          <BenefitSection benefit={item.benefit} key={item.benefit.title} reverse={!(index % 2)} />
        ))}
      </Flex>

      <Flex className={styles.discoverWrapper} justify="center" align="center" gap={180}>
        <Flex className={styles.discoverContent} align="center" justify="between">
          <WppTypography type="3xl-heading">Discover the full range of apps in our Marketplace</WppTypography>

          <Button size="m" component={Link} to={`${landingRoutes.root}/${landingRoutes.selectOs}`}>
            <WppTypography type="l-midi" className={styles.discoverButtonText}>
              Access Open
            </WppTypography>
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}
