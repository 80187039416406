import clsx from 'clsx'
import { PropsWithChildren, Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'

import styles from 'app/secureRoutes/SecureRoutes.module.scss'
import { useIntercomUser } from 'components/intercom/utils'
import { RenderErrorBoundary, RenderErrorType } from 'components/renderError'
import { RenderErrorHeight } from 'components/renderError/boundary/RenderErrorBoundary'
import { LoadingPage } from 'layout/loadingPage/LoadingPage'
import { SecureLayout } from 'layout/SecureLayout'
import { IsCentralTenant } from 'pages/centralTenant/support/utils/IsCentralTenant'
import { IsDemoTenant } from 'pages/demoTenant/utils/IsDemoTenant'
import { CocaColaMatter } from 'pages/home/components/cocaColaMatter/CocaColaMatter'
import { IsCocaColaTenant } from 'pages/home/components/cocaColaMatter/utils'
import { IsPlaygroundTenant } from 'pages/playground/utils/IsPlaygroundTenant'
import { useAppState } from 'providers/appState/AppStateProvider'
import { useMicroAppsContext } from 'providers/microApps/MicroAppsProvider'
import { MicroAppFromUrlType } from 'types/appState/appState'

const Benefits = lazy(() => import('pages/benefits/Benefits'))
const Migrations = lazy(() => import('pages/migrations/Migrations'))
const ProcessManagement = lazy(() => import('pages/processManagement/ProcessManagement'))
const Central = lazy(() => import('pages/centralTenant/Central'))
const CxAdoptionOverview = lazy(() => import('pages/cxAdoptionOverview/CxAdoptionOverview'))
const CxTechEnablement = lazy(() => import('pages/cxTechEnablement/CxTechEnablement'))
const OpcoConvergenceOverview = lazy(() => import('pages/opcoConvergenceOverview/OpcoConvergenceOverview'))
const UsersSupportFramework = lazy(() => import('pages/usersSupportFramework/UsersSupportFramework'))
const NestleDemo = lazy(() => import('pages/demoTenant/nestleDemo/NestleDemo'))
const PfizerDemo = lazy(() => import('pages/demoTenant/pfizerDemo/PfizerDemo'))
const SpotifyDemo = lazy(() => import('pages/demoTenant/spotifyDemo/SpotifyDemo'))
const VolkswagenDemo = lazy(() => import('pages/demoTenant/volkswagenDemo/VolkswagenDemo'))
const BeiersdorfDemo = lazy(() => import('pages/demoTenant/beiersdorfDemo/BeiersdorfDemo'))
const ModelCase = lazy(() => import('pages/model/ModelCase'))
const ExperienceLoop = lazy(() => import('pages/playground/pages/experienceLoop/ExperienceLoop'))
const Profile = lazy(() => import('pages/playground/pages/profile/Profile'))
const Blueprint = lazy(() => import('pages/playground/pages/blueprint/Blueprint'))
const Blueprints = lazy(() => import('pages/playground/pages/blueprints/Blueprints'))
const Staffing = lazy(() => import('pages/playground/pages/staffing/Staffing'))
const Workfront = lazy(() => import('pages/playground/pages/workfront/Workfront'))

export const PitchRoutes = ({ children }: PropsWithChildren<{}>) => {
  const { isMicroAppActive } = useMicroAppsContext()
  const { microAppData } = useAppState()

  const isLoading = microAppData.type === MicroAppFromUrlType.LOADING

  useIntercomUser()
  return (
    <SecureLayout>
      {/* Content-related issues boundary. */}
      <RenderErrorBoundary
        catchErrors={[
          RenderErrorType.DataIsNotAvailable,
          RenderErrorType.ForbiddenPage,
          RenderErrorType.NotFound,
          RenderErrorType.CriticalError,
        ]}
        height={RenderErrorHeight.FullPage}
      >
        {/** Note: In some cases single-spa routing happens faster than React re-renders,
       so this MicroApp container is always available */}
        <div
          className={clsx(styles.microAppContainer, {
            [styles.hidden]: !isMicroAppActive || isLoading,
          })}
          id="pitch"
        />

        {isLoading ? (
          <LoadingPage />
        ) : (
          <Suspense>
            <Routes>
              <Route path="benefits" element={<IsCentralTenant />}>
                <Route index element={<Benefits />} />
              </Route>
              <Route path="benefits" element={<IsCentralTenant />}>
                <Route index element={<Benefits />} />
              </Route>
              <Route path="experience-loop" element={<IsPlaygroundTenant />}>
                <Route index element={<ExperienceLoop />} />
              </Route>
              <Route path="profile" element={<IsPlaygroundTenant />}>
                <Route index element={<Profile />} />
              </Route>
              <Route path="blueprint" element={<IsPlaygroundTenant />}>
                <Route index element={<Blueprint />} />
              </Route>
              <Route path="blueprints" element={<IsPlaygroundTenant />}>
                <Route index element={<Blueprints />} />
              </Route>
              <Route path="staffing" element={<IsPlaygroundTenant />}>
                <Route index element={<Staffing />} />
              </Route>
              <Route path="workfront" element={<IsPlaygroundTenant />}>
                <Route index element={<Workfront />} />
              </Route>
              <Route path="model">
                <Route index element={<ModelCase />} />
              </Route>
              <Route path="process-management" element={<IsCentralTenant />}>
                <Route index path="*" element={<ProcessManagement />} />
              </Route>
              <Route path="central">
                <Route index path="*" element={<Central />} />
              </Route>
              <Route path="cx-adoption-overview" element={<IsCentralTenant />}>
                <Route index path="*" element={<CxAdoptionOverview />} />
              </Route>
              <Route path="cx-tech-enablement" element={<IsCentralTenant />}>
                <Route index path="*" element={<CxTechEnablement />} />
              </Route>
              <Route path="opco-convergence-overview" element={<IsCentralTenant />}>
                <Route index path="*" element={<OpcoConvergenceOverview />} />
              </Route>
              <Route path="users-support-framework" element={<IsCentralTenant />}>
                <Route index path="*" element={<UsersSupportFramework />} />
              </Route>
              <Route path="product-migration" element={<IsCentralTenant />}>
                <Route index path="*" element={<Migrations />} />
              </Route>
              <Route path="nestle" element={<IsDemoTenant />}>
                <Route index path="*" element={<NestleDemo />} />
              </Route>
              <Route path="moments-that-matter" element={<IsCocaColaTenant />}>
                <Route index element={<CocaColaMatter />} />
              </Route>
              <Route path="pfizer" element={<IsDemoTenant />}>
                <Route index path="*" element={<PfizerDemo />} />
              </Route>
              <Route path="volkswagen" element={<IsDemoTenant />}>
                <Route index path="*" element={<VolkswagenDemo />} />
              </Route>
              <Route path="beiersdorf" element={<IsDemoTenant />}>
                <Route index path="*" element={<BeiersdorfDemo />} />
              </Route>
              <Route path="spotify" element={<IsDemoTenant />}>
                <Route index path="*" element={<SpotifyDemo />} />
              </Route>
              <Route path="*" element={children} />
            </Routes>
          </Suspense>
        )}
      </RenderErrorBoundary>
    </SecureLayout>
  )
}
