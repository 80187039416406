import { WppButton } from '@platform-ui-kit/components-library-react'
import { LeanAppsMappingMiroBoardInstanceNode } from '@wpp-open/core'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { BaseErrorState } from 'components/renderError/errorState/base/BaseErrorState'
import { SvgForbidden } from 'components/svg/renderError/SvgForbidden'
import { useData } from 'providers/data/DataProvider'
import { useToast } from 'providers/toast/ToastProvider'
import { routesManager } from 'utils/routesManager'

export const MiroRestrictedState = ({ instanceId }: { instanceId: string }) => {
  const { t } = useTranslation()
  const { enqueueToast } = useToast()
  const { navigationTree } = useData()
  const app = navigationTree.leanAppsMapping[instanceId] as LeanAppsMappingMiroBoardInstanceNode

  useEffect(() => {
    enqueueToast({
      header: t('os.lean_app_page.miro_restricted_state.toast_title'),
      message: t('os.lean_app_page.miro_restricted_state.toast_text'),
      type: 'error',
    })
  }, [t, enqueueToast])

  return (
    <BaseErrorState
      illustration={<SvgForbidden />}
      mainTitle={t('os.error_state.forbidden_page.main_title')}
      subTitle={t('os.lean_app_page.miro_restricted_state.description', { email: app.owner })}
      actions={
        <Link to={routesManager.internalPages.home.root()}>
          <WppButton data-testid="go-home-button">{t('os.error_state.forbidden_page.primary_button')}</WppButton>
        </Link>
      }
    />
  )
}
