import clsx from 'clsx'
import { Fragment } from 'react'

import { Script } from 'components/common/script/Script'
import styles from 'pages/leanApp/tableau/TableauLeanApp.module.scss'
import { AllwynTableauLeanApps, PfizerTableuLeanApps } from 'pages/leanApp/tableau/utils'
import { LinkEmbeddedAppShort } from 'types/apps/leanApps'

interface Props {
  app: LinkEmbeddedAppShort
}

export const TableauLeanApp = ({ app }: Props) => {
  const { objects, script } = tableauLeanAppConfig(app)

  return (
    <Fragment key={app.id}>
      <Script src={script} />

      <div className={clsx('tableauPlaceholder', styles.placeholder)}>
        <object className="tableauViz" width="100%" height="100%">
          {objects.map(object => (
            <param key={object.name} name={object.name} value={object.value} />
          ))}
        </object>
      </div>
    </Fragment>
  )
}

const tableauLeanAppConfig = (app: LinkEmbeddedAppShort) => {
  switch (app.id) {
    case PfizerTableuLeanApps.AdienceEngineDashboard:
      return {
        objects: [
          { name: 'host_url', value: 'https://public.tableau.com/' },
          { name: 'embed_code_version', value: '3' },
          { name: 'site_root', value: '' },
          { name: 'name', value: 'Pfizer-NurtecUS/NURTEC' },
          { name: 'tabs', value: 'no' },
          { name: 'toolbar', value: 'yes' },
          {
            name: 'static_image',
            value: 'https://public.tableau.com/static/images/Pf/Pfizer-NurtecUS/NURTEC/1.png',
          },
          { name: 'animate_transition', value: 'yes' },
          { name: 'display_static_image', value: 'yes' },
          { name: 'display_spinner', value: 'yes' },
          { name: 'display_overlay', value: 'yes' },
          { name: 'display_count', value: 'yes' },
          { name: 'language', value: 'en-GB' },
        ],
        script: 'https://public.tableau.com/javascripts/api/viz_v1.js',
      }
    case PfizerTableuLeanApps.AdienceEngineDashboardInlytaUS:
      return {
        objects: [
          { name: 'host_url', value: 'https://public.tableau.com/' },
          { name: 'embed_code_version', value: '3' },
          { name: 'site_root', value: '' },
          { name: 'name', value: 'Pfizer-Inlyta/Inlyta' },
          { name: 'tabs', value: 'no' },
          { name: 'toolbar', value: 'yes' },
          {
            name: 'static_image',
            value: 'https://public.tableau.com/static/images/Pf/Pfizer-Inlyta/Inlyta/1.png',
          },
          { name: 'animate_transition', value: 'yes' },
          { name: 'display_static_image', value: 'yes' },
          { name: 'display_spinner', value: 'yes' },
          { name: 'display_overlay', value: 'yes' },
          { name: 'display_count', value: 'yes' },
          { name: 'language', value: 'en-GB' },
        ],
        script: 'https://public.tableau.com/javascripts/api/viz_v1.js',
      }
    case PfizerTableuLeanApps.AdienceEngineDashboardNurtecUK:
      return {
        objects: [
          { name: 'host_url', value: 'https://public.tableau.com/' },
          { name: 'embed_code_version', value: '3' },
          { name: 'site_root', value: '' },
          { name: 'name', value: 'Pfizer-VyduraUK/MigraineHCP' },
          { name: 'tabs', value: 'no' },
          { name: 'toolbar', value: 'yes' },
          {
            name: 'static_image',
            value: 'https://public.tableau.com/static/images/Pf/Pfizer-VyduraUK/MigraineHCP/1.png',
          },
          { name: 'animate_transition', value: 'yes' },
          { name: 'display_static_image', value: 'yes' },
          { name: 'display_spinner', value: 'yes' },
          { name: 'display_overlay', value: 'yes' },
          { name: 'display_count', value: 'yes' },
          { name: 'language', value: 'en-GB' },
        ],
        script: 'https://public.tableau.com/javascripts/api/viz_v1.js',
      }
    case PfizerTableuLeanApps.AdienceEngineDashboardRittleUS:
      return {
        objects: [
          { name: 'host_url', value: 'https://public.tableau.com/' },
          { name: 'embed_code_version', value: '3' },
          { name: 'path', value: 'shared/NDQZF3J6G' },
          { name: 'toolbar', value: 'yes' },
          {
            name: 'static_image',
            value: 'https://public.tableau.com/static/images/ND/NDQZF3J6G/1.png',
          },
          { name: 'animate_transition', value: 'yes' },
          { name: 'display_static_image', value: 'yes' },
          { name: 'display_spinner', value: 'yes' },
          { name: 'display_overlay', value: 'yes' },
          { name: 'display_count', value: 'yes' },
          { name: 'language', value: 'en-GB' },
        ],
        script: 'https://public.tableau.com/javascripts/api/viz_v1.js',
      }
    case PfizerTableuLeanApps.GlobalDataAvailabilityMap:
      return {
        objects: [
          { name: 'host_url', value: 'https://public.tableau.com/' },
          { name: 'embed_code_version', value: '3' },
          { name: 'path', value: 'shared/3456HPDWP' },
          { name: 'toolbar', value: 'yes' },
          { name: 'static_image', value: 'https://public.tableau.com/static/images/34/3456HPDWP/1.png' },
          { name: 'animate_transition', value: 'yes' },
          { name: 'display_static_image', value: 'yes' },
          { name: 'display_spinner', value: 'yes' },
          { name: 'display_overlay', value: 'yes' },
          { name: 'display_count', value: 'yes' },
          { name: 'language', value: 'en-US' },
        ],
        script: 'https://public.tableau.com/javascripts/api/viz_v1.js',
      }
    case AllwynTableauLeanApps.MissionControl:
      return {
        objects: [
          { name: 'host_url', value: 'https://tableau-cloud.insidemedia.net/' },
          { name: 'embed_code_version', value: '3' },
          { name: 'site_root', value: '/t/Signal' },
          { name: 'name', value: 'AllwynDashboardProposal/mediadelivery' },
          { name: 'tabs', value: 'no' },
          { name: 'toolbar', value: 'yes' },
          { name: 'showAppBanner', value: 'false' },
        ],
        script: 'https://tableau-cloud.insidemedia.net/javascripts/api/viz_v1.js',
      }
    default:
      return {
        objects: [
          { name: 'host_url', value: 'https://tableau-cloud.insidemedia.net/' },
          { name: 'embed_code_version', value: '3' },
          { name: 'site_root', value: '/t/WavemakerRedesign' },
          { name: 'name', value: 'Modelo_CultureGraph/AudienceProfile' },
          { name: 'tabs', value: 'yes' },
          { name: 'toolbar', value: 'yes' },
          { name: 'showAppBanner', value: 'false' },
        ],
        script: 'https://tableau-cloud.insidemedia.net/javascripts/api/viz_v1.js',
      }
  }
}
