import { UserDetails, MayBeNull, AnalyticsActionType } from '@wpp-open/core'
import { useEffect } from 'react'

import { useTenantAndUserData } from 'providers/tenantAndUserData/TenantAndUserDataProvider'
import { UserAssignedRoles } from 'types/users/users'
import { identifyUser, trackAnalytics } from 'utils/analytics'
import { noop } from 'utils/common'

export const useInitSegmentAnalytics = process.env.DEV
  ? noop
  : ({ userDetails, roles }: { userDetails: MayBeNull<UserDetails>; roles: UserAssignedRoles[] }) => {
      const { currentTenant } = useTenantAndUserData()

      useEffect(() => {
        if (userDetails && currentTenant && roles) {
          const init = async () => {
            await identifyUser({ userDetails, currentTenant, roles })

            trackAnalytics({
              type: AnalyticsActionType.action,
              payload: `Tenant ${currentTenant.name}`,
            })
          }

          init()
        }
      }, [userDetails, currentTenant, roles])
    }
