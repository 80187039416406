import { WppTypography } from '@platform-ui-kit/components-library-react'
import { Link } from 'react-router-dom'

import { Flex } from 'components/common/flex/Flex'
import brands from 'pages/landing/assets/landing/jsons/brands.json'
import { Button } from 'pages/landing/components/button/Button'
import { landingRoutes } from 'pages/landing/const'
import styles from 'pages/landing/homePage/transformingWPPsImpact/TransformingWPPsImpact.module.scss'
import { TransformingWPPsImpactCarousel } from 'pages/landing/homePage/transformingWPPsImpact/transformingWPPsImpactCarousel/TransformingWPPsImpactCarousel'

export const TransformingWPPsImpact = () => {
  return (
    <Flex direction="column" gap={40} className={styles.wrapper} data-testid="transforming-impact-section">
      <Flex direction="column" align="center" gap={24}>
        <WppTypography type="4xl-display">Transforming WPP’s impact</WppTypography>
        <WppTypography type="l-body">Inspiring world-class marketing organisations</WppTypography>
      </Flex>
      <Flex direction="column" align="center" gap={40}>
        <Flex gap={32} className={styles.brands} align="center">
          {brands.data.map(({ logo }, id) => (
            <img src={logo} key={id} />
          ))}
        </Flex>
        <div className={styles.overlay} />
        <TransformingWPPsImpactCarousel />
      </Flex>
      <Flex direction="row" justify="center">
        <Button size="m" component={Link} to={landingRoutes.successStories}>
          <WppTypography type="l-midi">See more success stories</WppTypography>
        </Button>
      </Flex>
    </Flex>
  )
}
