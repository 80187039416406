import { useNavigate } from 'react-router-dom'

import { TreeListItem } from 'components/treeList/treeListItem/TreeListItem'
import { useHeaderNavigation } from 'layout/header/headerNavigationProvider/HeaderNavigationProvider'
import { useAppState } from 'providers/appState/AppStateProvider'
import { getAppStableId } from 'providers/appState/utils'

interface Props {
  appStableId: string
  appName: string
  href: string
  parentNodePath: string
}

export const AppMenuItem = ({ appStableId, appName, href, parentNodePath }: Props) => {
  const navigate = useNavigate()
  const { activeNodePath } = useHeaderNavigation()
  const { microAppData, closeNavigation } = useAppState()

  const isChecked = activeNodePath === parentNodePath && appStableId === getAppStableId(microAppData)

  return (
    <TreeListItem
      checked={isChecked}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onClick={e => {
        if (!e.currentTarget.checked) {
          navigate(href)
          closeNavigation()
        }
      }}
    >
      <span slot="label">{appName}</span>
    </TreeListItem>
  )
}
