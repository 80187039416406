import { Flex } from 'components/common/flex/Flex'
import { GetStartedSteps } from 'pages/landing/components/getStartedSteps/GetStartedSteps'
import { Benefits } from 'pages/landing/homePage/benefits/Benefits'
import { FrequentlyAskedQuestions } from 'pages/landing/homePage/frequentlyAskedQuestions/FrequentlyAskedQuestions'
import { Hero } from 'pages/landing/homePage/hero/Hero'
import { HolisticSolutions } from 'pages/landing/homePage/holisticSolutions/HolisticSolutions'
import styles from 'pages/landing/homePage/Home.module.scss'
import { TransformingWPPsImpact } from 'pages/landing/homePage/transformingWPPsImpact/TransformingWPPsImpact'

export const Home = () => {
  return (
    <Flex direction="column" align="center" className={styles.homepage} gap={120}>
      <Hero />

      <Benefits />

      <HolisticSolutions />

      <TransformingWPPsImpact />

      <GetStartedSteps />

      <FrequentlyAskedQuestions />
    </Flex>
  )
}
