import { useLayoutEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { About } from 'pages/landing/aboutPage/About'
import { Layout } from 'pages/landing/components/layout/Layout'
import { landingRoutes } from 'pages/landing/const'
import { Contact } from 'pages/landing/contactPage/Contact'
import { Home } from 'pages/landing/homePage/Home'
import { Offer } from 'pages/landing/offerPage/Offer'
import { Products } from 'pages/landing/productsPage/Products'
import { Stories } from 'pages/landing/storiesPage/Stories'
import { Terms } from 'pages/landing/termsPage/Terms'

export const LandingRoutes = () => {
  const location = useLocation()
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  return (
    <Layout>
      <Routes>
        <Route index element={<Home />} />
        <Route path={landingRoutes.aboutOpen} element={<About />} />
        <Route path={landingRoutes.whatWeOffer} element={<Offer />} />
        <Route path={landingRoutes.ourProducts} element={<Products />} />
        <Route path={landingRoutes.successStories} element={<Stories />} />
        <Route path={landingRoutes.terms} element={<Terms />} />
        <Route path={landingRoutes.contact} element={<Contact />} />
        <Route
          path="*"
          element={
            <Navigate
              to={
                // Root may be an empty string
                landingRoutes.root || '/'
              }
            />
          }
        />
      </Routes>
    </Layout>
  )
}
