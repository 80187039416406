import { createAsyncStoragePersister } from '@tanstack/query-async-storage-persister'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client'
import { get, set, del } from 'idb-keyval'
import { PropsWithChildren, useMemo, useState } from 'react'

import { REACT_QUERY_DEV_OFFLINE_CACHE_KEY, localDevConfig } from 'environment/dev'
import { queryClient } from 'providers/osQueryClient/utils'
import { join } from 'utils/common'

const persister = createAsyncStoragePersister({
  storage: {
    getItem: async key => (await get(key)) || null,
    setItem: (key, value) => set(key, value),
    removeItem: key => del(key),
  },
  key: REACT_QUERY_DEV_OFFLINE_CACHE_KEY,
  throttleTime: 10000,
})

export const DevQueryClientProvider = ({ children }: PropsWithChildren<{}>) => {
  const [tabSessionId] = useState(() => {
    let uuid = window.sessionStorage.getItem('tab-session-id')

    if (!uuid) {
      uuid = crypto.randomUUID()
      window.sessionStorage.setItem('tab-session-id', uuid)
    }

    return uuid
  })

  const buster = useMemo(() => join([...Object.values(localDevConfig), tabSessionId], '/'), [tabSessionId])

  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister, buster }}>
      <ReactQueryDevtools initialIsOpen={false} />
      {children}
    </PersistQueryClientProvider>
  )
}
