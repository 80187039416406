import {
  HierarchyContainerNode,
  HierarchyNode,
  LeanAppNode,
  LeanAppsMapping,
  AppsMapping,
  ContainerNodeType,
} from './common'
import { MayBeNull } from '../common'

export const ProjectNodeType = 'PROJECT'
export type ProjectNodeType = typeof ProjectNodeType

export const ProjectsContainerNodeId = 'project-container-node'
export type ProjectsContainerNodeId = typeof ProjectsContainerNodeId

export interface ProjectNode {
  azId: null
  type: ProjectNodeType
  name: string
  logoOriginal: null
  logoThumbnail: null
  children: string[]
  apps: readonly []
}

export interface ProjectsContainerNode {
  azId: null
  type: ContainerNodeType
  name: ProjectsContainerNodeId
  logoOriginal: null
  logoThumbnail: null
  children: string[]
  apps: readonly []
}

export type NavigationTreeNode =
  | HierarchyNode
  | LeanAppNode
  | ProjectNode
  | ProjectsContainerNode
  | HierarchyContainerNode

export type NavigationTreeMapping = Record<string, NavigationTreeNode>

export interface ProjectsMappingNode {
  contextWorkspace: MayBeNull<string>
  description: MayBeNull<string>
  endDate: MayBeNull<string>
  id: string
  name: string
  ownerId: string
  startDate: MayBeNull<string>
  status: string
  type: string
}

export type ProjectsMapping = Record<string, ProjectsMappingNode>

export interface NavigationTree {
  rootId: string
  mapping: NavigationTreeMapping
  appsMapping: AppsMapping
  leanAppsMapping: LeanAppsMapping
  projectsMapping: ProjectsMapping
}

export interface NavigationNode {
  id: string
  children: string[]
}
