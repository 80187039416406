import { AnimatePresence, motion } from 'framer-motion'
import { useLockBodyScroll, useKey } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { Portal } from 'components/common/portal/Portal'
import { NavigationMenuContent } from 'components/navigationMenu/navigationMenuContent/NavigationMenuContent'
import styles from 'components/navigationMenu/navigationMenuSidebar/NavigationMenuSidebar.module.scss'
import { useAppState } from 'providers/appState/AppStateProvider'

export const NavigationMenuSidebar = () => {
  const { isNavigationOpen, closeNavigation } = useAppState()

  useLockBodyScroll(isNavigationOpen)

  useKey(
    e => isNavigationOpen && e.key === 'Escape',
    () => {
      closeNavigation()
    },
    {
      options: {
        passive: true,
      },
    },
  )

  return (
    <Portal>
      <AnimatePresence>
        {isNavigationOpen && (
          <Flex
            className={styles.backdrop}
            onClick={e => {
              e.stopPropagation()

              if (e.target === e.currentTarget) {
                closeNavigation()
              }
            }}
          >
            <motion.div
              className={styles.sidebar}
              transition={{ duration: 0.5 }}
              variants={{
                out: { x: '-100%', opacity: 0.3 },
                in: { x: 0, opacity: 1 },
              }}
              initial="out"
              animate="in"
              exit="out"
            >
              <NavigationMenuContent />
            </motion.div>
          </Flex>
        )}
      </AnimatePresence>
    </Portal>
  )
}
