import { MayBeNull } from '@wpp-open/core'

import { AppData } from 'constants/apps'
import { CaasAppShort, NativeAppShort } from 'types/apps/leanApps'
import { RenderableLeanAppShort } from 'types/navigation/navigation'
import { Project } from 'types/projects/project'
import { ProjectCanvasFluid, ProjectCanvasLinear } from 'types/projects/projectCanvas'
import { ProjectCanvasApplication, ProjectPhase } from 'types/projects/projectPhase'
import { WorkspaceLevels } from 'utils/workspace'

export enum MicroAppFromUrlType {
  LEAN = 'lean',
  LEGACY = 'legacy',
  LATEST = 'latest',
  INVALID = 'invalid',
  LOADING = 'loading',
}

export interface BaseDataFromUrl {
  currentBaseUrl: string
}

export interface DataFromUrlShort extends BaseDataFromUrl {
  workspaceAzId?: string
  projectId?: string
  projectItemId?: string
}

export interface DataFromUrlFull extends BaseDataFromUrl {
  workspaceLevels: WorkspaceLevels
  project: MayBeNull<Project>
  projectCanvas: MayBeNull<ProjectCanvasLinear | ProjectCanvasFluid>
  projectPhase: MayBeNull<ProjectPhase>
  projectItem: MayBeNull<ProjectCanvasApplication>
}

export type LegacyAppDataFromUrl<T extends BaseDataFromUrl> = T & {
  type: MicroAppFromUrlType.LEGACY
  app: CaasAppShort
}

export type AppDataFromUrl<T extends BaseDataFromUrl> = T & {
  type: MicroAppFromUrlType.LATEST
  app: AppData
}

export type LeanAppDataFromUrl<T extends BaseDataFromUrl> = T & {
  type: MicroAppFromUrlType.LEAN
  app: RenderableLeanAppShort | CaasAppShort | NativeAppShort
}

export type InvalidAppDataFromUrl<T extends BaseDataFromUrl> = T & {
  type: MicroAppFromUrlType.INVALID
  app: null
}

export type MicroAppDataFromUrlShort =
  | LegacyAppDataFromUrl<DataFromUrlShort>
  | AppDataFromUrl<DataFromUrlShort>
  | LeanAppDataFromUrl<DataFromUrlShort>
  | InvalidAppDataFromUrl<DataFromUrlShort>

// Used when short data from url is being validated
export interface LoadingAppDataFromUrlFull extends DataFromUrlFull {
  type: MicroAppFromUrlType.LOADING
  app: null
}

export type MicroAppDataFromUrlFull =
  | LegacyAppDataFromUrl<DataFromUrlFull>
  | AppDataFromUrl<DataFromUrlFull>
  | LeanAppDataFromUrl<DataFromUrlFull>
  | InvalidAppDataFromUrl<DataFromUrlFull>
  | LoadingAppDataFromUrlFull
