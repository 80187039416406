import { LeanAppType, LeanAppsMappingNode, MayBeNull } from '@wpp-open/core'

import { LeanApp, LeanAppShort, NativeAppShort } from 'types/apps/leanApps'
import { MiroConfigStatus, MiroConfigStatusResponse } from 'types/miro'
import { RenderableLeanApp, RenderableLeanAppShort } from 'types/navigation/navigation'

export const isRenderableLeanAppShortOrNative = (
  value: LeanAppShort,
): value is RenderableLeanAppShort | NativeAppShort =>
  isRenderableLeanAppShort(value) || value.type === LeanAppType.NATIVE

export const isRenderableLeanAppShort = (value: LeanAppShort): value is RenderableLeanAppShort =>
  value.type !== LeanAppType.LINK_DETACHED && value.type !== LeanAppType.CAAS && value.type !== LeanAppType.NATIVE

export const isRenderableLeanApp = (value: LeanApp): value is RenderableLeanApp =>
  value.type !== LeanAppType.LINK_DETACHED && value.type !== LeanAppType.CAAS && value.type !== LeanAppType.NATIVE

export const isMiroWarningVisible = ({
  leanAppMappingNode,
  miroConfigStatus,
}: {
  leanAppMappingNode: LeanAppsMappingNode
  miroConfigStatus: MayBeNull<MiroConfigStatusResponse>
}) => {
  const { type, initialized } = leanAppMappingNode

  return (
    type === LeanAppType.MIRO_BOARD_INSTANCE && !initialized && miroConfigStatus?.status === MiroConfigStatus.NotSet
  )
}
