import { WppIconApp, WppListItem } from '@platform-ui-kit/components-library-react'
import { useNavigate } from 'react-router-dom'

import { AppData } from 'constants/apps'
import { useAppState } from 'providers/appState/AppStateProvider'
import { getAppStableId } from 'providers/appState/utils'
import { getAppUrl } from 'utils/navigation'

interface Props {
  app: AppData
  workspaceAzId?: string
}

export const BreadcrumbMenuNativeAppItem = ({ app, workspaceAzId }: Props) => {
  const navigate = useNavigate()
  const { microAppData } = useAppState()

  const isChecked = app.code === getAppStableId(microAppData)
  const href = getAppUrl(app.baseUrl, workspaceAzId)

  return (
    <WppListItem
      checked={isChecked}
      linkConfig={{
        href,
        onClick: e => e.preventDefault(),
      }}
      onWppChangeListItem={() => {
        if (!isChecked) {
          navigate(href)
        }
      }}
      data-testid="breadcrumbs-menu-app-item"
    >
      <WppIconApp slot="left" />
      <span slot="label">{app.name}</span>
    </WppListItem>
  )
}
