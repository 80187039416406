import { AnalyticsActionType, AppLibraryType } from '@wpp-open/core'
import {
  start,
  getAppNames,
  getAppStatus,
  registerApplication,
  unregisterApplication,
  AppError,
  LifeCycles,
  LOAD_ERROR,
  SKIP_BECAUSE_BROKEN,
} from 'single-spa'

import { AppConfig } from 'types/common/singleSpa'
import { trackAnalytics } from 'utils/analytics'

const isRegistered = (code: string) => getAppNames().includes(code)

export const loadPageScript = (url: string): Promise<any> =>
  new Promise((resolve, reject) => {
    const script = document.createElement('script')
    script.src = url
    script.onload = resolve

    script.onerror = () => {
      script.remove()
      reject()
    }

    document.head.appendChild(script)
  })

const getAppLoader =
  ({ libraryType, windowLibraryName, appUrl }: AppConfig) =>
  (): Promise<LifeCycles> => {
    switch (libraryType) {
      case AppLibraryType.SystemJS: {
        return System.import(appUrl)
      }

      case AppLibraryType.Window: {
        const libraryStorage = window as unknown as Record<string, LifeCycles>

        if (libraryStorage[windowLibraryName!]) {
          return Promise.resolve(libraryStorage[windowLibraryName!])
        }

        return loadPageScript(appUrl).then(() => libraryStorage[windowLibraryName!])
      }

      case AppLibraryType.ESM: {
        return import(/* webpackIgnore: true */ appUrl)
      }

      default:
        throw new Error('Unsupported libraryType')
    }
  }

export const registerApp = (appConfig: AppConfig) => {
  const { code, activeWhen, customProps } = appConfig

  // TODO: move to apropriate place
  trackAnalytics({
    type: AnalyticsActionType.action,
    payload: `Capture ${code}`,
  })

  if (!isRegistered(code)) {
    registerApplication({
      name: code,
      app: getAppLoader(appConfig),
      activeWhen,
      customProps,
    })
  }
}

export const unregisterApp = async ({ code }: { code: string }) => {
  try {
    if (isRegistered(code)) {
      await unregisterApplication(code)
      console.warn(`App '${code}' was unregistered`)
    }
  } catch (error) {
    console.error(`Failed to unregister '${code}' app`, error)
  }
}

export const unregisterAllApps = async () => {
  try {
    await Promise.all(getAppNames().map(app => unregisterApplication(app)))
  } catch (error) {
    console.error('Failed to unregister all apps', error)
  }
}

export const isCriticalMicroAppError = (error: AppError) => {
  const { appOrParcelName } = error
  const appStatus = getAppStatus(appOrParcelName)

  return [SKIP_BECAUSE_BROKEN, LOAD_ERROR].includes(appStatus!)
}

export const runSingleSpa = () => {
  start()
}
