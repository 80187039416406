import { PropsWithChildren, useMemo } from 'react'
import { useAuth } from 'react-oidc-context'

import { useStableCallback } from 'hooks/useStableCallback'
import { AuthContext } from 'providers/auth/AuthProvider'
import { OidcUser } from 'types/auth/user'

export const OidcSecure = ({ children }: PropsWithChildren<{}>) => {
  const { user, signoutRedirect } = useAuth()
  const userProfile = user!.profile

  const oidcUser: OidcUser = useMemo(
    () => ({
      email: userProfile.email!,
    }),
    [userProfile],
  )

  const logout = useStableCallback(() => {
    signoutRedirect()
  })

  return (
    <AuthContext.Provider
      value={{
        jwt: user!.access_token,
        user: oidcUser,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
