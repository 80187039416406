import { PropsWithChildren } from 'react'

import { Footer } from 'pages/landing/components/footer/Footer'
import { Header } from 'pages/landing/components/header/Header'
import styles from 'pages/landing/components/layout/Layout.module.scss'

export const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div className={styles.root}>
      <Header />
      <main className={styles.main} data-testid="main-section">
        {children}
      </main>
      <Footer />
    </div>
  )
}
