import { facadeApi } from 'api'
import { LeanApp } from 'types/apps/leanApps'

interface Params {
  tenantId: string
  leanAppId: string
}

export const fetchLeanApp = ({ tenantId, leanAppId }: Params) =>
  facadeApi.get<LeanApp>(`/tenants/${tenantId}/lean-apps/${leanAppId}`)
