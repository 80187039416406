import { isAxiosError } from 'axios'

import { is401Error, is403Error } from 'api/utils'

export const isMiroAuthError = (error: unknown) =>
  isAxiosError(error) &&
  is401Error(error) &&
  ['token is incorrect or expired', 'User is not authorized in Miro.'].some(
    message => error.response?.data?.detail.includes(message),
  )

const errorPattern = new RegExp(/^User\s[\s\S]*\sis\snot\sauthorized\sin\sMiro$/)

export const isMiroAdminAuthError = (error: unknown) =>
  isAxiosError(error) && is401Error(error) && errorPattern.test(error.response?.data?.detail)

export const getEmailFromError = (error: any) => {
  const emailPattern = new RegExp(/\S+@\S+\.\S+\b/)

  return error.response?.data?.detail.match(emailPattern)
}

export const isRestrictedError = (error: unknown) =>
  isAxiosError(error) &&
  is403Error(error) &&
  ['Insufficient permissions to copy the board. Check source board permissions or team plan.'].some(
    message => message === error.response?.data?.detail,
  )
