import { WppIconChevron } from '@platform-ui-kit/components-library-react'
import { MayBeNull } from '@wpp-open/core'
import clsx from 'clsx'
import { motion, AnimatePresence } from 'framer-motion'
import { PropsWithChildren, useState } from 'react'

import { Flex } from 'components/common/flex/Flex'
import styles from 'pages/landing/components/carousel/Carousel.module.scss'
import { dotsVariants, slideVariants } from 'pages/landing/components/carousel/utils'

interface Props extends PropsWithChildren {
  currentIndex: number
  itemsCount: number
  onMove: (index: number) => void
}

export const Carousel = ({ currentIndex, onMove, children, itemsCount }: Props) => {
  const [direction, setDirection] = useState<MayBeNull<string>>(null)

  const handleNext = () => {
    setDirection('right')
    onMove(currentIndex + 1 === itemsCount ? 0 : currentIndex + 1)
  }

  const handlePrevious = () => {
    setDirection('left')
    onMove(currentIndex - 1 < 0 ? itemsCount - 1 : currentIndex - 1)
  }

  const handleDotClick = (index: number) => {
    setDirection(index > currentIndex ? 'right' : 'left')
    onMove(index)
  }

  return (
    <>
      <Flex align="center">
        <motion.div whileHover="hover" className={styles.left} onClick={handlePrevious} data-testid="carousel-left">
          <WppIconChevron height={32} width={32} direction="left" />
        </motion.div>
        <div className={styles.carouselImages}>
          <AnimatePresence>
            <motion.div
              className={styles.carouselItem}
              key={currentIndex}
              initial={direction === 'right' ? 'hiddenRight' : 'hiddenLeft'}
              animate="visible"
              exit="exit"
              variants={slideVariants}
            >
              {children}
            </motion.div>
          </AnimatePresence>
        </div>
        <motion.div whileHover="hover" className={styles.right} onClick={handleNext} data-testid="carousel-right">
          <WppIconChevron height={32} width={32} direction="right" />
        </motion.div>
      </Flex>

      <div className={styles.carouselIndicator}>
        {[...Array(itemsCount).keys()].map((index: number) => (
          <motion.div
            key={index}
            className={clsx(styles.dot, { [styles.active]: currentIndex === index })}
            onClick={() => handleDotClick(index)}
            initial="initial"
            animate={currentIndex === index ? 'animate' : ''}
            whileHover="hover"
            variants={dotsVariants}
          />
        ))}
      </div>
    </>
  )
}
