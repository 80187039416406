import { useQueryClient } from '@tanstack/react-query'
import { useEffect } from 'react'

export const DevResetQueries = () => {
  const queryClient = useQueryClient()

  // Refetch everything when switching from/to generic tenant
  useEffect(() => {
    queryClient.resetQueries()
  }, [queryClient])

  return null
}
