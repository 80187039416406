import { WppTypography } from '@platform-ui-kit/components-library-react'
import clsx from 'clsx'
import { useCss } from 'react-use'

import { Flex } from 'components/common/flex/Flex'
import { Solution } from 'pages/landing/homePage/holisticSolutions/HolisticSolutions'
import styles from 'pages/landing/homePage/holisticSolutions/holisticSolutionsCard/HolisticSolutionsCard.module.scss'

interface Props {
  item: Solution
}

export const HolisticSolutionsCard = ({ item }: Props) => {
  return (
    <Flex
      direction="column"
      justify="start"
      align="start"
      gap={16}
      className={clsx(
        styles.solution,
        useCss({
          backgroundImage: `url(${item.image})`,
        }),
      )}
    >
      <WppTypography type="3xl-heading" className={styles.header}>
        {item.header}
      </WppTypography>
      <WppTypography type="m-midi">{item.description}</WppTypography>
    </Flex>
  )
}
