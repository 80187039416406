import { NavigationState } from '@platform-ui-kit/components-library'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { HELP_LINKS_BY_APP_STABLE_ID } from 'components/zendesk/utils/constants'
import { ExternalAppCode } from 'constants/apps'
import { KNOWLEDGE_BASE_HOME_URL, OS_STATUS_PAGE_URL } from 'constants/common'
import { Permission } from 'constants/permission'
import { useHasPermission } from 'hooks/useHasPermission'
import { useWidgetsState } from 'hooks/useWidgetsState'
import { LegacyAppWindowLibraryName } from 'legacy/types/apps'
import { useAppState } from 'providers/appState/AppStateProvider'
import { getAppStableId } from 'providers/appState/utils'
import { excludeFalsy } from 'utils/common'
import { routesManager } from 'utils/routesManager'

interface HeaderNavigationItem extends Omit<NavigationState, 'label'> {
  label: string
}

export enum HelpNavigationValue {
  Community = 'community',
  KnowledgeBase = 'knowledge_base',
  Support = 'support',
  OsStatus = 'os_status',
  Marketplace = 'marketplace-help',
  Intercom = 'intercom',
}

export const isHelpNavigationItem = (value: string) =>
  Object.values(HelpNavigationValue).includes(value as HelpNavigationValue)

const useHeaderHelpSubItems = () => {
  const { microAppData } = useAppState()
  const appStableId = getAppStableId(microAppData)

  const { intercomActive, zendeskActive, zendeskEnabled, intercomEnabled } = useWidgetsState()
  const { t } = useTranslation()

  return useMemo(() => {
    const helpLinks = appStableId
      ? HELP_LINKS_BY_APP_STABLE_ID[appStableId as LegacyAppWindowLibraryName | ExternalAppCode]
      : null

    return [
      !!helpLinks?.communityUrl &&
        zendeskActive && {
          label: t('os.header.applications.community'),
          value: HelpNavigationValue.Community,
          path: helpLinks.communityUrl,
        },
      {
        label: t('os.help.knowledge_base'),
        value: HelpNavigationValue.KnowledgeBase,
        path: helpLinks?.knowledgeBaseUrl || KNOWLEDGE_BASE_HOME_URL,
      },
      !intercomActive && {
        label: t('os.help.os_status'),
        value: HelpNavigationValue.OsStatus,
        path: OS_STATUS_PAGE_URL,
      },
      intercomActive &&
        intercomEnabled && {
          label: t('os.help.get_support'),
          value: HelpNavigationValue.Intercom,
        },
      zendeskActive &&
        zendeskEnabled && {
          label: t('os.help.get_support'),
          value: HelpNavigationValue.Support,
        },
    ].filter(excludeFalsy)
  }, [appStableId, t, intercomActive, intercomEnabled, zendeskActive, zendeskEnabled])
}

export const useHeaderMenu = () => {
  const { t } = useTranslation()
  const { hasPermission } = useHasPermission()
  const helpNavigation = useHeaderHelpSubItems()

  return useMemo(() => {
    const headerNavigation: HeaderNavigationItem[] = [
      !!helpNavigation.length && {
        label: t('os.header.applications.help'),
        value: 'help',
        children: helpNavigation,
      },
      {
        label: t('os.header.applications.news'),
        value: 'news',
        path: routesManager.systemApps.news.root(),
      },
      hasPermission(Permission.WPP_OPEN_MARKETPLACE_APP_ACCESS) && {
        label: t('os.header.applications.marketplace'),
        value: 'marketplace',
        path: routesManager.systemApps.marketplace(),
      },
      hasPermission(Permission.WPP_DEVHUB_APP_ACCESS) && {
        label: t('os.header.applications.devhub'),
        value: 'devhub',
        path: routesManager.systemApps.devhub(),
      },
      hasPermission(Permission.NETWORK_APP_ACCESS) && {
        label: t('os.header.applications.network'),
        value: 'network',
        path: routesManager.internalPages.network.root(),
      },
    ].filter(excludeFalsy)

    return headerNavigation
  }, [helpNavigation, t, hasPermission])
}
